.channel_container {
  margin: 20px;
  padding: 15px;
}
.channel_container__channel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 0px;
  padding-top: 0px;
  width: 100%;
}
.channel_swipe_container__data__information {
  width: 30.4%;
}
.data__information__more {
  position: absolute;
  height: calc(100% - 131px);
  padding: 20px 20px 40px 20px;
  background-color: #fff;
  z-index: 1;
  top: 0;
  right: 0;
  width: 67.4%;
  border-radius: 16px;
}
.data__information__more_title {
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
}
.data__information__more_description {
  margin: 0px;
}
.data__information__more_description--empty {
  color: grey;
}
.channel_container__channel > a > img {
  width: 75px;
  margin: 0px;
  border-radius: 50%;
  border: 1px solid #a7a7a7;
}
.channel_container__channel__information {
  width: 100%;
  padding-left: 15px;
}
.channel_container__channel__content {
  width: 100%;
}
.channel_container__channel__content--more {
  cursor: pointer;
}
.channel_container__channel__content--more > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  margin: 3px 0px;
}
.channel_container__channel__content--more > div > i {
  font-size: 18px;
  color: #505050;
}
.channel_container__channel__content--instagram {
  color: #000;
  font-weight: 400;
}
.channel_container__channel__content--instagram:hover {
  text-decoration: underline;
}
.channel_container__channel__content--instagram:hover > .channel__content--instagram__logo {
  text-decoration: unset;
}
.channel_container__channel__information__scrapping {
  margin-top: 10px;
}
.channel_container__channel__information__scrapping > .channel__content--gender {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px;
  font-size: 15px;
}
.channel_container__channel__information__scrapping > .channel__content--age {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px;
  font-size: 15px;
}
.channel_container__channel__information__scrapping > .channel__content--keyword {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px;
  font-size: 15px;
}
.channel_container__channel__information__scrapping > .channel__content--gender > span {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  line-height: 1;
  text-align: center;
  color: #636363;
  margin-right: 15px;
  width: 50px;
}
.channel_container__channel__information__scrapping > .channel__content--age > span {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  line-height: 1;
  text-align: center;
  color: #636363;
  margin-right: 15px;
  width: 50px;
}
.channel_container__channel__information__scrapping > .channel__content--keyword > span {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  line-height: 1;
  text-align: center;
  color: #636363;
  margin-right: 15px;
  width: 50px;
}
.channel_container__channel__information__scrapping > .channel__content--gender > span > i {
  font-size: 18px;
}
.channel_container__channel__information__scrapping > .channel__content--age > span > i {
  font-size: 18px;
}
.channel_container__channel__information__scrapping > .channel__content--keyword > span > i {
  font-size: 18px;
}

.channel__content--titleChannel {
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 6px;
  color: #000;
}
.channel__content--views, .channel__content--subscriber {
  font-size: 14px;
  margin-bottom: 0px;
}
.channel__content--title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  margin: 3px 0px;
}
.channel__content--title > span {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  line-height: 1;
  text-align: center;
  color: #636363;
  margin-right: 10px;
}
.channel__content--title > span > i {
  font-size: 18px;
}
.channel__content--instagram__logo {
  background: url('../assets/insta_logo.png');
  background-size: 18px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  /* make logo insta grey */
  -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  filter: gray;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
}
.channel_container__channels {
  width: 69.6%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.channel_database--reload {
  background-color: #06152a;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #06152a;
  border-radius: 3px;
  font-size: 15px;
  letter-spacing: 0.8px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  cursor: pointer;
  height: 36px;
  padding: 5px 35px;
}
.channel_container__channels > .al-loading {
  font-size: 50px;
  margin-top: 25px;
}
.channel__image {
  width: 25%;
  position: relative;
  margin: 0 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.channel__image:first-child {
  margin-left: 0px;
}
.channel__image:last-child {
  margin-right: 0px;
}
.channel__image__container--image {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0;
}
.channel__image__container--image:hover > .channel__image--image--hover {
  display: flex;
}
.channel__image--image {
  display: flex;
  width: 100%;
  margin: -10% 0;
  cursor: pointer;
}
.channel__image--image--hover {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000047;
  cursor: pointer;
}
.channel__image--image--hover > span {
  font-size: 80px;
  color: #fff;
}
.channel__image--duration {
  display: inline-block;
  font-size: 13px;
  line-height: 18px;
  background-color: #0000007d;
  color: #fff;
  font-weight: 500;
  position: absolute;
  bottom: 0px;
  margin: 0px;
  right: 0px;
  padding: 0px 4px;
}
.channel__image--views {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 14px;
}
.channel__image--views > i {
  margin-right: 5px;
  font-size: 16px;
  height: 16px;
}

.channel_swipe_container__details {
  width: 100%;
  padding: 8px 0px 0px 0px;
}

.channel__image__thumbs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.channel__image--like, .channel__image--dislike {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  font-size: 12px;
  margin-top: 5px;
  line-height: 14px;
}
.channel__image--like {
  color: #227a22;
}
.channel__image--like > i {
  font-size: 17px;
  margin-right: 5px;
}
.channel__image--dislike {
  color: #ff2d2d;
}
.channel__image--dislike > i {
  font-size: 17px;
  margin-right: 5px;
}

.channel__image--date {
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #2b2b2b;
}
.channel__view--more {
  text-align: right;
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #2b2b2b;
  text-decoration: underline;
  cursor: pointer;
}

.channel__image--title {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 4px;
}

.channel_database {
  padding: 0;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.channel_database > h4 {
  margin: 0px;
  text-decoration: underline;
}
.channel_database--no {
  background-color: #ed5555;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 35px;
  border: 1px solid #ed5555;
  border-radius: 3px;
  font-size: 15px;
  letter-spacing: 0.8px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  cursor: pointer;
  margin-right: 25px;
}
.channel_database--no:hover {
  background-color: #fff;
  color: #ed5555;
  border: 1px solid #ed5555;
}
.channel_database--reload {
  margin: 0px 45px;
}
.channel_database--yes {
  background-color: #3cb33c;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 35px;
  border: 1px solid #3cb33c;
  border-radius: 3px;
  font-size: 15px;
  letter-spacing: 0.8px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  cursor: pointer;
  margin-left: 25px;
}
.channel_database--yes:hover {
  background-color: #fff;
  color: #3cb33c;
  border: 1px solid #3cb33c;
}
.channel_database--cocreation {
  background-color: #1815a0;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #1815a0;
  border-radius: 3px;
  font-size: 15px;
  letter-spacing: 0.8px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  cursor: pointer;
  height: 36px;
  position: absolute;
  left: 25px;
  padding: 5px 35px;
}
.channel_database--cocreation:hover {
  background-color: #FFF;
  color: #1815a0;
  border: 1px solid #1815a0;
}
.channel_database__container {

}
.container__status {
  font-size: 16px;
}
.container__gender {
  font-size: 16px;
}
.container__status_reason {
  font-size: 16px;
}
.container__country {
  font-size: 16px;
}
.container__email {
  font-size: 16px;
}
.container__instagram {
  font-size: 16px;
}

.container__status > p {
  margin-bottom: 0px;
  font-size: 16px;
  margin-top: 15px;
  text-decoration: underline;
}
.container__gender > p {
  margin-bottom: 0px;
  font-size: 16px;
  margin-top: 15px;
  text-decoration: underline;
}
.container__status_reason > p {
  margin-bottom: 0px;
  font-size: 16px;
  margin-top: 15px;
  text-decoration: underline;
}
.container__country > p {
  margin-bottom: 0px;
  font-size: 16px;
  margin-top: 15px;
  text-decoration: underline;
}
.container__email > p {
  margin-bottom: 0px;
  font-size: 16px;
  margin-top: 15px;
  text-decoration: underline;
}
.container__instagram > p {
    margin-bottom: 0px;
    font-size: 16px;
    margin-top: 15px;
    text-decoration: underline;
}

.container__status > label {
  margin-right: 15px;
}
.container__gender > label {
  margin-right: 15px;
}
.container__status > label > span {
  margin-left: 10px;
}
.container__gender > label > span {
  margin-left: 10px;
}
.container__update_button {
  margin-top: 35px;
}
.channel_swipe_container {
  padding: 35px 25px;
  position: relative;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #000;
}
.channel_swipe_container:first-child {
  margin-top: 0px;
}
.channel_swipe_container__data {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.data__videoInformation--close {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #fff;
  display: flex;
}
.channel_swipe_container__data__videoInformation {
  position: absolute;
  left: calc(30.4% - 15px);
  top: 5px;
  right: 5px;
  bottom: 100px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  z-index: 1;
  padding: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}
.channel_swipe_container__data__videoInformation > .data__videoInformation__infos {
  width: 74%;
  height: 100%;
  margin-left: 1%;
  overflow: scroll;
}
.data__videoInformation__infos > .data__videoInformation__infos__title {
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 0px;
}
.data__videoInformation__infos > .data__videoInformation__infos__genre {
  text-align: right;
  font-size: 11px;
  line-height: 20px;
  margin-bottom: 10px;
}
.data__videoInformation__title {
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 20px;
}
.channel_swipe_container__data__videoInformation > .data__videoInformation__content {
  font-size: 12px;
  margin: 0px;
}
.data__videoInformation__content--empty {

}
.channel__image--time {
  margin: 0;
  position: absolute;
  z-index: 9;
  right: 0;
  top: 0px;
  background-color: #000000a3;
  color: #fff;
  padding: 0px 6px;
}

.channel__content--viewChannel > a {
  width: 100%;
  line-height: 32px;
  padding: 0 16px;
  display: flex;
}
.channel_database--viewChannel:hover {
  background-color: #fff;
  border: 1px solid #ff0100;
}
.channel_database--viewChannel:hover > a {
  color: #ff0100;
}
.swipe_container__confidenceyesno {
  position: absolute;
  top: 5px;
  right: 25px;
}
.swipe_container__confidenceyesno > p {
  margin: 0px;
  padding: 3px 6px;
  background-color: #a9b9d0;
  border-radius: 5px;
  border: 1px solid #555;
  font-size: 12px;
  line-height: 15px;
}