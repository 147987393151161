@keyframes appears {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.user-management {
  padding: 0px 40px 25px 25px;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-name: appears;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.um-search {
  display: block;
}
.um-search__filter {
  position: relative;
  width: 400px;
}
.um-search__filter__value {
  width: 368px;
  height: 40px;
  border-radius: 16px;
  background-color: #fff;
  padding: 0 16px;
  border: none;
  outline: none;
}
.um-search__filter__icon {
  font-size: 14px;
  position: absolute;
  right: 13px;
  top: 13px;
  cursor: pointer;
  user-select: none;
}

.um-list__header {
  display: flex;
  background-color: #d0d7e1;
  border-radius: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 7px 20px;
}
.um-list__pending {
  margin-bottom: 30px;
}
.um-list__header__item {
  height: 46px;
  line-height: 46px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-right: 10px;
}
.um-list__header__picture {
  min-width: 42px;
}
.um-list__header__name {
  /* width: 180px; */
  width: 20%;
  min-width: 150px;
  margin-left: 10px;
}
.um-list__header__email {
  /* width: 260px; */
  width: 20%;
  min-width: 220px;
  margin-left: 10px;
}
.um-list__header__level {
  min-width: 230px;
  margin-right: min(1%, 30px);
}
.um-list__header__team {
  margin-left: min(1%, 30px);
  margin-right: 0;
  width: 40%;
}
.um-list__header__team_add {
  width: 20px;
  margin: 0;
}



@keyframes slidein {
  0% {
    margin-left: 120%;
    width: 300%;
  }
  100% {
    margin-left: 0%;
    width: 100%;
  }
}
.um-user-line {
  position: relative;
  display: flex;
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 7px 20px;
  animation-duration: 0.3s;
  animation-name: slidein;
  /* animation-delay: 1s; */
}
.um-user-line--inactive {
  background-color: #F5F5F5;
}
.um-user-line__remove {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -10px;
  top: 0px;
  height: 25px;
  width: 25px;
  background-color: #e53935;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}
.um-user-line__remove:hover {
  background-color: hsl(1, 77%, 50%);
}
.um-user-line__remove:active {
  background-color: hsl(1, 77%, 35%);
}
.um-user-line__remove--hidden {
  display: none;
}
.um-user-line__remove__icon {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}
.um-user-line__picture {
  margin-right: 10px;
  height: 42px;
  border-radius: 42px;
  /* background-image: url('/images/default.png'); */
}
.um-user-line__picture__img {
  border-radius: 42px;
  width: 42px;
  height: 42px;
}
.um-user-line__name {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  /* width: 180px; */
  width: 20%;
  min-width: 150px;
  overflow: hidden;
  line-height: 42px;
  height: 42px;
}
.um-user-line__email {
  font-size: 16px;
  font-weight: 400;
  /* width: 260px; */
  width: 20%;
  min-width: 220px;
  overflow: visible;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 42px;
  height: 42px;
}
.um-user-line__level {
  min-width: 230px;
  margin-right: min(1%, 30px);
  text-transform: capitalize;
}
.um-user-line__level .dropdown_container__selected_value {
  height: 31px;
  border-radius: 16px;
}
.um-user-line__level .dropdown_container__selected_value__input {
  text-transform: capitalize;
}
.um-user-line__team {
  margin-left: min(1%, 30px);
  width: 40%;
  /* flex-grow: 3; */
  display: flex;
  flex-wrap: wrap;
}
.um-user-line__team_add {
  width: 13px;
  height: 17px;
  padding: 1.5px 3.5px;
  background-color: #dedede;
  border-radius: 10px;
  line-height: 20px;
  user-select: none;
  margin-top: 10px;
}
.um-user-line__team_add:hover {
  background-color: hsl(0, 0%, 75%);
}
.um-user-line__team_add__icon {
  cursor: pointer;
}
.um-user-line__team_add .material-icons {
  font-size: 13px;
  font-weight: 500;
}
.um-user-line__team_add__menu {
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 1;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  text-align: right;
  width: 20%;
  border: 1px solid #D4D4D4
}
.um-user-line__team_add__menu--hidden {
  display: none;
}
.um-user-line__team_add__menu__close {
  width: 13px;
  height: 18px;
  padding: 0.5px 3.5px 1.5px 3.5px;
  background-color: #dedede;
  border-radius: 10px;
  line-height: 20px;
  user-select: none;
  cursor: pointer;
  margin-right: 4px;
  margin-top: 1px;
}
.um-user-line__team_add__menu__close:hover {
  background-color: hsl(0, 0%, 75%);
}
.um-user-line__team_add__menu__team-list {
  display: flex;
  flex-wrap: wrap;
}
.um-user-line__team_add__menu__team-list__chip {
  display: flex;
  background-color: #D4D4D4;
  height: 14px;
  /* line-height: 30px; */
  border-radius: 4px;
  padding: 8px 10px;
  margin: 4px 10px 4px 0px;
  cursor: pointer;
}
.um-user-line__team_add__menu__team-list__chip:hover {
  background-color: hsl(0, 0%, 73%);
}
.um-user-line__team_add__menu__team-list__text {
  font-size: 12px;
  line-height: 14px;
}

.um-user-line__team__chip {
  display: flex;
  background-color: #c2d2e9;
  height: 14px;
  /* line-height: 30px; */
  border-radius: 4px;
  padding: 8px 10px;
  margin: 4px 10px 4px 0px;
}
.um-user-line__team__chip__text {
  font-size: 12px;
  line-height: 14px;
  /* height: 14px; */
  margin-right: 12px;
}
.um-user-line__team__chip__close.material-icons {
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  user-select: none;
  /* height: 14px; */
}
