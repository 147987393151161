.data_inventory {
  padding: 50px;
}
.data_inventory__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.data_inventory__search {
  margin: 20px 0px;
  width: 45%;
}
.data_inventory__search > p {
  margin: 0px;
  font-size: 15px;
}
.data_inventory__search--input {
  font-size: 18px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #06152a;
  height: 36px;
  width: 100%;
  padding: 0px 10px;
}
.data_inventory__search--input:focus {
  outline: none;
}
.data_inventory__search--input:disabled {
  background-color: #e4e4e4;
  border-bottom: 1px solid grey;
  cursor: not-allowed;
}
.data_inventory__fistsale {
  margin: 0px 0px 20px 0px;
  width: 45%;
  display: flex;
  flex-wrap: wrap;
}
.data_inventory__fistsale > p {
  width: calc(70% - 20px);
  margin-bottom: 10px;
  font-size: 15px;
}
.data_inventory__fistsale--remove {
  font-size: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.data_inventory__fistsale_content {
  display: flex;
  flex-direction: column;
}
.data_inventory__fistsale_content > span {
  text-decoration: underline;
  font-size: 11px;
  margin-left: 5px;
}
.data_inventory__fistsale_start, .data_inventory__fistsale_end {
  background-color: #c2d2e9;
  border: 1px solid #000;
  font-size: 15px;
  padding: 8px 5px;
  margin-right: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  border-radius: 5px;
}
.data_inventory__uploads {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0px;
}
.data_inventory__uploads--hide {
  display: none;
}
.data_inventory__uploads__storage {
  width: 60%;
  margin: 0px 20%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #000;
  border-radius: 16px;
  margin-bottom: 35px;
}
.data_inventory__uploads__storage--icon {
  font-size: 35px;
  color: #C2D2E9;
  margin-right: 10px;
}
.data_inventory__uploads__storage__container {
  width: 100%;
}
.data_inventory__uploads__storage__container__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}
.data_inventory__uploads__storage__container__content:first-child {
  margin-top: 5px;
}
.storage__container__content--title {
  font-size: 13px;
  margin: 0px;
}
.storage__container__content--button {
  background-color: #3a4e7a;
  color: #fff;
  border: 1px solid #000;
  border-radius: 7px;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.storage__container__content--button:hover {
  background-color: #C2D2E9;
}
.storage__container__content--button > span {
  font-size: 19px;
}




.data_inventory__uploads__container {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.data_inventory__uploads__container__title {
  margin-bottom: 6px;
  text-decoration: underline;
  text-align: center;
  font-size: 20px;
}
.data_inventory__uploads__container__subtitle {
  margin: 0px;
  font-size: 12px;
}
.data_inventory__timeframe {
  margin: 20px 0px 5px 0px;
  width: 45%;
}
.data_inventory__timeframe__title {
  margin: 0px;
  font-size: 15px;
}
.data_inventory__timeframe__choices {
  display: flex;
  flex-direction: column;
}
.data_inventory__timeframe__choices__group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.data_inventory__timeframe__choices__group > p {
  font-size: 13px;
  line-height: 16px;
  background-color: #C2D2E9;
  padding: 5px 10px;
  margin: 5px 10px;
  border-radius: 4px;
  border: 1px solid #06152a69;
  cursor: pointer;
}
.data_inventory__timeframe__choices__group > p:first-child {
  margin-left: 0px;
}
.data_inventory__timeframe__choices__group > .isActive {
  background-color: #06152a;
  color: #fff; 
}
