.tabbed-navigation {
  border-radius: 16px;
  margin: 60px 0 30px;
  width: fit-content;
  background-color: #ffffff;
}

.tabbed-navigation button {
  border: none;
  border-radius: 16px;
  padding: 15px 40px;
  background-color: white;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;

}

.tabbed-navigation button.active{
  background-color:#3A4E7A;
  color: #FFFFFF;
  font-weight: 600;
  border-radius: 16px;
}

