
.mdl-layout__container {
  background-color: #EEF1F5;
}
.mdl-layout__drawer {
  width: 70px;
  background-color: #06152a;
  -webkit-transform: unset;
  transform: unset;
  -webkit-transform-style: unset;
  transform-style: unset;
  border-right: 0px;
  position: fixed;
  height: calc(100vh - 30px);
  top: 15px;
  left: 15px;
  border-radius: 15px;
  overflow: unset;
  /*overflow-y: scroll;*/
}
.mdl-layout__drawer::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.mdl-layout__drawer::-webkit-scrollbar-thumb {
  background: transparent;
}

.mdl-layout__content {
  margin-left: 100px !important;
  margin-top: 15px;
  width: calc(100% - 100px);
  height: 100vh;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* height: 100vh; */
}
.oklos-menu__modal-container {
  position:fixed;
  padding:0;
  margin:0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.oklos-menu__container {
  background-color: #E8ECF1;
  height: 100vh;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.oklos-menu__container--on-modal {
  background-color: #E8ECF1;
  height: 100vh;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px); */
  filter: blur(5px) brightness(95%);
}
.mdl-layout__header {
  margin-left: 200px !important;
  width: calc(100% - 200px) !important;
}
.mdl-navigation > .mdl-navigation__signout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.mdl-navigation > .mdl-navigation__signout > img {
  width: 40px;
  height: 40px;
  border-radius: 23px;
  border: 1px solid #999;
}
a {
  text-decoration: none;
  color: white;
}
.demo-card-wide.mdl-card {
  width: 512px;
  margin: 35px;
}
.demo-card-wide > .mdl-card__title {
  color: #000;
  height: 176px;
  background: url('./assets/Inventory.png') center / cover;
}
.demo-card-wide > .avatar {

  background: url('./assets/header_image.jpg') center / cover;
}
.demo-card-wide > .orders {
  background: url('./assets/orders.jpg') center / cover;
}
.demo-card-wide > .qrcode {
  background: url('./assets/qrcode.png') center / cover;
}
.demo-card-wide > .products {
  background: url('./assets/products.jpg') center / cover;
}
.demo-card-wide > .insta {
  background: url('./assets/insta.jpg') center / cover;
}
.demo-card-wide > .channels {
  background: url('./assets/channels.jpg') center / cover;
}
.demo-card-wide > .engagement_channel {
  background: url('./assets/engagement_rate.png') center / cover;
}
.avatar_wrapper {
  display: flex;
  justify-content: center;
}
.avatar_wrapper > h1 {
  font-size: 40px;
    margin: 35px 0px;
    line-height: 45px;
}

.avatar_picture {
  width: 35%;
  border-radius: 50%;
}

.demo-card-wide > .mdl-card__menu {
  color: #000;
}
@-moz-keyframes spinLoading { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spinLoading { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spinLoading { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }
.al-loading {
  display: flex;
  justify-content: center;
  font-size: 90px;
  -webkit-animation: spinLoading 2.3s linear infinite;
  -moz-animation: spinLoading 2.3s linear infinite;
  animation: spinLoading 2.3s linear infinite;
  color: #484848;
}

.components_wrapper {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding: 1px 25px 56px 25px;
}

.demo-layout-transparent {
  background: url('./assets/header_image.jpg') center / cover;
}
.demo-layout-transparent .mdl-layout__header,
.demo-layout-transparent .mdl-layout__drawer-button {
  /* This background is dark, so we set text to white. Use 87% black instead if
     your background is light. */
  color: white;
}


/**********Sign In*********/

.login_main_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
  justify-content: space-evenly;

}
.login_container--title {
  font-size: 27px;
  line-height: 1;
  margin-top: 0px;
  font-weight: 600;
}
.signedin-text:hover {
text-decoration: underline;
}

/* Shared */
.login_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 16px;
  padding: 25px 15px;
  margin: 15px;
  width: 40%;
  min-height: 250px;
}
.login_container > p {
  font-size: 20px;
  width: 70%;
  margin: 0 auto 25px auto;
}
.login_container > a {
  display: inline-block;
}
.loginBtn {
  box-sizing: border-box;
  position: relative;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
  cursor: pointer;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}




.loginBtn--google {
  background: #DD4B39;
}
.loginBtn--google:before {
  border-right: #BB3F30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}

/* End Sign In Button*/

/*********QR code generator*********/
  .generate-qr-main-wrapper {
    padding: 1px 25px 56px 25px;
  }
  .generate-qr-main-wrapper__content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
  }
  .generate-qr-main-wrapper__content__right {
    width: 47%;
    border-left: 1px solid #000;
    padding-left: 3%;
  }
  .radio-wrapper {
    display: flex;
    flex-direction: row;
    margin: 20px 0px;
  }
  .radio-wrapper > label {
    margin-right: 50px;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 20px;
  }
  .radio-wrapper > label > span {
    margin-left: 10px;
  }
  #generate-qr-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 25px;
    width: 50%;
  }
  .qr-size-buttons {
    margin: 25px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .qr-size-buttons__button {
    padding: 0 45px;
    background-color: #3A4E7A;
    border-radius: 8px;
    font-size: 18px;
    line-height: 17px;
    font-weight: 400;
    margin-right: 25px;
  }
  .qr-size-buttons__button > i {
    color: #fff;
  }
  .qr-size-buttons__button:hover {
    background-color: #C2D2E9 !important;
    color: #000 !important;
  }
  .qr-size-buttons__button:hover > i {
    color: #000;
  }
  .download-button-wrapper {
    margin-top: 35px;
  }
  .mdl-button--fab {
    margin: 0 10px;
  }

   /**********Product Component**********/
   .product-component-main-container{
     margin:30px;
    /* background-color: #d8cbcb;
    width: 30%; */
   }
   .demo-card-image.mdl-card {
    width: 256px;
    height: 256px;
  }
  .demo-card-image > .mdl-card__actions {
    height: 52px;
    padding: 16px;
    background: rgba(0, 0, 0, 0.2);
  }
  .demo-card-image__filename {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }
  .product-container {
    margin: 35px 0;
    box-shadow: 9px 12px 19px #88888F;
    width:95%;
    margin:auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    

  }

  /************Products Page **************/
  .products-page-wrapper{
    
  }
  .products-page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    
    margin-top: 30px;
    box-shadow: 9px 12px 19px #88888F;
    width: 92%;
    margin: auto;
    margin-top: 30px;
  }
  .product-des-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
  .details-button-a{
    align-items: center !important;
    display: flex !important;
  }
  .details-button{
    display: flex !important;
    margin-right: 20px !important;
    align-items: center !important;
  }
  .product-des-container > h6 {
    max-width: 130px;
    width:25%;
    margin: auto 0px;
  }
/***************Inventory Page************/
.product_info_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.chart-comp-container > canvas {
    height: 550px !important;
    width: 1100px !important;
  
}

.chart-comp-container{
  display: flex;
  justify-content: center;
  margin-bottom: 150px;
}
.product-card {
  min-height: 130px !important;
  min-width: 130px !important;
  height: 130px !important;
  width: 130px !important;

}
.product-card:hover {
  min-height: 200px !important;
  min-width: 200px !important;
  height: 300px !important;
  width: 300px !important;
}


.mdl-layout__drawer-button {
  position: fixed !important;
}
.back-button {
  opacity: 0.5;
}
.back-button:hover {
  opacity: 1;
}
/* back button inventory */
.backButton {
  display: flex;
  justify-content: right;
  margin-left: 30px;
}
.rdr-DateRange {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
}
.dateRange__update {
  display: flex;
  margin: 0 auto;
}
.mdl-layout__header-row {
  background-color: #06152a;
}
.oklos-menu {
  box-shadow: unset;
  background-color: black;
  height: 100vh;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.oklos-menu--round {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 64px;
}
.oklos-menu > .mdl-layout__drawer {
  box-shadow: unset;
  background-color: rgb(6,21,42);
  color: #fff;
  border-right: 1px solid rgb(6,21,42);
}
.oklos-menu > .mdl-layout__header-row {
  position: relative;
}
.mdl-layout-title > a {
  font-size: 30px;
}
.oklos-menu__nav {
  overflow: unset;
  padding-top: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center !important;
}
.oklos-menu__nav__link {
  color: #fff !important;
  cursor: pointer;
  font-size: 16px;
  padding: unset !important;
  margin: 10px 0px !important;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  width: min(calc((100vh - 30px - (11 * 2 * 10px)) / 7), 50px);
  height: min(calc((100vh - 30px - (11 * 2 * 10px)) / 7), 50px);
  opacity: 1;
}
.oklos-menu__nav__link.material-icons-outlined {
  /* font-size: 30px; */
  font-size: clamp(5px, 5vh, 30px);
}

.oklos-menu__nav__link--influencer {
  background-image: url('../src/assets/icons-influencer.png');
  background-size: clamp(10px, 100%, 30px) auto;
  background-repeat: no-repeat;
  background-position: center;
}
.oklos-menu__nav__link--influencer:hover, .oklos-menu__nav__link-influencer-isActive {
  background-image: url('../src/assets/icons-influencer--hover.png');
  border-radius: 20px 0px 0px 20px;
}
.oklos-menu__nav__link--home:hover:has(.oklos-menu__nav__link--home--hover),
.oklos-menu__nav__link--shipping:hover,
.oklos-menu__nav__link--data:hover,
.oklos-menu__nav__link--influencer:hover {
  position: relative;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.oklos-menu__nav__link--home--hover,
.oklos-menu__nav__link--shipping--hover,
.oklos-menu__nav__link--data--hover,
.oklos-menu__nav__link--influencer--hover {
  display: none;
}
.oklos-menu__nav__link--home:hover > .oklos-menu__nav__link--home--hover,
.oklos-menu__nav__link--shipping:hover > .oklos-menu__nav__link--shipping--hover,
.oklos-menu__nav__link--data:hover > .oklos-menu__nav__link--data--hover,
.oklos-menu__nav__link--influencer:hover > .oklos-menu__nav__link--influencer--hover {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 180px;
  left: min(calc((100vh - 30px - (11 * 2 * 10px)) / 7), 50px);
  top: 0px;
  background-color: #fff;
  z-index: 100;
  border-radius: 0px 5px 5px 20px;
  padding: 10px 30px;
  box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.15);
  max-height: 350px;
  /* overflow-y: scroll; */
  overflow-x: hidden;
}
.oklos-menu__nav__link--home:hover > .oklos-menu__nav__link--home--hover {
  width: 250px;
  overflow-x: unset;
  overflow-y: visible !important;
  /* height: 300px; */
  max-height: unset;
}
.oklos-menu__nav__link--home:hover > .oklos-menu__nav__link--home--hover::-webkit-scrollbar,
.oklos-menu__nav__link--shipping:hover > .oklos-menu__nav__link--shipping--hover::-webkit-scrollbar,
.oklos-menu__nav__link--data:hover > .oklos-menu__nav__link--data--hover::-webkit-scrollbar,
.oklos-menu__nav__link--influencer:hover > .oklos-menu__nav__link--influencer--hover::-webkit-scrollbar {
  width: 10px;
}
.oklos-menu__nav__link--home:hover > .oklos-menu__nav__link--home--hover::-webkit-scrollbar-track,
.oklos-menu__nav__link--shipping:hover > .oklos-menu__nav__link--shipping--hover::-webkit-scrollbar-track,
.oklos-menu__nav__link--data:hover > .oklos-menu__nav__link--data--hover::-webkit-scrollbar-track,
.oklos-menu__nav__link--influencer:hover > .oklos-menu__nav__link--influencer--hover::-webkit-scrollbar-track {
  background: #EDF1F5;
}
.oklos-menu__nav__link--home:hover > .oklos-menu__nav__link--home--hover::-webkit-scrollbar-thumb,
.oklos-menu__nav__link--shipping:hover > .oklos-menu__nav__link--shipping--hover::-webkit-scrollbar-thumb,
.oklos-menu__nav__link--data:hover > .oklos-menu__nav__link--data--hover::-webkit-scrollbar-thumb,
.oklos-menu__nav__link--influencer:hover > .oklos-menu__nav__link--influencer--hover::-webkit-scrollbar-thumb {
  background: #3A4E7A;
  border-radius: 5px;
}
/* .oklos-menu__nav__link--influencer:hover > .oklos-menu__nav__link--influencer--hover::-webkit-scrollbar-thumb:hover {
  background-color: royalblue;
} */
.oklos-menu__nav__link--home--hover > a,
.oklos-menu__nav__link--shipping--hover > a,
.oklos-menu__nav__link--data--hover > a,
.oklos-menu__nav__link--influencer--hover > a {
  color: #000;
  font-family: "Roboto";
  padding: 12px 0px;
}
.oklos-menu__nav__link--home--hover > a,
.oklos-menu__nav__link--shipping--hover > .link--isActive, .oklos-menu__nav__link--shipping--hover > a:hover,
.oklos-menu__nav__link--data--hover > .link--isActive, .oklos-menu__nav__link--data--hover > a:hover,
.oklos-menu__nav__link--influencer--hover > .link--isActive, .oklos-menu__nav__link--influencer--hover > a:hover {
  text-decoration: underline;
}
.oklos-menu__nav__link--utm {
  background-image: url('../src/assets/icons-add-link.png');
  background-size: clamp(10px, 100%, 30px) auto;
  background-repeat: no-repeat;
  background-position: center;
}
.oklos-menu__nav__link--utm:hover, .oklos-menu__nav__link-utm-isActive {
  background-image: url('../src/assets/icons-add-link--hover.png');
}

.oklos-menu__nav__link--engagement {
  background-image: url('../src/assets/icons-connected-people.png');
  background-size: clamp(10px, 100%, 30px) auto;
  background-repeat: no-repeat;
  background-position: center;
}
.oklos-menu__nav__link--engagement:hover, .oklos-menu__nav__link-engagement-isActive {
  background-image: url('../src/assets/icons-connected-people--hover.png');
}

.oklos-menu__nav__link--qrcode {
  background-image: url('../src/assets/icons-qr-code.png');
  background-size: clamp(10px, 100%, 30px) auto;
  background-repeat: no-repeat;
  background-position: center;
}
.oklos-menu__nav__link--qrcode:hover, .oklos-menu__nav__link-qrcode-isActive {
  background-image: url('../src/assets/icons-qr-code--hover.png');
}

.oklos-menu__nav__link--home {
  background-image: url('../src/assets/Oklos_Logo_Invert.png');
  background-size: clamp(10px, 100%, 100%) auto;
  /* background-size: 50px; */
  background-repeat: no-repeat;
  background-position: center;
}
.oklos-menu__nav__link-home-isActive {
  background-image: url('../src/assets/Oklos_Logo_Blue.png');
  background-color: #fff;
  /* border-radius: 14px !important; */
}
.oklos-menu__nav__link--home:hover {
  background-image: url('../src/assets/Oklos_Logo_Blue.png');
  background-size: clamp(10px, 100%, 80%) auto;
}

.oklos-menu__nav__link--shipping {
  background-image: url('../src/assets/icons-in-transit.png');
  background-size: clamp(10px, 100%, 30px) auto;
  background-repeat: no-repeat;
  background-position: center;
}
.oklos-menu__nav__link--shipping:hover, .oklos-menu__nav__link-shipping-isActive {
  background-image: url('../src/assets/icons-in-transit--hover.png');
}

.oklos-menu__nav__link--data {
  background-image: url('../src/assets/icons-combo-chart.png');
  background-size: clamp(10px, 100%, 30px) auto;
  background-repeat: no-repeat;
  background-position: center;
}
.oklos-menu__nav__link--data:hover, .oklos-menu__nav__link-data-isActive {
  background-image: url('../src/assets/icons-combo-chart--hover.png');
}

.oklos-menu__nav__link--gift {
  background-image: url('../src/assets/icon-gift-card.png');
  background-size: clamp(10px, 100%, 30px) auto;
  background-repeat: no-repeat;
  background-position: center;
}
.oklos-menu__nav__link--gift:hover, .oklos-menu__nav__link-gift-isActive {
  background-image: url('../src/assets/icon-gift-card--hover.png');
  background-size: clamp(10px, 100%, 30px) auto;
}

.oklos-menu__nav__link--inventory {
  background-image: url('../src/assets/icons-warehouse.png');
  background-size: clamp(10px, 100%, 30px) auto;
  background-repeat: no-repeat;
  background-position: center;
}
.oklos-menu__nav__link--inventory:hover, .oklos-menu__nav__link-inventory-isActive {
  background-image: url('../src/assets/icons-warehouse--hover.png');
}

.oklos-menu__nav__link--logout {
  background-image: url('../src/assets/icons-logout.png');
  background-size: clamp(10px, 100%, 30px) auto;
  background-repeat: no-repeat;
  background-position: center;
}
.oklos-menu__nav__link--logout:hover, .oklos-menu__nav__link-logout-isActive {
  background-image: url('../src/assets/icons-logout--hover.png');
}

a > .oklos-menu__nav__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 70%;
  opacity: 1;
}
.oklos-menu__nav__link--icon {
  width: 30px;
  height: 30px;
}
.oklos-menu__nav__link:hover, .oklos-menu__nav__link--isActive {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 20px;
}
.oklos-menu__nav__link:hover > a, .oklos-menu__nav__link--isActive > a {
  color: #000;
}
.oklos-menu__nav__link--logout_inf {
  position: absolute;
  bottom: 25px;
}

.oklos-menu--corner {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: rgb(6,21,42);
  bottom: -100px;
  left: 0px;
}
.oklos-menu--corner--content {
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 100px 0 0 0;
  -moz-border-radius: 100px 0 0 0;
  -webkit-border-radius: 100px 0 0 0;
}

.page__header {
  width: 100%;
}
.page__header__title {
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  margin: 40px 0px;
  line-height: 1;
}

.data_streamlit {
  /* height: calc(100vh - 59px); */
}
.data_streamlit__error {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.data_streamlit__error > span {
  font-size: 20px;
  margin-right: 5px;
}
.data_streamlit__error > a {
  color: #000;
  text-decoration: underline;
  margin-left: 5px;
}
.data_streamlit > .header_component {
  padding: 0px 50px;
}
.data_streamlit__container {
  height: calc(100vh - 174px);
}
.data_streamlit__container > iframe {
  height: 100%;
}
