.oi_history_deals {
}
.oi_history_deals_deal {
  position: relative;
  background-color: #d0d7e1;
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 30px;
}
.oi_history_deals_deal__title {
  display: flex;
  flex-wrap: wrap;
  font-family: "Roboto";
  font-size: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 20px;
}
.oi_history_deals_deal__am {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffffbf;
  position: absolute;
  top: 10px;
  margin-bottom: 0px;
  padding: 4px 10px;
  border-radius: 10px;
  right: 10px;
  font-size: 12px;
  line-height: 20px;
}
.oi_history_deals_deal__am > span {
  font-size: 19px;
  margin-right: 5px;
}
.oi_history_deals_deal__title_date {
  font-size: 15px;
  margin-left: 10px;
  color: #4e4e4e;
  font-weight: 400;
}
.oi_history_deal_content {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
}
.oi_history_deal_content--subtitle {
  font-size: 12px;
  color: #3373c3;
  margin-bottom: 0px;
}
.oi_history_deal_content_prices,
.oi_history_deal_content_dates {
  background-color: #fff;
  padding: 20px;
  width: calc(50% - 55px);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
}
.oi_history_deal_content_status {
  background-color: #fff;
  padding: 20px;
  width: calc(50% - 55px);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.oi_history_deal_content_status > .oi_history_deal_content--subtitle {
  margin-bottom: 5px;
}
.oi_history_deal_content_status .dropdown_container {
  width: 250px;
  font-size: 14px;
}
.oi_history_deal_content_status .dropdown_container__selected_value__input,
.oi_history_deal_content_status .dropdown_container__list__value {
  font-size: 14px;
}
.oi_history_deal_content_list {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}
.oi_history_deal_content_list--title {
  width: 100%;
}
.oi_history_deal_content_list_content {
  width: calc(100% - 40px);
  padding: 20px;
  background-color: #f1f3f4;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.deal_list_content--type {
  width: 100%;
  font-size: 20px;
  font-family: "Roboto";
}
.deal_list_content--type-value {
  text-transform: capitalize;
  margin-right: 5px;
}

.oi_history_deal_content_prices_price--value,
.deal_list_content--type-value,
.deal_list_content_links--value {
  margin-bottom: 0px;
}
.deal_list_content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 50%;
}
.deal_list_content_links {
  width: 48%;
  padding-right: 2%;
}
.deal_list_content_links--value {
  color: #000;
  min-height: 20px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  word-break: break-all;
}
.deal_list_content_video {
  position: relative;
  width: 50%;
  display: flex;
}
.deal_list_content_video_loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.deal_list_content_video_loading > p {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0px;
}
.deal_list_content_video_loading > .al-loading {
  font-size: 46px;
}
.deal_list_content_video_content {
  width: 50%;
  position: relative;
}
.deal_list_content_video_content_link {
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0;
}
.deal_list_content_video_content_link_thumbnail {
  display: flex;
  width: 100%;
  margin: -10% 0;
  cursor: pointer;
}
.deal_list_content_video_content_link_duration {
  position: absolute;
  right: 4px;
  bottom: 4px;
  z-index: 2;
  background-color: #00000080;
  color: #fff;
  padding: 0px 3px;
  border-radius: 4px;
}
.deal_list_content_video_content_details {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.deal_list_content_video_content_details_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
  font-size: 13px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.deal_list_content_video_content_details_views {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}
.deal_list_content_video_content_details_views_date {
}
.deal_list_content_video_content_details_views_views {
}
.deal_list_content_video_content_details_thumb--up,
.deal_list_content_video_content_details_thumb--down {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  width: calc(50% - 10px);
}
.deal_list_content_video_content_details_thumb--up {
  justify-content: flex-start;
  color: green;
  padding-left: 10px;
}
.deal_list_content_video_content_details_thumb--up > span {
  font-size: 19px;
  margin-right: 8px;
}
.deal_list_content_video_content_details_thumb--down {
  justify-content: flex-end;
  color: red;
  padding-right: 10px;
}
.deal_list_content_video_content_details_thumb--down > span {
  font-size: 19px;
  margin-right: 8px;
}
.deal_list_content_video_description {
  width: 50%;
  height: 260px;
  overflow: scroll;
  margin-left: 15px;
}

.deal_list_content_post_loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.deal_list_content_post_loading > p {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0px;
}
.deal_list_content_post_loading > .al-loading {
  font-size: 46px;
}

.deal_list_content_post {
  position: relative;
  width: 50%;
  display: flex;
}
.deal_list_content_post_content {
  width: 50%;
  position: relative;
}
.deal_list_content_post_content_link {
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0;
}
.deal_list_content_post_content_link_thumbnail {
  display: flex;
  width: 100%;
  cursor: pointer;
}
.deal_list_content_post_description {
  width: 50%;
  height: 100%;
  overflow: scroll;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.deal_list_content_post_description--content {
  position: relative;
  height: calc(100% - 50px);
  overflow-y: scroll;
  margin-bottom: 0px;
}
.deal_list_content_post_description_details {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 50px;
  font-family: "Roboto";
  align-items: flex-end;
}

.deal_list_content_post_description_details--comments,
.deal_list_content_post_description_details--likes {
  margin-bottom: 0px;
  line-height: 20px;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Roboto";
}
.deal_list_content_post_description_details--comments
  > .description_details--comments-icon,
.deal_list_content_post_description_details--likes
  > .description_details--likes-icon {
  font-size: 15px;
  margin-right: 6px;
}

.deal_list_content_post_description_details--date {
  font-family: "Roboto";
  line-height: 20px;
  margin-bottom: 0px;
  width: 100%;
}
