.channelsEmails_container {
  padding: 1px 25px 56px 25px;
}

@-moz-keyframes rotating { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes rotating { 100% { -webkit-transform: rotate(360deg); } }
@keyframes rotating { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.rotating {
  display: flex;
  -webkit-animation: rotating 2.3s linear infinite;
  -moz-animation: rotating 2.3s linear infinite;
  animation: rotating 2.3s linear infinite;
}
.channelsEmails_sum {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 25px;
  font-size: 30px;
}
.channelsEmails_res {
  text-align: center;
  font-style: italic;
  color: grey;
}
.channelsEmails_list {
  margin: 20px 50px 50px 50px;
}
.channelsEmails_container__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.channelsEmails_list_channel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #c5c5c5;
  padding: 7px 0px;
}
.channelsEmails_list_channel:nth-child(odd) {
  background-color: #e6e6e6;
}
.channelsEmails_list_channel:last-child {
  border-bottom: none;
}
.channelsEmails_list_channel_channelid {
  margin-bottom: 0px;
  width: 40%;
}
.channelsEmails_list_channel_status {
  display: flex;
  margin-bottom: 0px;
}