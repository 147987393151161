.report_table {
  display: block;
  max-width: calc(100% - 20px);
  overflow-x: scroll;
  overflow-y: hidden;
  border-bottom: 1px solid black;
}

.report_table_tr:last-child > .report_table_tr {
  border-bottom: 0;
}

.report_table_th, .report_table_td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;

  /* The secret sauce */
  /* Each cell should grow equally */
  width: 1%;
  /* But "collapsed" cells should be as small as possible */
}
.report_table_th.collapse, .report_table_td.collapse {
  width: 0.0000000001%;
}
.report_table_th:last-child , .report_table_td:last-child {
  border-right: 0;
}
