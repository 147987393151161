.am-selector {
  background-color: #FFF;
  border-radius: 16px;
  padding: 11px 12px;
  display: flex;
  flex-direction: column;
  z-index: 200;
  cursor:default;
}
.am-selector--open {
  border: 1px solid #000;
}

.am-selector--close {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* .am-selector .material-icons {
  cursor: pointer;
} */
.am-selector .dropdown_container {
  width: 200px;
}