.dashboard__container {

}
.dashboard__container__header {
  display: flex;
  justify-content: center;
}
.dashboard__container__header > h1 {
  font-size: 40px;
  margin: 35px 0px;
  line-height: 45px;
}
.dashboard__container__content {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding: 1px 25px 56px 25px;
}

.dashboard__container__content__data {
  width: calc(33.3333% - 62px);
  background-color: #fff;
  border-radius: 16px;
  padding: 25px 15px;
  margin: 15px;
}
.dashboard__data__title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.dashboard__data__title > img {
  width: 50px;
  margin-right: 15px;
}
.dashboard__data__title > h2 {
  font-size: 25px;
  line-height: 28px;
  margin: 0;
}
.dashboard__data__main {

}
.dashboard__data__main--title {
  font-size: 16px;
  margin-bottom: 10px;
  color: #000;
  font-weight: normal;
}
.dashboard__data__main--value {
  margin: 0px;
  font-size: 30px;
  line-height: 36px;
}
.dashboard__data__main--value > .al-loading {
  font-size: 58px;
}
.dashboard__data__sub {
  margin-top: 20px;
  border-top: 1px solid #D8D8D8;
  padding-top: 15px;
}
.dashboard__data__sub__details {
  width: 90%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 6px auto;
}
.dashboard__data__sub__details--title {

}
.dashboard__data__sub__details--value {

}
.dashboard__data__sub__details--value > .al-loading {
  font-size: 20px;
}

.dashboard__container__content__release--title {
  width: 100%;
  font-family: "Roboto";
  text-align: left;
  margin-left: 15px;
  margin-bottom: 5px;
  font-size: 26px;
  line-height: 34px;
}
.dashboard__container__content__release {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 16px;
  padding: 30px;
  margin: 0px 15px;
  font-family: "Roboto";
  margin-bottom: 10px;
}
.release_title {
  font-size: 26px;
  font-weight: 400;
  text-decoration: underline;
  line-height: 28px;
  margin-bottom: 3px;
}
.release_date {
  font-size: 16px;
  color: #727272;
  margin-bottom: 0px;
  font-weight: 300;
  /* font-style: italic; */
}
.release_informations {
  margin: 20px 0px 8px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
}
.release_informations > span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  margin-right: 5px;
}
.release_subtitle {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  color: #3373C3;
  /* text-decoration: underline; */
  margin-top: 18px;
}
.release_subtext {
  margin-bottom: 0px;
  font-size: 16px;
  margin-left: 11px;
  line-height: 22px;
}

.dashboard__container__content__influencer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 16px;
  padding: 30px;
  margin: 0px 15px;
  font-family: "Roboto";
  margin-bottom: 10px;
}
.dashboard__container__content__influencer > .dashboard__data__title {
  justify-content: left;
}
.dashboard__data__content {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.dashboard__container__content__influencer_detail {
  width: 33.33333%;
}
.dashboard__data__main--small {
  width: 50%;
  margin: 0 auto;
}