.deals_list__content_item {
  width: var(--column-width);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
}

.deals_list__content_item:not(:first-child) {
  margin-left: var(--gap-size);
}

.deals_list__content_item a {
  color: #3373C3;
  font-weight: 400;
}

.deals_list__content_item--influencer {
  display: flex;
  word-break: break-all;
  width: calc(var(--column-width) / 2 * 5);
}


.deals_list__content_item--expected_date,
.deals_list__content_item--last_outreach,
.deals_list__content_item--date_created {
  width: calc(var(--column-width) / 4 * 3);
  min-width: 75px;
}

.deals_list__content_item--follow-up {
  min-width: 105px;
}

.deals_list__content_item--payment {
  width: calc(var(--column-width) / 3 * 2);
  min-width: 60px;
}
.deals_list__content_item--payment > span {
  margin-left: 5px;
  font-size: 15px;
  cursor: default;
}
.deals_list__content_item__payment-status--requested {
  color: #D0D7E1;
}
.deals_list__content_item__payment-status--processing {
  color: #FFD84D;
}
.deals_list__content_item__payment-status--processed {
  color: #43A047;
}

.deals_list__content_item--social {
  display: flex;
  align-items: center;
  gap: 8px 16px;
  flex-wrap: wrap;
}

.deals_list__content_item--social .material-icons {
  color: #000000;
  opacity: 0.54;
  font-size: 20px;
}

.deals_list__content_item--social .deals_list__content_item_social__youtube {
  height: 17px;
  object-fit: cover;
}

.deals_list__content_item--social .deals_list__content_item_social__launch {
  display: flex;
  align-items: center;
}

.deals_list__content_item img,
.deals_list__content_item--social img {
  width: 25px;
}


.deals_list__content_item_follow_up div {
  display: flex;
  flex-direction: column;
}

.deals_list__content_item--influencer img {
  max-width: 50px;
  max-height: 50px;
  width: 3.5vw;
  height: 3.5vw;
  border-radius: 50%;
  background-color: #D8D8D8;
}

.deals_list__content_item_influencer_data {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.deals_list__content_item_influencer_data_name,
.deals_list__content_item_influencer_data_email {
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
}

.deals_list__content_item_influencer_data_name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.deals_list__content_item_influencer_data_email {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.deals_list__content_item_influencer_data_email--copy {
  display: none;
  font-size: 14px;
  margin-left: 8px;
}

.deals_list__content_item_influencer_data_email:hover .deals_list__content_item_influencer_data_email--copy {
  display: block;
}
