.follow_up_date {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.follow_up_date_left {
  display: flex;
  flex-direction: column;
}

.follow_up_date_left span:first-child {
  margin-bottom: 6px;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  padding: unset;
}