.redirect {

}
.redirect--title {
  font-family: "Roboto";
  font-size: 30px;
  letter-spacing: 0.2px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 40px;
}
.redirect_loader {
  width: 100%;
  position: absolute;
  top: 20vh;
  z-index: 1;
}
.redirect_container {
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 56px 80px;
  margin-right: 40px;
  margin-left: 20px;
  margin-bottom: 40px;
}
.redirect_container--loading {
  filter: blur(5px) brightness(95%);
}
.redirect_container_empty {
  text-align: center;
  font-size: 16px;
  border: 1px solid #ff6e6e;
  width: 40%;
  margin: 50px auto 0 auto;
  padding: 24px 30px;
  background-color: #ffbbbb;
  border-radius: 16px;
}
.redirect_container_empty--create-new {
  margin: 20px auto;
}
.redirect_container_search {
  background-color: #F3F3F3;
  padding: 10px 13px 10px 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
}
.redirect_container_search__domain {
  color: #757575;
}
.redirect_container_search--input {
  font-family: "Roboto";
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
}
.redirect_container_search--input:focus {
  outline: none;
}
.redirect_container_search--icon {
  font-size: 20px;
  cursor: pointer;
}
.redirect_container_result {
  margin-top: 50px;
}
.redirect_container_result_from {
  margin-bottom: 50px;
}
.redirect_container_result_from, .redirect_container_result_to {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.redirect_container_result_from--title, .redirect_container_result_to--title, .redirect_container_result_utms_utm--key {
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 23px;
  font-family: 'Roboto';
  width: 25%;
  margin: 0px;
}
.redirect_container_result_from--value,
.redirect_container_result_to--value {
  width: 75%;
  border: none;
  background-color: #F3F3F3;
  border-radius: 16px;
  padding: 15px 20px;
  font-size: 14px;
  color: #000;
  line-break: anywhere;
  display: flex;
  color: #757575;
}
.redirect_container_result_from--value-domain {
  min-width: 172px;
}
.redirect_container_result_from--value-input,
.redirect_container_result_to--value-input {
  border: none;
  background-color: #F3F3F3;
  width: 75%;
  outline: none;
}
.redirect_container_result_to--value {
  width: 75%;
  border: none;
  background-color: #F3F3F3;
  border-radius: 16px;
  padding: 15px 20px;
  font-size: 15px;
  color: #000;
  line-break: anywhere;
  margin-bottom: 27px;
}

.redirect_container_result_utms {

}
.redirect_container_result_utms_utm {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}
.redirect_container_result_utms_utm--key {
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 24px;
  font-family: 'Roboto';
  width: calc(25% - 22px);
  margin: 0px;
  text-align: right;
  padding-right: 11px;
}
.redirect_container_result_utms_utm--value:disabled {
  opacity: 0.6;
}
.redirect_container_result_utms_utm--value {
  width: 40%;
  border: 1px solid #F3F3F3;
  background-color: #F3F3F3;
  border-radius: 25px;
  padding: 12px 20px;
  font-size: 15px;
  color: #000; 
}
.redirect_container_result_utms_utm--value:focus {
  outline: none;
  border: 1px solid #808080;
}
.redirect_container_result_update {
  outline: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.12px;
  background-color: rgba(0, 0, 0, 0);
  min-height: 36px;
  padding: 8px 25px;
  border-radius: 6px;
  cursor: pointer;
}
.redirect_container_result_update_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}
.redirect_container_result_update--update {
  color: white;
  background-color: #2F6CB8;
  border: solid 1px #2F6CB8;
}
.redirect_container_result_update--update:hover {
  color: #2F6CB8;
  background-color: #FFF;
}
.redirect_container_result_update--create {
  color: white;
  background-color: #43A047;
  border: solid 1px #43A047;
}
.redirect_container_result_update--create:hover {
  color: #43A047;
  background-color: #FFF;
}
.redirect_container_result_update--disabled {
  cursor: not-allowed;
}
.redirect_container_result_utms_utm > .discount_container__content__code_line > .clean_input_container {
  margin: 0px 0px 0px 20px;
}
.redirect_container_result_expand {
  background-color: #F3F3F3;
  padding: 0;
  text-align: center;
  width: 40px;
  height: 25px;
  line-height: 25px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  margin-left: 50%;
}
.redirect_container_result_expand:hover {
  background-color: hsl(0, 0%, 96%);
  color: hsl(0, 0%, 15%);
}
.redirect_container_result_expand:active {
  background-color: hsl(0, 0%, 65%);
  color: #F3F3F3;
}

.discount_container__content__code_line .clean_input_container {
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 0;
  position: relative;
  top: -8px;
}
.discount_container__content__code_line .clean_input_container__label {
  font-size: 0.8em;
  margin: 0;
  padding: 0;
}
.discount_container__content__code_line .clean_select_for_form {
  /* width: 188px; */
  width: auto;
  font-size: 16px;
  /* line-height: 1; */
  border: 1px solid #C5C5C5;
  border-radius: 8px;
  height: 30px;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/32x32/br_down.png) no-repeat right #ddd;
  background-size: 14px;
  -webkit-appearance: none;
  /* background-position-x: 154px; */
  /* background-position-x: 30px; */
  background-position: right 10px center;
  background-color: #fff;
  padding: 0 30px 0 10px;
  cursor: pointer;
}