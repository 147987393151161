.ambassador_tab {
  margin-right: 20px;
  margin-bottom: 60px;
}
.ambassador_tab_count {
  text-align: center;
  font-size: 25px;
  margin-bottom: 30px;
  line-height: 28px;
}
.ambassador_tab_header, .ambassador_tab_values {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid grey;
}
.ambassador_tab_header > p, .ambassador_tab_values_value {
  margin: 10px 5px;
  width: 16.666%;
  color: #000;
  text-align: left;
}
.ambassador_tab_header {
  background-color: #adadad;
}
.ambassador_tab_header > p {
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
}
.ambassador_tab_values_value_icon {
  text-align: center;
  height: 24px;
}

.ambassador_tab_values_value > span, .ambassador_tab_values_value > .al-loading {
  font-size: 24px !important;
}
.ambassador_tab_values_value > .al-loading {
  color: red;
}
.ambassador_tab_values_value--done {
  color: green;
}
.ambassador_tab_values:nth-child(even) {
  background-color: #DADADA;
}