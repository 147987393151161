.dropdown_container__selected_value {
  /* display: block; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: white;
  border: rgba(9, 29, 54, 0.5) solid 1px;
  border-radius: 5px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.15px;
  font-weight: 400;
  min-height: 28px;
  line-height: 28px;
  cursor: pointer;
  text-align: left;
  padding: 0px 30px 5px 15px;
  overflow: hidden;
}
.dropdown_container__selected_value:hover {
  border: #091d36 solid 1px;
}
.dropdown_container__selected_value:focus {
  border: #091d36 solid 1px;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.3);
  /* box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); */
}
.dropdown_container__selected_value--disable {
  background-color: #DBDBDB;
  cursor: not-allowed;
}
.dropdown_container__selected_value__input_wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.dropdown_container__selected_value__content {
  overflow: hidden;
  position: absolute;
  outline:none;
  user-select: none;
  overflow-wrap: break-word;
  word-break: break-all;
  left: 5px;
  top: 6px;
  /* z-index: 200; */
  font-size: 12px;
}
.dropdown_container__selected_value__input {
  outline: none;
  border: none;
  background: none;
  /* padding: 0 40px 0 20px; */
  cursor: inherit;
  font-size: 12px;
  padding: 6px 0px 6px 0px;
  margin: 5px 2px 0 5px;
  overflow: hidden;
}
.dropdown_container__list {
  z-index: 200;
  margin: 0;
  padding: 8px 0;
  position: absolute;
  min-width: 252px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.3);
  /* box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); */
  list-style-type: none;
  max-height: 244px;
  overflow: auto;
}
.dropdown_container__list__value {
  height: 38px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 38px;
  padding: 0 20px;
}
.dropdown_container__list__value--hover {
  background-color: rgba(9, 29, 54, 0.16);
  cursor: pointer;
}
.dropdown_container__selected_value--icon {
  position: relative;
}
.dropdown_container__selected_value--icon::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: rgba(9, 29, 54, 0.5) transparent transparent transparent;
  position: absolute;
  right: 15px;
  top: 16px;
}
.dropdown_container__selected_value--icon_open {
  position: relative;
}
.dropdown_container__selected_value--icon_open::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: rgba(9, 29, 54, 0.5) transparent transparent transparent;
  position: absolute;
  right: 20px;
  top: 16px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

/* Chip */
.dropdown_container__selection_chips__chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #C2D2E9;
  justify-content: space-evenly;
  border-radius: 4px;
  padding: 6px 7px;
  margin: 5px 5px 0 5px;
  font-size: 10px;
}
.dropdown_container__selection_chips__chip > label {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0;
  line-height: 1;
}
.dropdown_container__selection_chips__chip > span {
  font-size: 12px;
  margin-left: 8px;
  cursor: pointer;
}
.dropdown_container__selection_chips__chip > span:hover {
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.05);
}
/* --- */