.reply {
  display: flex;
}

.reply_container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  background-color: #d0d7e1;
  border-radius: 10px;
  padding: 15px 10px;
}
.reply_container_textarea {
  position: relative;
}
.reply_container_textarea > .al_loading {
  position: absolute;
  top: 0px;
  background-color: transparent;
  width: 100%;
  padding: 35px 0px;
}
.reply_container_textarea > textarea {
  width: calc(100% - 22px);
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
}
.reply_container_error {
  color: red;
  margin-top: 2px;
  margin-bottom: 0px;
  font-style: italic;
}
.reply_container_feedback {
  cursor: pointer;
  color: #454545;
  font-style: italic;
  margin-top: 5px;
}
.reply_container_actions {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.reply_container_send, .reply_container_generate {
  display: flex;
  width: 40%;
  background: #3A4E7A;
  border: 1px solid #3A4E7A;
  border-radius: 16px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  padding: 0px 14px;
  margin-top: 15px;
}
.reply_container_send:hover, .reply_container_generate:hover {
  background: #fff;
  color: #3A4E7A;
}
.reply_container_send > span, .reply_container_generate > span {
  margin: 0px 0px 0px 10px;
}