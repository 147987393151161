.search-results {
  margin-top: 30px;
}
.search-results__influencer {
  /* display: flex; */
  background-color: #ffffff;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 24px 0;
}
.search-results__influencer-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-results__influencer-head__left {
  display: flex;
  width: 90%;
}
.search-results__influencer__image {
  background-color: #d8d8d8;
  border-radius: 23px;
  height: 46px;
  width: 46px;
  margin: 0 18px 0 25px;
}
.search-results__influencer__image-content {
  border-radius: 23px;
  height: 46px;
  width: 46px;
}
.search-results__influencer__name {
  font-weight: 500;
  width: 30%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.search-results__influencer__section {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  justify-content: center;
  /* align-items: center; */
}
.search-results__influencer__section__title {
  color: #3373c3;
}
.search-results__influencer__youtube-icon {
  height: 24px;
}
.search-results__influencer__instagram-icon {
  height: 24px;
}
.search-results__influencer__icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
}
.search-results__influencer__icon-container__link {
  display: flex;
  right: 0;
  padding: 2px;
  border-radius: 5px;
}
.search-results__influencer__checkbox {
  transform: scale(2);
}
.search-results__influencer__checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.search-results__influencer-head__expand {
  font-size: 30px;
  cursor: pointer;
}
.search-results__influencer-head__expand:hover {
  background-color: hsl(0, 0%, 98%);
  border-radius: 15px;
}
.search-results__influencer-body {
  padding: 0 50px;
  margin-top: 30px;
  height: auto;
  transition: max-height 0.2s ease, margin-top 0.2s ease;
  max-height: 1000px;
}
.search-results__influencer-body--collapsed {
  max-height: 0px;
  transition: max-height 0.1s ease, margin-top 0.1s ease;
  padding: 0 50px;
  margin-top: 0px;
  overflow: hidden;
}
.search-results__influencer-body__active-deals-line {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px black;
  padding: 20px 30px;
}
.search-results__influencer-body__active-deals-line__group {
  display: flex;
}
.search-results__influencer-body__active-deals-line__name {
  font-weight: 600;
}
.search-results__influencer-body__active-deals-line__date {
}
.search-results__influencer-body__active-deals-line__am {
  color: #979797;
  margin-left: 4px;
  width: 220px;
}
.search-results__influencer-body__active-deals-line__final-price-label {
  color: #3373c3;
}
.search-results__influencer-body__active-deals-line__final-price {
  margin-left: 10px;
  width: 50px;
}
.search-results__influencer-body__active-deals-line__deal-type-label {
  color: #3373c3;
}
.search-results__influencer-body__active-deals-line__deal-type {
  margin-left: 10px;
  width: 125px;
}
.search-results__influencer-body__active-deals-line__status-label {
}
.search-results__influencer-body__active-deals-line__status {
  text-transform: uppercase;
  margin-left: 4px;
  width: 115px;
}
