.quick_outreach_infoheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 30px;
  margin-bottom: 20px;
}
.quick_outreach_infoheader_search {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.quick_outreach_infoheader_search--title {
  font-family: "Roboto";
  font-size: 13px;
  line-height: 14px;
  margin-bottom: 4px;
  margin-left: 15px;
  color: #061529;
}
.quick_outreach_infoheader_search--input {
  font-size: 16px;
  font-family: "Roboto";
  padding: 0px 15px;
  background-color: #fff;
  border-radius: 16px;
  height: 36px;
  border: none;
}
.quick_outreach_infoheader_search--input:focus {
  outline: none;
}
.quick_outreach_infoheader_search--search_off {
  position: absolute;
  font-size: 20px;
  color: #484848;
  bottom: 8px;
  right: 10px;
  cursor: pointer;
}
.quick_outreach_infoheader_count_deal > p {
  margin: 0px;
  font-family: "Roboto";
  font-size: 16px;
}
.quick_outreach {
  margin-right: 20px;
  margin-bottom: 60px;
}
.quick_outreach_header {
  display: flex;
  flex-direction: row;
  background-color: #D0D7E1;
  padding: 13px 30px;
  border-radius: 20px;
}
.quick_outreach_header > * {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  font-family: "Roboto";
  font-size: 18px;
}

.quick_outreach-status,
.quick_outreach-followup,
.quick_outreach-lastoutreach,
.quick_outreach-channel,
.quick_outreach-payment {
  width: 14%;
}

.quick_outreach-actions {
  width: 8%;
}

.quick_outreach_header > p[data-order] {
  cursor: pointer;
}

.quick_outreach-email {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 20%;
  margin: 0px;
}
.quick_outreach-email > a {
  color: #000;
  font-weight: 400;
}
.quick_outreach-email_copy-email {
  display: none;
}
.quick_outreach-email:hover > .quick_outreach-email_copy-email {
  display: flex;
  font-size: 18px;
  margin-left: 14px;
  cursor: pointer;
}
.quick_outreach_order__icon {
  margin-left: 9px;
  background-color: #fff;
  border-radius: 100%;
  font-size: 19px;
}

.quick_outreach_deal .quick_outreach-status-name {
  display: inline-block;
  margin: 0;
  padding: 7px 12px;
  background-color: #EDF1F5;
  border-radius: 9px;
  text-align: center;
  line-height: 16px;
}

.quick_outreach-payment {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.quick_outreach-payment span {
  margin-left: 5px;
  font-size: 15px;
  cursor: default;
}
.quick_outreach-payment-status--requested {
  color: #D0D7E1;
}
.quick_outreach-payment-status--processing {
  color: #FFD84D;
}
.quick_outreach-payment-status--processed {
  color: #43A047;
}

.quick_outreach_deal {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #fff;
  font-family: "Roboto";
  font-size: 14px;
  margin-top: 12px;
  border-radius: 20px;
  padding: 19px 30px;
}
.quick_outreach_deal-name {
  margin: 6px 0 0;
  font-size: 10px;
  line-height: 12px;
  color: #3373C3;
}
.quick_outreach_deal > .quick_outreach-channel {
  display: grid;
  grid-template-columns: 24px 24px;
  grid-gap: 10px 32px;
}
.quick_outreach_deal > .quick_outreach-channel > a > img {
  max-width: 24px;
}
.quick_outreach-view_outreach {
  width: 2%;
  margin-left: 10px;
  color: #000;
}
.quick_outreach_deal .quick_outreach-actions button{
  background-color: #2F6CB8;
  color: #fff;
  border-radius: 16px;
  border: 1px solid #2F6CB8;
  cursor: pointer;
  height: 36px;
  width: 100px;
}
.quick_outreach-view_outreach > span {
  font-size: 18px;
}