.dragdrop__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #3A4E7A;
    width: 250px;
    height: 60px;
    border-radius: 16px;
    border: 1px solid #000;
    color: #fff;
    overflow: hidden;
}
.dragdrop__container > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 17px;
}
.dragdrop__container > div > span {
    margin-right: 7px;
}
.dragdrop__container--isActive {
    background-color: #C2D2E9;
    color: #000
}
