.channels-data > h2 {
  text-align: center;
  margin-top: 50px;
}
.channels-data__inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.channels-data__inputs > .channels-data__inputs__name, .channels-data__inputs > button {
  margin: 0px 25px;
}
.channels-data__inputs__separator {
  height: 66px;
  width: 1px;
  background-color: #878787;
}
.channels-data__inputs__name > p {
  margin-bottom: 0;
  font-size: 17px;
  text-decoration: underline;
}
.channels-data__inputs__name > input {
  height: 36px;
  font-size: 18px;
  padding-left: 8px;
}

.channels-upload__result__container {
  max-width: 500px;
  margin: 0 auto;
}
.channels-upload__result__container__data {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.channels-upload__result__container__data__status {
    margin: 0;
    font-size: 18px;
    text-decoration: underline;
    margin-right: 10px;
}
.channels-upload__result__container__data__value {
  margin: 0;
  font-size: 18px;
}
.channels-upload__result__container__dataxml {

}
.channels-upload__result__container__dataxml > p {
  font-size: 13px;
  font-family: "Arial";
  margin: 0;
  border: 1px solid #000;
  padding: 15px 10px;
  line-height: 1;
  margin: 15px 0px;
}