.confirmation-modal{
  display: flex;
  flex-direction: column;
  width: 35vw;
  min-width: 580px;
  max-height: 65vh;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  align-items: center;
}
.confirmation-modal--important {
  background-color: #F5E6E6;
}
.confirmation-modal__content {
  position: relative;
  height: 100%;
  /* height: 300px; */
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
  align-items: center;
  width: 370px;
}
.modal-head {
  margin-bottom: 30px;
  width: 100%;
}
.modal-head__line {
  display: flex;
  justify-content: center;
}
.modal-head__line__title {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  /* padding-left: 67px; */
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}
/* .modal-head__line__button {
  transform: translate(14px, -24px);
} */
.modal-head__line__button {
  transform: none;
  position: relative;
  top: -10px;
  right: 24px;
  width: 0;
}
.modal-head__separator {
  margin: 0 0 14px 0;
}
.modal-head__line__button .al_button {
  min-height: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
}
.modal-head__line__button .al_button__info_container__icon--no-child {
  margin: 0;
}
.modal-foot {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.modal-foot .al_button {
  width: 160px;
  height: 40px;
  text-transform: uppercase;
}
.modal-foot__cancel .al_button{
  background-color: #E53935;
  border: none;
}
.modal-foot__separator {
  width: 50px;
}