.youtube_views {
  padding: 1px 25px 56px 25px;
}
.youtube_views__content {
  width: 80%;
  background-color: #fff;
  padding: 35px 40px;
  border: 1px solid #000;
  border-radius: 16px;
  margin: 25px auto;
}
.youtube_views__content__search {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}
.youtube_views__content__search--title {
  margin: 0;
  font-size: 28px;
  letter-spacing: 0.8px;
  text-decoration: underline;
  line-height: 32px;
  text-align: center;
  margin-bottom: 24px;
}
.youtube_views__content__search > .mdl-button {

}

.youtube_views__data {
  background-color: #fff;
  padding: 38px 54px 0px 54px;
  border-top: 1px solid #bcbcbc;
  margin-top: 20px;
}
.youtube_views__data__user {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.youtube_views__data__user--image {
  width: 110px;
  height: 110px;
  border-radius: 100%;
}
.youtube_views__data__user_info {
  width: 30%;
  margin-left: 40px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.youtube_views__data__user_info--name {
  font-size: 24px;
  line-height: 26px;
  margin: 5px 0px;
}
.youtube_views__data__user_info--views, .youtube_views__data__user_info--subscribers, .youtube_views__data__user_info--country, .youtube_views__data__user_info--instagram, .youtube_views__data__user_info--email {
  font-size: 16px;
  line-height: 20px;
  margin: 5px 0px;
  display: flex;
  align-items: center;
}
.youtube_views__data__user_info--views > span, .youtube_views__data__user_info--subscribers > span, .youtube_views__data__user_info--country > span, .youtube_views__data__user_info--instagram > span, .youtube_views__data__user_info--email > span {
  margin-right: 10px;
}
.youtube_views__data__user_info--instagram {

}
.youtube_views__data__user_info--instagram > img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.youtube_views__data__user_info--instagram > a {
  color: #000;
}
.youtube_views__videos {
  display: flex;
  align-items: center;
  justify-content: center;
}
.youtube_views__videos__breakdown {
  margin-top: 50px;
  margin-bottom: 20px;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  width: 300px;
  padding: 10px ;
  /* padding: 50px 35px 0px 35px; */
}
.youtube_views__videos__breakdown--title {
  font-size: 17px;
  text-decoration: underline;
  margin-bottom: 15px;
}
.youtube_views__videos__breakdown--data {
  /* margin-left: 10px; */
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 23px;
}
.youtube_views__videos__breakdown__data {
  position: relative;
}
.youtube_views__videos__breakdown__data__text {
  /* font-size: 13px; */
  /* line-height: 16px; */
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 0px;
  /* padding: 2px 0px; */
}
.youtube_views__videos__breakdown__data__info {
  display: none;
}
.youtube_views__videos__breakdown__data:hover > .youtube_views__videos__breakdown__data__info {
  display: flex;
  position: absolute;
  width: calc(100% - 20px);
  margin: 0px;
  top: 25px;
  left: -4px;
  background-color: #e8ecf1;
  line-height: 18px;
  padding: 5px 12px;
  border: 1px solid #3a4e7a80;
  z-index: 2;
  border-radius: 8px;
}
.youtube_views__videos__breakdown__data__info:after, .youtube_views__videos__breakdown__data__info:before {
  content: "";
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
}
.youtube_views__videos__breakdown__data__info:after {
  top: -15px;
  border-color: transparent transparent #e8ecf1;
  left: 12px;
  border-width: 8px;
}
.youtube_views__videos__breakdown__data__info:before {
  border-width: 9px;
  left: 11px;
  top: -18px;
  border-color: transparent transparent #3a4e7a94; 
}

.youtube_views__videos__view_more {
  padding: 10px 30px;
  color: #fff;
  background-color: #05162a;
  border: 1px solid #05162a;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
}
.youtube_views__videos__view_more:hover {
  background-color: transparent;
  color: #05162a;
}

.youtube_cc_prediction {
  width: 80%;
  background-color: #fff;
  padding: 35px 40px;
  border: 1px solid #000;
  border-radius: 16px;
  margin: 25px auto;
}
.youtube_cc_prediction--title {
  margin: 0;
  font-size: 28px;
  letter-spacing: 0.8px;
  text-decoration: underline;
  line-height: 32px;
  text-align: center;
  margin-bottom: 24px;
}
.youtube_cc_prediction__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.youtube_cc_prediction__data {
  padding: 38px 10px 0px 10px;
  border-top: 1px solid #bcbcbc;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
/* youtube */
.prediction__data__youtube {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #000;
  padding: 10px 15px;
  border-radius: 12px;
  width: calc(50% - 42px);
  margin-right: 10px;
  position: relative;
}
.prediction__data__youtube--logo {
  width: 36px;
  position: absolute;
  top: 8px;
  right: 8px;
}
.prediction__data__youtube--picture {
  width: 30%;
  border-radius: 100%;
}
.prediction__data__youtube__data {
  margin-left: 25px;
}
.youtube__data--title {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.6px;
  line-height: 28px;
}
.youtube__data--subs {
  font-size: 16px;
  line-height: 24px;
}
.youtube__data--views {
  font-size: 16px;
  line-height: 24px;
}
/* instagram */
.prediction__data__instagram {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(50% - 42px);
  border: 1px solid #000;
  padding: 10px 15px;
  border-radius: 12px;
  margin-left: 10px;
  position: relative;
}
.prediction__data__instagram--logo {
  width: 24px;
  position: absolute;
  top: 8px;
  right: 8px;
}
.prediction__data__instagram--picture {
  width: 30%;
  border-radius: 100%;
}
.prediction__data__instagram__data {
  margin-left: 25px;
  display: flex;
  flex-wrap: wrap;
}
.instagram__data--name {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.6px;
  line-height: 28px;
  width: 100%;
  text-align: center;
}
.instagram__data_counts {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  text-align: center;
  margin-top: 10px;
}
.instagram__data_counts--posts, .instagram__data_counts--followers, .instagram__data_counts--following {

}
.instagram__data_counts--posts > p:first-child, .instagram__data_counts--followers > p:first-child, .instagram__data_counts--following > p:first-child {
  margin: 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.instagram__data_counts--posts > p:last-child, .instagram__data_counts--followers > p:last-child, .instagram__data_counts--following > p:last-child {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
}
.prediction__data_final {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.prediction__data_final__youtube {
  width: calc(50% - 42px);
  margin-right: 10px;
  position: relative;
  padding: 40px 15px;
  display: flex;
  flex-wrap: wrap;
}
.prediction__data_final__youtube--avgViews, .prediction__data_final__youtube--avgLikes, .prediction__data_final__youtube--avgDislikes {
  width: 33.3333%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.prediction__data_final__youtube--avgViews > i, .prediction__data_final__youtube--avgLikes > i, .prediction__data_final__youtube--avgDislikes > i {
  margin-right: 5px;
  font-size: 19px;
}
.prediction__data_final__youtube--avgViews > span, .prediction__data_final__youtube--avgLikes > span, .prediction__data_final__youtube--avgDislikes > span {
  font-size: 17px;
}
.prediction__data_final__youtube--videos {
  width: 100%;
  text-align: center;
  font-size: 17px;
}
.prediction__data_final__youtube--videos > span:first-child {
  text-decoration: underline;
  margin-right: 6px;
  font-size: 16px;
}
.prediction__data_final__youtube--videos > span:last-child {
  font-weight: 500;
}
.prediction__data_final__youtube--engagement {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 0px;
}
.prediction__data_final__youtube--engagement > i {
  font-size: 30px;
}
.prediction__data_final__youtube--engagement > span {
  font-size: 32px;
  margin-left: 10px;
}

.prediction__data_final__instagram {
  width: calc(50% - 42px);
  margin-left: 10px;
  position: relative;
  padding: 40px 15px;
  display: flex;
  flex-wrap: wrap;
}
.prediction__data_final__instagram--posts {
  width: 100%;
  text-align: center;
  font-size: 17px;
}
.prediction__data_final__instagram--posts > span:first-child {
  text-decoration: underline;
  margin-right: 6px;
  font-size: 16px;
}
.prediction__data_final__instagram--posts > span:last-child {
  font-weight: 500;
}
.prediction__data_final__instagram--engagement {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 0px;
}
.prediction__data_final__instagram--engagement > i {
  font-size: 30px;
}
.prediction__data_final__instagram--engagement > span {
  font-size: 32px;
  margin-left: 10px;
}

.prediction__data_final__value {
  width: 100%;
  text-align: center;
}
.prediction__data_final__value > p {
  width: 20%;
  margin: 20px auto 25px auto;
  border: 1px solid #06152a;
  font-size: 61px;
  line-height: 1;
  padding: 10px 30px;
  border-radius: 12px;
  color: #06152a;
}