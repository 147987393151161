/* MODAL */
.modal-outreach-podcast {
  position: relative;
  padding: 20px 50px;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
}
.modal-outreach-podcast__close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.modal-outreach-podcast__close:hover {
  background-color: rgba(58, 78, 122, 0.05);
  border-radius: 100%;
}
.modal-outreach-podcast__info-text {
  margin-top: 20px;
}
.modal-outreach-podcast__scrolling-container {
  overflow: auto;
  text-align: right;
}
.modal-outreach-podcast .al_button.contained {
  background-color: #2F6CB8;
}


/* TILE */
.podcast-tile {
  background-color: #D0D7E1;
  border-radius: 20px;
  text-align: center;
  padding: 20px 60px;
  margin-bottom: 20px;
  position: relative;
}
.podcast-tile__profile-link {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #000;
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 8px;
}
.podcast-tile__profile-link:hover {
  background-color: rgba(58, 78, 122, 0.05);
}
.podcast-tile__name {
  font-size: 24px;
  margin-top: 10px;
}
.podcast-tile__podcast-info {
  margin-bottom: 20px;
}
.podcast-tile__network-list,
.podcast-tile__email-list,
.podcast-tile__url-list {
  margin: 0;
  display: flex;
  justify-content: center;
}
.podcast-tile__url {
  text-transform: capitalize;
  color: #000;
  margin-left: 4px;
}
.podcast-tile__url:hover {
  text-decoration: underline;
}
.podcast-tile__network-list__network,
.podcast-tile__email-list__email {
  margin: 0 0 0 4px;
}
.podcast-tile__not-linked {
  margin-bottom: 10px;
}
.podcast-tile__not-linked__description {
  margin: 0;
}
.podcast-tile__not-linked__action {
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
}