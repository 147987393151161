.scrapper-working {
  font-size: 16px;
  margin-right: 30px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.scrapper-working--loading {
  margin-right: 5px;
  -webkit-animation: spin 2.6s linear infinite;
  -moz-animation: spin 2.6s linear infinite;
  animation: spin 2.6s linear infinite;
}
.scrapper-working__xml {
  margin: 0px;
}
.scrapper-working__processing {
  margin-bottom: 0px;
}
.scrapper-working__add > span {
  font-size: 14px;
}
.scrapper-working__add > button {
  height: 25px;
  min-width: 25px;
  width: 25px;
}
.scrapper-working__add > button > i {
  font-size: 18px;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }