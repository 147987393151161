.modal-head__line {
  display: flex;
  justify-content: center;
}
.modal-head__line__title {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  padding-left: 67px;
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
}
.modal-head__line__button {
  transform: translate(14px, -24px);
}
.modal-head__separator {
  margin: 0 0 14px 0;
}
.modal-foot{
  display: flex;
  justify-content: center;
}

.how-to-videos-information-modal {
  display: flex;
  flex-direction: column;
  width: 50vw;
  max-height: 65vh;
}
.how-to-videos-information-modal h2 {
  font-size: 20px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}
.how-to-videos-information-modal__content {
  position: relative;
  height: 100%;
  overflow: scroll;
}