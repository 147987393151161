.scrapping__info {
  text-align: center;
}
.scrapping__info > p {
  margin: 0;
  font-size: 16px;
}
.scrapping {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.scrapping-container {
  width: 30%;
  border: 1px solid #d4d4d4;
  box-shadow: 2px 2px 7px #a9a9a9;
  margin: 7px;
  padding: 10px;
}
.scrapping-container__title {
  font-size: 16px;
  text-decoration: underline;
}
.scrapping-container__content {

}
.scrapping-container__content > p {
  margin: 0px;
  font-size: 12px;
}
.proxy {
  font-size: 12px;
}