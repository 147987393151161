.influencer_container {
  width: calc(100% - 50px);
  margin: 100px auto 0 auto;
}
.influencer_container--title {
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 40px;
  margin-left: 15px;
}
.influencer__report {
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  padding: 10px 15px;
  border-radius: 16px;
  border: 1px solid #000;
  background-color: #fff;
}
.influencer__report__row--header > p {
  font-weight: bold;
}
.influencer__report__row {
  display: flex;
  flex-direction: row;
  width: calc(100% - 20px);
  padding: 10px;
  border-bottom: 1px solid #b6b6b6;
}
.influencer__report__row:nth-child(even) {background: #f1f1f1;}
/*.influencer__report__row:nth-child(odd) {background: #FFF}*/
.influencer__report__row:last-child {
  border: none;
}
/* .influencer__report__row > p {
  width: 10%;
} */
.influencer__report__row--title {
  width: 20%;
  margin-bottom: 0px;
}
.influencer__report__row--start, .influencer__report__row--end, .influencer__report__row--qty, .influencer__report__row--gross, .influencer__report__row--discount, .influencer__report__row--returns, .influencer__report__row--sales, .influencer__report__row--taxes, .influencer__report__row--total {
  width: 8.8888888%;
  margin-bottom: 0px;
  text-align: center;
}


.influencer__report__row--start {

}
.influencer__report__row--end {

}
.influencer__report__row--qty {

}
.influencer__report__row--gross {

}
.influencer__report__row--discount {

}
.influencer__report__row--returns {

}
.influencer__report__row--sales {

}
.influencer__report__row--taxes {

}
.influencer__report__row--total {
  text-align: right;
}