.deal_notes {
  background-color: #fff;
  border-radius: 20px;
  padding: 25px 35px;
  display: flex;
  flex-wrap: wrap;
}
.deal_notes_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 35px;
}
.deal_notes_input_text {
  width: 75%;
  box-sizing: border-box;
  font-family: "Roboto";
  border-radius: 16px;
  padding: 13px 24px;
  border: none;
  font-size: 15px;
  background-color: #f3f3f3;
}
.deal_notes_input_text:focus {
  outline: none;
}
.deal_notes_input_actions {
  width: 80px;
}
.deal_notes_input_add {
  background: #2F6CB8;
  border-radius: 16px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.deal_notes_input_cancel {
  border-radius: 16px;
  margin-top: 5px;
  padding: 3px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.deal_notes_input_cancel:hover {
  background: #f3f3f3;
}
.deal_notes_history {
  max-height: 220px;
  min-height: 160px;
  overflow: auto;
  width: 100%;
}
.deal_notes_history_note {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  font-family: "Roboto";
  padding: 7.5px 0;
  border-radius: 16px;
}
.deal_notes_history_note_delete {
  visibility: hidden;
  position: absolute;
  left: calc(75% - 35px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 0px;
  transition: height 0.3s, padding 0.3s;
  padding: 6px;
}
.deal_notes_history_note_delete--hover {
  position: absolute;
  flex-direction: column;
  z-index: 5;
  left: calc(75% - 35px);
  display: flex;
  justify-content: space-around;
  height: 60px;
  transition: height 0.3s, padding 0.3s;
  padding: 6px;
  border-radius: 16px;
  background-color: #f3f3f3;
}
.deal_notes_history_note_delete--icon {
  color: black;
  font-size: 17px;
  cursor: pointer;
  padding: 3px;
}
.deal_notes_history_note_delete--icon:hover {
  background-color: #0000000e;
  border-radius: 50%;
}
.deal_notes_history_note_content {
  width: 75%;
  box-sizing: border-box;
  background-color: #f3f3f3;
  padding: 13px 40px 13px 20px;
  border-radius: 16px;
  font-size: 12px;
  min-height: 0px;
  transition: min-height 0.3s;
}
.deal_notes_history_note_content--adam {
  background-color: #d0d7e1;
}
.deal_notes_history_note_content--hover {
  min-height: 72px;
  transition: min-height 0.3s;
}
.deal_notes_history_note_content--edition {
  background-color: #9b87bb;
  color: #fff;
}
.deal_notes_history_note_meta {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.deal_notes_history_note_meta_date {
  margin: 0px;
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #979797;
  line-height: 16px;
  text-align: right;
}
.deal_notes_history_note_meta_author {
  font-size: 8px;
  margin: 0px;
  letter-spacing: 0.2px;
  color: #979797;
  line-height: 20px;
}

.deal_notes--gray {
  background-color: #f1f3f4;
}
.deal_notes--gray .deal_notes_input_text {
  background: #ffffff;
}
.deal_notes--gray .deal_notes_history_note_content:not(.deal_notes--gray .deal_notes_history_note_content--edition) {
  background-color: #ffffff;
}
.deal_notes--gray .deal_notes_history_note_delete--hover {
  background-color: #ffffff;
}
.deal_notes--gray .deal_notes_input_text {
  border: 1px solid #000;
}