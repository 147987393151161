.quick-snapshot {
  margin-top: 30px;
  padding: 22px 30px;
  border-radius: 16px;
  background: #FFF;
  color: #000;
  letter-spacing: 0.15px;

  .quick-snapshot-header {
    display: flex;
    justify-content: space-between;

    h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }

    span {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      font-size: 25px;
      background: #F1F3F4;
      cursor: pointer;
    }
  }

  .quick-snapshot-elements {
    display: flex;
    margin-top: 2px;
    flex-wrap: wrap;

    .quick-snapshot-element {
      flex: 1 1 0px;

      display: inline-block;
      text-align: center;
      margin-right: 15px;
      padding: 15px 25px;
      border-radius: 8px;
      background: #F1F3F4;
      max-width: 30%;
      margin-top: 20px;

      .quick-snapshot-element-title {
        margin-bottom: 5px;
        font-size: 16px;
        line-height: normal;
      }

      .quick-snapshot-element-count {
        font-size: 24px;
        line-height: normal;
      } 
    }
  }
}