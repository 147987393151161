.qualification_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
}
.qualification__next {
  position: absolute;
  bottom: 48px;
  left: 18px;
  z-index: 9;
  background-color: dodgerblue;
  padding: 6px 15px;
  border-radius: 10px;
  color: #fff;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 17px;
  border: 1px solid dodgerblue;
  cursor: pointer;
}
.qualification__next:hover {
  background-color: #fff;
  color: dodgerblue;
}
.qualification__top_bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 85px;
}
.qualification__top_bar_count {
  margin: 0px 0px 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Roboto";
  font-size: 15px;
  color: #afafaf;
}
.qualification__top_bar_history {
  position: absolute;
  right: 85px;
}
.qualification__empty_message {
  width: 300px;
  padding: 20px 40px;
  background-color: #fff;
  border-radius: 16px;
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  top: 35vh;
  position: relative;
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  /* align-items: center; */
  /* align-content: center; */
  /* justify-content: center; */
}
.qualification__platforms {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  background-color: #fff;
  border-radius: 16px;
}
.qualification__platforms__platform {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 20px;  
  background-color: #fff;
  border-radius: 16px;
  width: 176px;
  cursor: pointer;
}
.qualification__platforms__platform__title {
  font-family: "Roboto";
  font-size: 16px;
  letter-spacing: 0.15px;
  margin: 0px 12px 0px 0px;
}
.qualification__platforms__platform__icon {
  height: 24px;
}
.qualification__platforms__platform--isSelected {
  background-color: #3A4E7A;
  color: #fff;
  border-radius: 16px;
}
.qualification__platforms__actions_containers_wrapper {
  display: flex;
}


.q_actions {
  width: calc(26% - 70px);
  margin: 20px 15px 0px 15px;
  height: calc(100vh - 155px);
  background-color: #fff;
  border-radius: 20px;
  padding: 27px 20px;
  position: relative;
  overflow-y: auto;
}
.q_actions__button {
  margin: 10px 0px;
  border-radius: 20px;
  border: 1px solid;
  padding: 10px 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.q_actions__button--decline:hover {
  background-color: #ed5555;
  color: #fff;
  border: 1px solid #000;
}
.q_actions__button--approve:hover {
  background-color: #3cb33c;
  color: #fff;
  border: 1px solid #000;
}
.q_actions__buttons--round {
  width: 20px;
  border: 1px solid;
  height: 20px;
  display: flex;
  border-radius: 100%;
  margin-right: 14px;
}
.q_actions__buttons--text {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.15px;
}
.q_actions__need_all_viewed {
  padding: 15px 20px;
  margin: 60px 0;
  border-left: orange 3px solid;
  font-weight: 300;
  background-color: aliceblue;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: block;
}

.q_actions__cocreation {
  background-color: #1815a0;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #1815a0;
  border-radius: 3px;
  font-size: 14px;
  letter-spacing: .8px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  cursor: pointer;
  height: 24px;
  padding: 5px 35px;
  width: 45%;
  margin-left: auto;
  margin-top: 25px;
}
.q_actions__cocreation:hover {
  background-color: #FFF;
  color: #1815a0;
  border: 1px solid #1815a0;
}
.q_actions > .al_tag_influencer {
  width: 100%;
}