.autocomplete-input {
  position: relative;
  /* filter: brightness(60%); */
}
.autocomplete-input__header {
  height: 40px;
}
.autocomplete-input__header__input {
  margin: auto 0;
  margin: 0;
  padding: 0 20px 0 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  /* text-align: center; */
  justify-content: center;
  margin-right: 5px;
  border-radius: 16px;
  border: none;
  height: 40px;
  width: 180px;
  outline: none;
}
.autocomplete-input__header__input--focus {
  margin: auto 0;
  margin: 0;
  padding: 0 20px 0 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  /* text-align: center; */
  justify-content: center;
  margin-right: 5px;
  border-radius: 16px;
  border: none;
  height: 40px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 180px;
  outline: none;
}
.autocomplete-input__dropdown {
  position: absolute;
  background: #ffffff;
  width: 220px;
  max-height: 200px;
  overflow: auto;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 10px 0;
  z-index: 1;
}
.autocomplete-input__dropdown__line {
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}
.autocomplete-input__dropdown__line--hover{
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: aliceblue;
}