.engravables_container {
  display: flex;
  flex-direction: row;
}
.engravable_button_confirm {
  color: white;
  background-color: #43A047;
  border: solid 1px #43A047;
}
.engravable_button_disabled {
  color: #fff;
  background-color: #979797;
  border: solid 1px #979797;
}
.engravable_button_no {
  color: white;
  background-color: #E53935;
  border: solid 1px #E53935;
}
.engravable_button_next {
  color: white;
  background-color: #3A4E7A;
  border: solid 1px #3A4E7A;
}


/* LEFT PANEL */
.engravable_leftpanel {
  width: calc(250px - 60px);
  border-radius: 20px;
  background-color: #F1F3F4;
  padding: 20px 30px;
}
.engravable_leftpanel_title {
  font-size: 20px;
  letter-spacing: 0.2px;
  margin: 0px;
}


/* STEP 1 */
.engravable_step {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 520px);
  margin: 0px 100px;
}
.engravable_step_images {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.engravable_step_image {
  width: 40%;
}
.engravable_step_image_title {
  text-align: center;
  font-size: 30px;
  letter-spacing: 0.2px;
  margin-bottom: 20px;
}
.engravable_step_1_image_img {
  width: 100%;
}

.engravable_step_image_file {
  background-color: #F3F3F3;
  border-radius: 20px;
  padding: 30px 20px;
}
.engravable_step_image_file_title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}
.engravable_step_image_file_info {
  margin: 30px 0px 0px 0px;
  font-style: italic;
}
.engravable_step_image_file_preview {
  text-align: center;
  margin: 0px;
  font-size: 50px;
}


.engravable_step_buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}

.engravable_step_engraving {
  width: 100%;
  margin-bottom: 100px;
}
.engravable_step_engraving_title {
  text-align: center;
  font-size: 30px;
  letter-spacing: 0.2px;
  margin-bottom: 20px;
}
.engravable_step_engraving_loading > .al_loading {
  background-color: transparent;
  width: unset;
}
.engravable_step_engraving_loading > .al_loading > .al_loading_icon {
  font-size: 150px;
}
.engravable_step_buttons_single {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}
.engravable_step_buttons_single > .engravable_button_confirm {
  max-width: 110px;
}

.engravable_step_engraving_confirm {
  width: 40%;
  margin: 0 auto;
  position: relative;
}
.engravable_step_engraving_confirm_preview {
  position: absolute;
  top: calc(50% - 10px);
  width: 100%;
  text-align: center;
  color: #8a8a8a;
  font-size: 18px;
}

.engravable_buttons_finish {
  display: flex;
  flex-direction: column;
}
.engravable_buttons_finish > .al_button {
  margin-bottom: 15px;
}



@page {
  size: 76.2mm 50.8mm;
  margin: 0;
}
@media print {
  body, html {
    position: relative;
    width: 76.2mm;
    height: 50.8mm;
    margin: 0px;
    overflow: hidden;
  }
  .notification-container {
    display: none;
    height: 0px !important;
    width: 0px !important;
  }
  .oklos-menu__nav {
    display: none;
  }
  .oklos-menu {
    height: 100%;
  }
  .oklos-menu__container {
    height: 100%;
  }
  .mdl-layout__content {
    margin: 0px !important;
    width: 100%;
    height: 100%;
  }
  .mdl-layout__drawer {
    display: none;
  }
  /* .giftnotes_print_note:nth-child(2n) {
    background-color: green;
  } */
  .engravables_container {
    position: relative;
    width: 76.2mm;
    height: 50.8mm;
  }
  .engravable_step_engraving_title {
    display: none;
  }
  .engravable_leftpanel {
    display: none;
  }
  .engravable_step {
    margin: 0px;
    width: 100%;
  }
  .engravable_step_engraving {
    margin: 0px;
  }
  .engravable_step_buttons {
    display: none;
  }
  #barcode {
    display: flex;
    flex-direction: column;
    width: 76.2mm;
    height: 50.8mm;
    overflow: hidden;
    justify-content: space-evenly;
  }
  #barcode > svg {
    width: 100%;
  }
}
