.scrapping_report {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.scrapping_report_data {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  min-width: 15%;
  margin-right: 20px;
  margin-bottom: 20px
}
.scrapping_report_data--title {
  margin-bottom: 10px;
  text-align: center;
  font-size: 15px;
  border-bottom: 1px solid #9e9e9e;
  padding-bottom: 10px;
}
.scrapping_report_data--value {
  margin: 0px;
  font-size: 25px;
  text-align: center;
  line-height: 1;
}
.scrapping_report_data--subvalue {
  margin: 5px 0px 0px 0px;
  font-size: 15px;
  text-align: center;
  line-height: 1;
  color: grey;
}

.scrapping_graph {
  padding-bottom: 50px;
}