.dashboard-v2__container {
  margin: 0 40px 40px 25px;
}

.dashboard-v2__container__header {
  display: flex;
  justify-content: space-between;
}

.dashboard-v2__container__header>h1 {
  font-size: 40px;
  margin: 0;
  line-height: 45px;
}

.dashboard-v2__container__header > .al_search {
  width: 400px;
}