.header_component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container__navigation {
  display: inline-block;
  margin-top: 30px;
  margin-left: 10px;
}
.container__navigation > a {
  margin: 0px 0px 0px 10px;
  color: #000;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.container__header {
  flex: 1;
  margin-top: 55px;
}
.container__header--no_how_to {
  margin-right: 133px;
}
.container__header > h1 {
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  margin: 10px 0px 15px 0px;
  line-height: 1;
}

.container__navigation:hover > a {
  border-bottom: 1px solid #000;
}
.container__navigation > a {
  margin: 0px 0px 0px 10px;
  color: #000;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.container__how_to {
  text-align: right;
  margin-top: 30px;
  margin-right: 10px;
  /* transform: translate(-20px, -75px); */
  /* z-index: -1; */
  /* width: fit-content; */
}