.q_instagram {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 67%;
  height: calc(100vh - 155px);
  position: relative;
  padding: 27px 20px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
}

.q_instagram__header {
  width: 28%;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}
.q_instagram__header_meta {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  margin-bottom: 28px;
}
.q_instagram__header_meta_link {
  width: 30%;
  margin-right: 20px;
}
.q_instagram__header_meta_picture {
  width: 100%;
  height: auto;
  border-radius: 100%;
}
.q_instagram__header_meta_details {
  width: 70%;
}

.q_instagram__header_meta_details--name {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  margin-bottom: 12px;
  line-height: 18px;
}
.q_instagram__header_meta_details--followers, .q_instagram__header_meta_details--following, .q_instagram__header_meta_details--posts  {
  margin: 0px;
  font-family: "Roboto";
  letter-spacing: 0.15px;
  line-height: 20px;
}
.q_instagram__header_meta_details--followers {

}
.q_instagram__header_meta_details--following {

}
.q_instagram__header_meta_details--posts {

}

.q_instagram__header_name {
  font-family: "Roboto";
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.q_instagram__header_emails {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px
}
.q_instagram__header_emails > span {
  font-family: "Roboto";
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 18px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.q_instagram__header_general_det {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px;
}
.q_instagram__header_general_det > span {
  font-size: 18px;
  margin-right: 6px;
}
.q_instagram__header_general_det_value {
  font-size: 14px !important; 
}


.q_instagram__posts {
  width: 67%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: scroll;
}

.q_instagram__posts__load {
  width: 35%;
  margin: 0 auto;
  text-align: center;
  background-color: #06152a;
  font-family: "Roboto";
  font-size: 16px;
  color: #fff;
  padding: 10px 0px;
  border-radius: 18px;
  cursor: pointer;
  margin-bottom: 15px;
  border: 1px solid #06152a;
  max-height: 20px;
}
.q_instagram__posts__load:hover {
  background-color: #fff;
  color: #06152a;
}

.q_instagram__update {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.q_instagram__update > input {
  height: 44px;
  width: 40%;
  border: none;
  background-color: #F3F3F3;
  padding: 0px 20px;
  border-radius: 22px;
  font-family: "Roboto";
  letter-spacing: 0.15px;
}
.q_instagram__update > input:focus {
  outline: none;
}
.q_instagram__update--button {
  height: 42px;
  border-radius: 22px;
  border: 1px solid #2F6CB8;
  background-color: #2F6CB8;
  padding: 0px 40px;
  color: #fff;
  line-height: 44px;
  font-size: 17px;
  font-family: "Roboto";
  letter-spacing: 0.15px;
  margin-left: 16px;
  cursor: pointer;
}
.q_instagram__update--button:hover {
  background-color: #FFF;
  color: #2F6CB8;
}
