.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.al_notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 15px;
  border-radius: 2px;
  color: #FFF;
  background-color: #CCC;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}
.al_notification_info {
  background-color: #2f96b4;
}
.al_notification_success {
  background-color: #51a351;
}
.al_notification_warning {
  background-color: #f89406;
}
.al_notification_error {
  background-color: #bd362f;
}
.al_notification_message--title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-family: "Roboto";
  line-height: 22px;
  font-weight: normal;
  margin: 0px;
}
.al_notification_message--icon {
  font-size: 18px;
  margin-right: 15px;
}
.al_notification_message--description {
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
}

.al_notification_message--title:hover, .al_notification_message--title:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4;
}
@keyframes progressBar {
  0% { width: 0%; }
  100% { width: 100%; }
}
.al_progressbar_container {
  width: 100%;
  height: 5px;
  border: none;
  border-radius: 0px;
  margin-top: 10px;
  background-color: #e8ecf1;
}
.al_progressbar_container_bar {
  background: #06152a;
  height: 100%;
  width: 0;
  text-align: right;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  animation-name: progressBar;
  animation-timing-function: ease-in;
  -webkit-animation-name: progressBar;
  -webkit-animation-timing-function: ease-in;
}
