.add-many-influencers {
  padding: 15px 30px;
}
.add-many-influencers__template {
  background-color: #ffffff;
  padding: 20px 30px;
  border-radius: 15px;
}
.add-many-influencers__template > .al_button.contained {
  border-radius: 14px;
}
.add-many-influencers__upload {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px 30px;
  border-radius: 15px;
  display: flex;
}
.add-many-influencers__upload > button:disabled {
  background-color: rgb(134, 134, 134);
  border-color: rgb(134, 134, 134);
  cursor: not-allowed;
}
.add-many-influencers__side {
  width: 50%;
}
.add-influencers-results {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px 30px;
  border-radius: 15px;
}
.add-influencers-results__error {
  margin: 0;
  text-align: center;
  vertical-align: middle;
  line-height: 36px;
  min-width: 64px;
  border: 2px solid rgb(255, 81, 0);
  border-radius: 2px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 14px;
  font-weight: 800;
  display: flex;
  align-items: stretch;
  color: rgb(255, 81, 0);
  margin-bottom: 5px;
}
.add-influencers-results__error__icon {
  padding-top: 10px;
  margin-right: 3px;
  margin-left: 10px;
}
.add-influencers-results__error__text {
  padding-top: 7px;
}
.add-influencers-results__table {
  width: 100%;
}
.add-influencers-results__table__header {
  display: flex;
  border-bottom: solid black 1px;
  padding: 14px 0;
  font-weight: 500;
  margin-bottom: 12px;
}
.add-influencers-results__table__content__line {
  display: flex;
  /* padding: 12px; */
  border-radius: 8px;
}
.add-influencers-results__table__content__line--odd {
  background-color: #F1F3F4;
}
.add-influencers-results__table__header__cell {
  width: 25%;
  padding: 0 24px;
}
.add-influencers-results__table__content__cell {
  width: 25%;
  padding: 12px 24px;
}
.add-many-influencers__side__title {
  margin-bottom: 10px;
}
.add-many-influencers__side__by-file > .dragdrop__container {
  width: 80%;
}
.copy-paste-input {
  width: 80%;
  height: 60px;
  border-radius: 16px;
  background-color: #3A4E7A;
  color: white;
  border: none;
  outline: none;
  caret-color: transparent;
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  cursor: pointer;
}
.copy-paste-input::placeholder {
  color: white;
  opacity: 1;
}
.copy-paste-input--selected {
  background-color: #C2D2E9;
}
.copy-paste-input--selected::placeholder {
  color: black;
}