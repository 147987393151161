.howto_inventory {
  max-width: 50%;
}
.inventory_content {
  margin-bottom: 28px;
}
.inventory_content--title {
  font-size: 16px;
  margin-bottom: 6px;
  font-weight: 400;
}
.inventory_content__details {

}
.inventory_content__details > a {
  color: #000;
  text-decoration: underline;
}