.transaction-search-input {
  display: flex;
}

.transaction-search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 20%;
  min-width: 270px;
  border-radius: 16px;
  margin: 1px;
  /* padding-right: 16px; */
  background-color: #ffffff;
  /* margin-right: -36px; */
}

.transaction-search-input-wrapper:hover {
  border: rgba(0, 0, 0, 0.2) solid 1px;
  margin: 0px;
}

.transaction-search-input-wrapper:focus-within {
  border: rgba(0, 0, 0, 0.8) solid 1px;
  margin: 0px;
}
.transaction-actions {
  color: #000;
}
.transaction-actions > span {
  font-size: 18px;
}


.transaction-search-input input {
  width: 100%;
  height: 36px;
  padding: 0 15px 0 15px;
  margin: 0;
  background: none;
  border: none;
  outline: none;
}

.transaction-search-input button {
  cursor: pointer;
  height: 36px;
  margin: 0;
  padding: 0 7px;
}

.transaction-search-input__btn-remove-search {
  background: none;
  border: none;
  position: relative;
  right: 0px;
  color: rgba(0, 0, 0, 0.60);
}

.transaction-search-input__btn-remove-search:hover {
  color: rgba(0, 0, 0, 0.80);
}

button.transaction-search-input__btn-search {
  background: #2F6CB8;
  border: none;
  color: #ffffff;
  border-radius: 16px;
  padding: 0 16px;
  margin: 1px 1px 1px 15px;
}

.transaction-status--requested {
  color: #D0D7E1;
  margin-left: 5px;
  margin-top: 4px;
}

.transaction-status--processing {
  color: #ffd84d;
  margin-left: 5px;
  margin-top: 4px;
}

.transaction-status--processed {
  color: #43a047;
  margin-left: 5px;
  margin-top: 4px;
}

/* Table */
.transaction .al_table__wrapper {
  padding: 0 20px 0 0;
}

.transaction table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.transaction .al_table__header__row {
  background: #d0d7e1;
  border-radius: 16px;
}

.transaction th {
  cursor: initial;
}

.transaction th:first-child {
  border-radius: 8px 0 0 8px;
}

.transaction th:last-child {
  border-radius: 0 8px 8px 0;
}

.transaction td {
  background: #fff;
  height: 40px;
}

.transaction td:first-child {
  border-radius: 8px 0 0 8px;
}

.transaction td:last-child {
  border-radius: 0 8px 8px 0;
  text-align: center;
}

/* .shipping_component__content__form_products  td:first-child {
  border-radius: 8px 0 0 8px;
  text-align: left;
}
.shipping_component__content__form_products  td:last-child {
  border-radius: 0 8px 8px 0;
} */