.oi_deal_content_container {
  background-color: #fff;
  border-radius: 20px;
  padding: 25px 35px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 20px;
  max-height: 430px;
  overflow: auto;
}
.oi_deal_content_container_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.oi_deal_content_container_header_title {
  margin-bottom: 0px;
  font-family: "Roboto";
  color: #3373c3;
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 20px;
}
.oi_deal_content_container_header_add {
  height: 36px;
  line-height: 36px;
  background-color: #3373c2;
  padding: 0px 18px;
  color: #fff;
  border-radius: 16px;
  cursor: pointer;
}

.oi_deal_content_container_content {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #f1f3f4;
  padding: 20px 25px 50px 25px;
  border-radius: 8px;
  margin-bottom: 14px;
}
.oi_deal_content_container_content_newcontent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.oi_deal_content_container_content_newcontent
  > .outreach_influencer_deal_update_button {
  position: unset;
}
.oi_deal_content_container_content_newcontent_quantity {
  border-radius: 5px;
  border: rgba(9, 29, 54, 0.5) solid 1px;
  padding: 0 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 44px;
  letter-spacing: 0.15px;
  outline: none;
  width: 80px;
}
.oi_deal_content_container_content_newcontent .dropdown_container {
  width: 250px;
}
.oi_deal_content_container_content_edit {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.oi_deal_content_container_content_edit > span {
  color: #2d2d2d;
}
.oi_deal_content_container_content_edit > span:hover {
  color: #3a4e7a;
}
.oi_deal_content_container_content_type {
  width: 100%;
  font-size: 24px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
  display: flex;
  line-height: 30px;
  margin-bottom: 24px;
  text-transform: capitalize;
}
.oi_deal_content_container_content_detail {
  width: 50%;
  margin-bottom: 36px;
}
.oi_deal_content_container_content_title {
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #3373c3;
}
.oi_deal_content_container_content_value {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0px;
  color: #000;
}
.oi_deal_content_container_content_value_date {
  font-size: 22px;
  line-height: 26px;
}
