.al_search {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.al_search--input {
  border: none;
  outline: none;
  background-color: #fff;
  height: 40px;
  border-radius: 16px;
  padding-left: 15px;
  padding-right: 44px;
  letter-spacing: 0.15px;
  font-family: Roboto;
  width: 100%;
}
.al_search--input::placeholder {
  color: #979797;
}

.al_search--icon {
  line-height: 40px;
  position: absolute;
  right: 12px;
  color: #06152a;
  user-select: none;
}