small {
  position: absolute;
  bottom: 0;
  font-size: 11px;
  line-height: 1;
}
.emails {
  padding-bottom: 50px;
}
.emails_container {
  position: relative;
  width: calc(35% - 55px);
  margin-left: 15px;
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
  overflow-y: scroll;
  height: 100%;
}
.emails_container_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.emails_container_header > p {
  font-size: 18px;
  font-weight: 800;
  margin: 0px
}

.emails_load_more {
  display: flex;
  background: #3A4E7A;
  border: 1px solid #3A4E7A;
  border-radius: 16px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  padding: 0px 14px;
}
.emails_load_more:hover {
  background: #fff;
  color: #3A4E7A;
}
.emails_load_more > p {
  margin: 0px 10px 0px 0px;
}
.email_reply {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  background-color: #3A4E7A;
  color: #FFF;
  border-radius: 100px;
  height: 28px;
  border: 1px solid #3A4E7A;
}
.email_reply:hover {
  background-color: #FFF;
  color: #3A4E7A;
}
.email_reply > span {
  margin-left: 10px;
}

.reply_popup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 80%;
  right: calc(30% + 50px);
  position: fixed;
  z-index: 1;
  border: 2px solid #3A4E7A;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  bottom: 0px;
  background-color: #d0d7e1;
  width: 60%;
  padding-top: 15px;
}
.reply_popup_header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
}
.reply_popup_header > p, .reply_popup_feedback > p {
  font-size: 18px;
  font-weight: 800;
  margin: 0px;
}
.reply_popup_container {
  width: calc(50% - 20px);
}
.reply_popup > .reply_container {
  width: 100%;
  border-radius: 0px;
  margin: 0px;
}
.reply_popup_feedback {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  background-color: #d0d7e1;
  border-radius: 10px;
  padding: 15px 10px;
}
.reply_popup_feedback_submit {
  display: flex;
  width: 40%;
  background: #3A4E7A;
  border: 1px solid #3A4E7A;
  border-radius: 16px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  padding: 0px 14px;
  margin-top: 15px;
}
.reply_popup_feedback_submit:disabled {
  background: #b5b5b5;
  border: 1px solid #b5b5b5;
  color: #e1e1e1;
}
.reply_popup_feedback_submit:hover {
  background: #fff;
  color: #3A4E7A;
}
.reply_popup_feedback > textarea {
  height: 100px;
  border-radius: 10px;
  border: 1px solid;
  padding: 10px;
}
.reply_popup_original {
  width: calc(50% - 20px);
  padding: 10px;
  height: 100%;
}
.reply_popup_original_content {
  background-color: #FFF;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}
.reply_popup_header_close {
  display: flex;
  position: absolute;
  right: 5px;
  top: 5px;
}