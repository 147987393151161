.modal-typeform-information {
  width: 35vw;
  min-width: 580px;
  max-height: 65vh;
  padding: 30px;
  overflow: auto;
  position: relative;
}
.modal-typeform-information .al_button {
  height: 24px;
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
  position: absolute;
  top: 5px;
  right: 10px;
  width: 30px;
  text-align: center;
}
.modal-typeform-information .al_button__info_container {
  justify-content: center;
  height: 30px;
}
.modal-typeform-information .al_button__info_container__icon--no-child {
  margin: 0;
}
