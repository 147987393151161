.youtubemodal-wrapper {
  height: 100%;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: scroll;
}
.youtubemodal-wrapper__header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 22%;
  background-color: #c5c5c5;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 10px;
}
.wrapper__header__thumbail {
  width: 22%;
  height: 100%;
  margin-right: 15px;
}
.wrapper__header__thumbail > img {
  width: 100%;
  border-radius: 100%;
}
.wrapper__header__info {
  width: 78%;
}
.wrapper__header__info > p {
  margin: 0px;
}
.header_view-channel {
  border: 1px solid #ff0100;
  background-color: #ff0100;
  font-size: 12px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 99;
  padding: 0px;
  border-radius: 5px;
  line-height: 1;
}
.header_view-channel:hover {
  border: 1px solid #ff0100;
  background-color: #fff;
}
.header_view-channel:hover > a {
  color: #ff0100;
}
.header_view-channel > a {
  padding: 8px 7px;
  display: block;
}
.header_close {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  cursor: pointer;
}
.wrapper__header__info > .info-nameChannel {
  font-size: 18px;
  font-weight: bold;
}
.wrapper__header__info > .info-subscriber, .wrapper__header__info > .info-views {
  font-size: 15px;
}
.youtubemodal-wrapper__content {
  padding: 10px 10px 87px 10px;
}
.youtubemodal-wrapper__content__tabs {
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 45px;
}
.youtubemodal-wrapper__content__tabs__tab {
  width: 50%;
  margin: 0px;
  font-size: 16px;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
}
.youtubemodal-wrapper__content__tabs__tab.isActive {
  color: #06152a;
  border-bottom: 2px solid #06152a;
}
.youtubemodal-wrapper__content__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.youtubemodal-wrapper__content__header > .content-status {
  height: 30px;
  margin-right: 15px;
  line-height: 30px;
  margin: 0;
  padding: 0 26px;
}
.youtubemodal-wrapper__content__header > .content-am > span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding: 4px 12px;
  background-color: #d8d8d8;
  border-radius: 8px;
}
.youtubemodal-wrapper__content > p {
  display: flex;
  margin: 0px 0px 5px 0px;
}
.youtubemodal-wrapper__content__main {
  width: 100%;
  margin: 20px auto;
}
.youtubemodal-wrapper__content__main > .content_val {
  margin: 0;
  padding: 8px 26px;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.youtubemodal-wrapper__content__main > .content_val:nth-child(odd) {
  background-color: #eef1f5;
}
.youtubemodal-wrapper__content__main > .content_val > .content_val--key {
  width: 30%;
}
.youtubemodal-wrapper__content__main > .content_val > .content_val--val {
  width: 70%;
  text-align: right;
}

.youtubemodal-wrapper__content > p > span {
  font-weight: bold;
}
.youtubemodal-wrapper__content > p > i {
  color: #4f4f4f;
  font-size: 16px;
  line-height: 24px;
  margin-left: 6px;
}
.youtubemodal-wrapper__content > .content-email--insta {

}
.youtubemodal-wrapper__content > .content-email--same {

}
.youtubemodal-wrapper__content > .content-email--diff {

}
.youtubemodal-wrapper__content > .content-email--yt {

}
.youtubemodal-wrapper__content > .content-email--empty {

}
.youtubemodal-wrapper__content--update {
  padding: 10px;
}

.youtubemodal-wrapper__content--update > .content--update__title {
  text-align: center;
  font-size: 18px;
  margin: 15px 0px;
  text-decoration: underline;
}
.youtubemodal-wrapper__content--update > .content--update__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 0px 10px;
}
.youtubemodal-wrapper__content--update > .content--update__container > .content--update__container-input {
  width: 100%;
  height: 32px;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid #b3b3b3;
  font-size: 15px;
  padding: 0px 10px;
}
.youtubemodal-wrapper__content--update > .content--update__container > .content--update__container-button--disabled {
  font-weight: 400;
  background-color: #979797;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  border: 1px solid #979797;
  cursor: not-allowed;
  font-size: 11px;
}
.youtubemodal-wrapper__content--update > .content--update__container > .content--update__container-button {
  font-weight: 400;
  background-color: #1059e1;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #1059e1;
  cursor: pointer;
}
.youtubemodal-wrapper__content--update > .content--update__container > .content--update__container-button:hover {
  background-color: #fff;
  color: #1059e1;
}
.youtubemodal-wrapper__content--update > .content--update__limit {
  padding: 0px 10px;
  font-size: 12px;
  color: #ff2222;
}
.youtubemodal-wrapper__content--message {
  padding: 10px;
}
.youtubemodal-wrapper__content--message > p {
  margin: 0px;
  text-align: center;
  font-size: 17px;
}
.youtubemodal_videos {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.youtubemodal_video {
  width: calc(50% - 30px);
  position: relative;
  padding: 0px 15px 20px 15px;
}
.youtubemodal_video__thumbnail {
  position: relative;
  display: flex;
  overflow: hidden;
}
.youtubemodal_video__thumbnail__image {
  width: 100%;
  margin: -10% 0px;
}
.youtubemodal_video__thumbnail__duration {
  position: absolute;
  background-color: #0000007a;
  color: #fff;
  padding: 0px 5px;
  bottom: 3px;
  right: 3px;
}
.youtubemodal_video__title {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .1px;
  line-height: 20px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 4px;
  margin-bottom: 0px;
}
.youtubemodal_video__stats {
  display: flex;
  flex-wrap: wrap;
}
.youtubemodal_video__stats__views, .youtubemodal_video__stats__likes, .youtubemodal_video__stats__dislikes {
  font-size: 12px;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  align-items: center;
}
.youtubemodal_video__stats__views > i, .youtubemodal_video__stats__likes > i, .youtubemodal_video__stats__dislikes > i {
  font-size: 17px;
  margin-right: 5px;
}
.youtubemodal_video__stats__views {
  margin-top: 8px;
  margin-bottom: 5px;
  width: 100%;
}
.youtubemodal_video__stats__likes {
  color: #227a22;
  width: 50%;
}
.youtubemodal_video__stats__dislikes {
  color: #ff2d2d;
  width: 50%;
  justify-content: flex-end;
}
.youtubemodal_video__date {
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 12px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: .3px;
  color: #2b2b2b;
  text-align: right;
}
.youtubemodal-wrapper__content_qualif {
  position: fixed;
  right: 28px;
  width: 45%;
  bottom: 0px;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  border-top: 1px solid #949494;
}
.youtubemodal-wrapper__content_qualif__button {
  background-color: #06152a;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 35px;
  border: 1px solid #06152a;
  border-radius: 3px;
  font-size: 15px;
  letter-spacing: 0.8px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  cursor: pointer;
  margin-right: 25px;
}
.youtubemodal-wrapper__content_qualif__button:hover {
  background-color: #06152a;
}
.youtubemodal-wrapper__content_qualif__button > i {
  font-size: 16px;
  margin-left: 12px;
}
