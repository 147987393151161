.al_table_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.al_table__title--products_count {
  font-size: 13px;
}
.al_table__export {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #585858;
  cursor: pointer;
}
.al_table__export:hover {
  color: #000;
}
.al_table__export > span {
  margin-left: 5px;
}
.al_table_detail__wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}
.al_table_detail__title {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  margin-bottom: 15px;
}
.al_table_detail {
  width: 100%;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0.15px;
  /* border: none; */
  border-collapse: collapse;
  border-spacing: 10px 10px;
}
.al_table_detail__header {
  width: 100%;
  background-color: #C2D2E9;
  text-align: left;
  height: 42px;
  line-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.al_table_detail__header__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
/* .al_table_detail__header__row__cell:first-child {
  padding-left: 20px;
} */
/* .al_table_detail__header__row__cell:last-child {
  padding-right: 20px;
} */
.al_table_detail__header__row__cell {
  cursor: pointer;
  line-height: 1;
  padding: 0px 5px;
  font-size: 13px;
}
.al_table_detail__body__row {
  border-bottom: #d3d3d3 solid 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.al_table_detail__body__row:hover {
  background-color: #eef1f5;
}
.al_table_detail__body__row__cell {
  line-height: 20px;
  padding: 0px 5px;
  border-right: 1px solid #d3d3d3;
  min-height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}
.al_table_detail__body__row__cell--factory {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.al_table_detail__body__row__cell--click {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  border-right: none;
}
.al_table_detail__body__row__cell--click > span {
  font-size: 30px;
}
.al_table_detail__body__row__cell--green {
  color: #43A047;
}
.al_table_detail__body__row__cell--yellow {
  color: #f59822;
}
.al_table_detail__body__row__cell--red {
  color: #E53935;
}
.al_table_detail__body__row__cell:last-child {
  border-right: none;
}

.isOpen {
	transform: rotate(180deg);
}
.al_table_detail__body__row__cell__information {
  width: 100%;
}
.al_table_detail__details_container {
  margin-left: 20px;
  margin-bottom: 25px;
  width: 100%;
  border-bottom: 1px solid #d3d3d3;
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
}
.al_table_detail__details_container_header {
  display: flex;
  flex-direction: row;
  background-color: #ddeafd;
}
.al_table_detail__details_container_header > p {
  font-size: 13px;
  text-align: center;
  line-height: 16px;
  margin: 0px;
  width: 9.09%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 5px;
  border-right: 1px solid #d3d3d3;
}
.al_table_detail__details_container_header > p:last-child {
  border-right: none;
}
.al_table_detail__details_container_content_element {
  display: flex;
  flex-direction: row;
  align-items: initial;
}
.al_table_detail__details_container_content_element--disable {
  color: #9c9c9c;
}
.al_table_detail__details_container_content_element > p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  margin: 0px;
  padding: 0px 5px;
  min-height: 37px;
  line-height: 37px;
  width: 9.09%;
  border-bottom: 1px solid #dedede;
}
.al_table_detail__details_container_content_element:last-child > p {
  border-bottom: none;
}