.scrapping_keyword {
  margin-right: 20px;
}

.scrapping_keyword_wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
  margin-bottom: 40px;
}
.scrapping_keywords_position {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  background-color: white;
  padding: 32px 55px;
  border-radius: 20px;
  margin-right: 15px;
  margin-left: 15px;
}
.scrapping_keywords_position .dropdown_container {
  width: 200px;
  margin-right: 20px;
}
.scrapping_keywords_position .dropdown_container__selected_value {
  height: 40px;
}
.scrapping_keywords_position .dropdown_container__selected_value--icon::after {
  top: 20px;
}
.scrapping_keywords_bulk {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 32px 55px;
  border-radius: 20px;
  margin-left: 15px;
}
.scrapping_keywords_bulk > span {
  margin-right: 40px;
}
.scrapping_keywords_bulk_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  border-radius: 14px;
}
.scrapping_keyword .button_disabled {
  background-color: rgb(134, 134, 134);
  border-color: rgb(134, 134, 134);
  cursor: not-allowed;
}
.scrapping_keywords_bulk_button .material-icons {
  margin-right: 20px;
}
.scrapping_keywords_bulk_loading {
  display: flex;
  align-items: center;
}
.scrapping_keywords_bulk_loading i {
  margin-left: 20px;
  margin-bottom: 15px;
}

.scrapping_keyword_new {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-left: 15px;
  background-color: white;
  padding: 32px 55px;
  border-radius: 20px;
}
.scrapping_keyword_new__input {
  width: 100%;
  font-size: 16px;
  font-family: "Roboto";
  padding: 0px 15px;
  background-color: #F3F3F3;
  border-radius: 16px;
  height: 36px;
  border: none;
}
.scrapping_keyword_new__input:focus {
  outline: none;
}
.scrapping_keyword_new__remove {
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  color: #484848;
  top: calc(50% - 10px);
  right: 200px;
}
.scrapping_keyword_new__add {
  cursor: pointer;
  font-size: 16px;
  font-family: "Roboto";
  border-radius: 16px;
  height: 36px;
  border: none;
  padding: 0px 18px 0px 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  background-color: #3A4E7A;
  color: #fff;
  width: 100px;
}
.scrapping_keyword_new__add > .material-icons {
  font-size: 20px;
  margin-left: 6px;
}

.keywords {
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 16px;
  padding: 20px 40px 40px 40px;
  height: calc(100vh - 270px);
  overflow-y: scroll;
}
.keywords--title {
  font-family: "Roboto";
  font-size: 15px;
  text-align: center;
  line-height: 37px;
  margin-bottom: 0px;
}
.keyword_headers {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  background-color: #EEF1F5;
  font-weight: bold;
  font-family: "Roboto";
  font-size: 16px;
  border-bottom: 1px solid rgb(71, 71, 71);
}
.keyword_headers > .keyword-author, .keyword_headers > .keyword-date {
  font-size: 16px;
}
.keyword {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  font-family: "Roboto";
  font-size: 16px;
}
.keyword--empty {
  display: none;
}
.keyword:nth-child(even) {
  background-color: #EEF1F5;
}
.keyword-id {
  width: 24px;
  margin-left: 10px;
  font-size: 14px;
}
.keyword-sort {
  width: 24px;
}
.keyword-kw {
  width: 33.333%;
  padding-left: 15px;
}
.keyword-author {
  width: calc(37.333% - 24px);
  font-size: 14px;
}
.keyword-date {
  width: calc(29.333% - 24px);
  font-size: 14px;
}
.keyword-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  margin-right: 10px;
  cursor: pointer;
}
.keyword-delete:hover {
  color: red;
}
.keyword-down {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  margin-left: 10px;
}
.keyword-down > span {
  font-size: 14px;
  color: grey;
}