.add_tag {
  display: flex;
  flex-direction: row;
}
.add_tag_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 0px 45px;
}
.add_tag_container:first-child {
  border-right: 1px solid #3A4E7A;
}
.add_tag_container_title {

}
.add_tag_container_form {
  display: flex;
  width: 100%;
}
.add_tag_container_input {
  display: flex;
  width: calc(80% - 25px);
  margin-right: 25px;
  font-size: 16px;
  padding: 0px 10px;
  border: none;
  border-bottom: 1px solid #3A4E7A;
}
.add_tag_container_button {
  width: 20%;
}
.add_tag_container_current {
  margin-top: 50px;
}
.add_tag_container_current_title {
  text-decoration: underline;
  font-weight: bold;
}
.add_tag_container_current_all {
  display: flex;
  flex-wrap: wrap;
}
.add_tag_container_current_tag {
  background-color: #C2D2E9;
  min-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 7px;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
}