.instaswipe-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid #000;
  border-radius: 16px;
  padding: 30px 15px 30px 25px;
  margin-bottom: 35px;
  background-color: #fff;
}
.instaswipe-wrapper__youtubeData {
  width: 45%;
  background-color: #f7f7f7;
  border: 1px solid #949494;
  position: fixed;
  bottom: 0;
  right: 28px;
  height: 80vh;
  z-index: 9999;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.instaswipe-wrapper_info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 35%;
}
.instaswipe-wrapper_info__image {
  padding-right: 20px;
  position: relative;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: row;
}
.instaswipe-wrapper_info__image > a {
  display: flex;
  width: 100px;
  margin-right: 20px;
}
.instaswipe-wrapper_info__image > a > img {
  width: 100%;
  height: 100px;
  border-radius: 50px;
}
.instaswipe-wrapper_info__content {
  position: relative;
  width: calc(100% - 20px);
  padding-right: 20px;
  margin-top: 20px;
}
.wrapper_info__content__youtube {
  display: inline-block;
  cursor: pointer;
}
.wrapper_info__content__youtube > img {
  width: 38px;
}
.wrapper_info__content__moreinfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}
.wrapper_info__content--handle {
  font-size: 23px;
  font-weight: 300;
  margin: 0;
}
.wrapper_info__content--verified {
  margin-left: 10px;
}
.wrapper_info__content--more {
  -webkit-appearance: none;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  outline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  background: #3897f0;
  border-color: #3897f0;
  color: #fff;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  height: 28px;
  width: auto;
}
.wrapper_info__content--more:hover {
  background: #6cb3f7;
  border-color: #6cb3f7;
}
.wrapper_info__content--email {
  margin-top: 14px;
  margin-bottom: 6px;
}
.wrapper_info__content--email > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.wrapper_info__content--email > div > span {
  font-size: 16px;
  margin-right: 5px;
}

.wrapper_info__content--data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;
}
.wrapper_info__content--data > p {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-bottom: 3px;
}
.wrapper_info__content--data > p > span:nth-child(2) {
  margin-left: 5px;
}
.wrapper_info__content--name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
}
.wrapper_info__content--bio {
  width: 100%;
  margin-bottom: 14px;
}
.wrapper_info__content--link {
  margin-bottom: 0px;
}
.wrapper_info__content--link > a {
  margin-left: 5px;
  color: #000;
}
.wrapper_info__content--link > span {
  font-weight: 500;
  border-bottom: 1px solid #000;
}
.instaswipe-wrapper_posts {
  display: flex;
  flex-wrap: wrap;
  width: 65%;
}
.instaswipe-wrapper_posts__post {
  position: relative;
  width: calc(25% - 20px);
  margin: 0px 10px;
}

.wrapper_posts__post__image {
  display: flex;
  width: 100%;
  position: relative;
  padding-bottom: 100%;
}
.wrapper_posts__post__image > img {
  width: 100%;
  position: absolute;
  object-fit: cover;
  height: 100%;
}
.wrapper_posts__post__content {
  margin-top: 10px;
}
.wrapper_posts__post__content > p {
  /* overflow: hidden;
  position: relative;
  max-height: 72px;
  text-align: justify;
  margin-right: -14px;
  padding-right: 14px; */
  position: relative;
  max-height: 72px;
  overflow: hidden;
  font-size: 13px;
}
/* .wrapper_posts__post__content > p:after {
  content: '';
  position: absolute;
  right: 0;
  width: 14px;
  height: 14px;
  margin-top: 0.2em;
  background: white;
}
.wrapper_posts__post__content > p:before {
  content: '...';
  position: absolute;
  right: 14px;
  bottom: 0;
  background-color: #fff;
} */
.wrapper_posts__post__image--type {
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  right: 5px;
  top: 5px;
}
.wrapper_posts__post__content_likes {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #0000007d;
}
.wrapper_posts__post__content_likes > p {
  margin: 3px 0;
  font-size: 12px;
}
.wrapper_posts__post__content_likes > p > img {
  margin-right: 5px;
  width: 12px;
}
.instaswipe-wrapper_buttons {
  display: flex;
  justify-content: flex-end;
  padding: 30px 10px 0px 30px;
  width: 100%;
}
.instaswipe-wrapper_buttons > .mdl-button {
  padding: 0px 45px;
}
.mdl-button:focus:not(:active) {
  background-color: #ed5555
}