.al_table__wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}
.al_table__title {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  margin-bottom: 15px;
}
.al_table {
  width: 100%;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0.15px;
  /* border: none; */
  border-collapse: collapse;
  border-spacing: 10px 10px;
}
.al_table__header {
  background-color: #EEF1F5;
  /* display: flex;
  flex-direction: row; */
  text-align: left;
  height: 52px;
  line-height: 50px;
}
.al_table__header--hidden {
  display: none;
}
/* .al_table__header__row {
  padding-left: 20px;
} */
.al_table__header__row__cell:first-child {
  padding-left: 20px;
}
.al_table__header__row__cell:last-child {
  padding-right: 20px;
}
.al_table__header__row__cell {
  cursor: pointer;
}
.al_table__body__row {
  /* display: flex;
  flex-direction: row; */
  border-bottom: #d3d3d3 solid 1px;
  height: 35px;
}
.al_table__body__row:hover {
  background-color: #eef1f53d;
}
.al_table__body__row__cell {
  width: 20px;
}
.al_table__body__row__cell:first-child {
  padding-left: 20px;
}
.al_table__body__row__cell:last-child {
  padding-right: 20px;
}
.al_table__more {
  background-color: #fff;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.al_table__more:hover {
  background-color: #f6f8fa;
}
.al_table__more--label {
  cursor: pointer;
}
.isOpen {
	transform: rotate(180deg);
}