.q_instagram__posts_post {
  width: 31%;
  margin-bottom: 35px;
  position: relative;
}
.q_instagram__posts_post_image {
  display: flex;
  width: 100%;
  height: 0px;
  position: relative;
  padding-bottom: 100%;
}
.q_instagram__posts_post_image_picture {
  width: 100%;
  position: absolute;
  object-fit: cover;
  height: 100%;
}
.q_instagram__posts_post_image--type {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 20px;
}
.q_instagram__posts_post_image_stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  bottom: 0px;
  width: calc(100% - 12px);
  position: absolute;
  padding: 2px 6px;
  background-color: #00000066;
}
.q_instagram__posts_post_image_stats_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  font-family: "Roboto";
  letter-spacing: 0.15px;
}
.q_instagram__posts_post_image_stats_container > span {
  font-size: 15px;
}

.q_instagram__posts_post_image_stats_container > .stats_container--likes {
  margin-right: 5px;
}

.q_instagram__posts_post_image_stats_container > .stats_container--comments {
  margin-left: 5px;
}
.q_instagram__posts_post_details_description {
  position: relative;
  max-height: 72px;
  overflow: hidden;
  font-size: 13px;
  letter-spacing: 0.15px;
  margin-bottom: 0px;
}
.q_instagram__posts_post_details_ago {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0px;
  line-height: 20px;
}
