.search-options {
  background-color: #d0d7e1;
  border-radius: 20px;
  padding: min(2vw, 30px) min(2vw, 40px);
  /* font-size: 9px; */
  /* padding: 2em 2em; */
}
.search-options__separator {
  background-color: #ffffff;
  margin: 40px 0 30px 0;
}
.search-options__line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 30px;
  /* height: 40px; */
}
.search-options-option {
  display: flex;
  flex-direction: row;
}
.search-options-suboption {
  /* display: flex;
  flex-direction: column; */
  justify-content: space-between;
  /* height: 84px; */
  margin-left: 78px;
  margin-top: 33px;
  width: 400px;
}
.search-options__input-number {
  min-width: 60px;
  max-width: 90px;
  width: 4vw;

  height: 30px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  /* text-align: center; */
  padding: 0 8px;
  margin: 0 6px;
}
.search-options__input-number:focus {
  border: 1px solid #000000;
  outline: none;
}
.search-options__input-number-separator {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* .search-options__input-text {
  width: 250px;
  height: 30px;
  border-radius: 8px;
  border: 1px solid #FFFFFF;
  padding: 0 8px;
  margin: 0 6px;
}
.search-options__input-text:focus {
  border: 1px solid #000000;
  outline: none;
} */
.search-options-option__title {
  font-size: 14px;
  min-width: 80px;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.search-options-option__title--secondary {
  min-width: auto;
}
.search-options-option__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;
  max-width: 25vw;
}
.search-options-option__content__chip-list {
  display: flex;
  flex-wrap: wrap;
  min-width: 10vw;
  margin-top: 5px;
  /* max-width: 20vw; */
}
.search-options-option__content__chip-list__chip {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3a4e7a;
  color: #ffffff;
  padding: 2px 10px;
  margin: 2px 2px;
  border-radius: 20px;
  /* height: 20px; */
}
.search-options-option__content__chip-list__chip > span {
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  padding: 3px;
}
.search-options-option__content__chip-list__chip > span:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
}
.search-option-line {
  margin-left: min(1vw, 20px);
  margin-right: min(1vw, 20px);
}
.search-option-line__title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}
.search-options__button {
  user-select: none;
  background-color: #ffffff;
  margin-right: 10px;
  /* padding: 0.71em 1.14em; */
  padding: 10px min(1vw, 16px);
  /* width: 4em; */
  width: 56px;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
}
.search-options__button:hover {
  background-color: hsl(221, 36%, 98%);
}
.search-options__button--selected {
  user-select: none;
  background-color: #3a4e7a;
  margin-right: 10px;
  padding: 10px 16px;
  width: 56px;
  text-align: center;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
}
.search-options__button--selected:hover {
  background-color: hsl(221, 36%, 39%);
}
.search-options__input-switch-container {
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
}
.search-options__input-switch-container--small > .search-options__input-switch {
  padding: 10px 16px;
  line-height: 10px;
}
.search-options__input-switch-container--small > .search-options__input-switch--selected {
  padding: 10px 16px;
  line-height: 10px;
}
.search-options__input-switch {
  user-select: none;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}
.search-options__input-switch--selected {
  user-select: none;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #3a4e7a;
}
/*
** Dropdown
*/
.search-options__dropdown-value {
  -webkit-appearance: none;
  outline: none;
  height: 40px;
  min-width: 260px;
  border: none;
  border-radius: 16px;
  font-size: 12px;
  line-height: 40px;
}
.search-options__dropdown-value:hover {
  border: none;
}
.search-options__dropdown-value:focus {
  border: none;
  border-radius: 16px;
}
.search-options__dropdown-list {
  border-radius: 16px;
}
/*
** ----
*/
