.influencer_login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}
.influencer_login--error {
  color: #ff3838;
}
.influencer_login--title {
  margin: 0px;
  font-size: 17px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  margin-bottom: 8px;
  width: 100%;
}

.influencer_login--input {
  height: 24px;
  border: 1px solid #ccc;
  margin-bottom: 18px;
  width: calc(100% - 16px);

  outline: none;
  height: 33px;
  border: none;
  border: 1px solid #D8D8D8;
  font-size: 15px;
  line-height: 33px;
  padding: 0px 8px;
}
.influencer_login--input--error {
  border: 1px solid #e53131;
}
.influencer_login--title--error {
  color: #e53131;
}
.influencer_login--submit {
  background-color: #3A4E7A;
  color: #fff;
  font-size: 16px;
  height: 36px;
  border: unset;
  border-radius: 5px;
  width: 105px;
  cursor: pointer;
}
.influencer_login--submit:hover {
  border: 1px solid #3A4E7A;
  background-color: #fff;
  color: #3A4E7A;
}
