.inventory-velocity {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.current__title {
  margin-bottom: 0px;
  margin-top: 5px;
  text-align: center;
}
.current__type {
  text-align: center;
}
.current__values {
  font-size: 16px;
}
.current__values__size {
  text-decoration: underline;
  font-weight: 700;
}
