.yt_newinfluencer {
  padding-right: 20px;
  padding-bottom: 30px;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.yt_newinfluencer__process-type {
  display: flex;
}
.yt_newinfluencer__process-type__left {
  width: 50%;
  border-right: rgb(141, 141, 141) 1px solid;
}
.yt_newinfluencer__process-type__right {
  width: 50%;
}
.yt_newinfluencer_loading {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #fff;
  border-radius: 15px;
  width: 13%;
  margin: 40px auto 20px auto;
}
.al-loading {
  font-size: 40px;
}
.al-loading-text {
  font-family: "Roboto";
  font-weight: 300;
  text-align: center;
  margin-bottom: 0px;
}
.yt_newinfluencer_search {
  position: relative;
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  padding: 18px 30px;
  width: 95%;
  box-sizing: border-box;
  /* margin: 0 auto; */
  background-color: #fff;
  border-radius: 15px;
  margin-top: 20px;
}
/* .yt_newinfluencer_search--podcast {
  flex-direction: column;
} */
.newinfluencer_search_input {
  /* position: relative; */
  width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
  line-height: 36px;
  font-size: 12px;
  background-color: #f3f3f3;
  border-radius: 8px;
  border: none;
  padding: 0 19px;
  margin-bottom: 10px;
}
.newinfluencer_search_input:focus {
  outline: none;
}
.newinfluencer_search_input-container {
  position: relative;
}
.newinfluencer_search_input--remove {
  position: absolute;
  font-size: 17px;
  margin-right: 8px;
  color: grey;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.newinfluencer_search_input--remove:hover {
  color: #3A4E7A;
}
.newinfluencer_search_input--search {
  position: absolute;
  right: 30px;
  cursor: pointer;
}
/* .newinfluencer_search_input--podcast {
  margin-right: 15px;
  width: 90%;
  padding: 0;
} */
.newinfluencer_search__search_actions {
  width: 100%;
  box-sizing: border-box;
  /* display: flex;
  align-items: flex-end; */
  text-align: right;
  margin-top: 5px;
}
.newinfluencer_search__search_actions__search {
  line-height: 36px;
  padding: 0 16px;
  border: 0;
  border-radius: 16px;
  background-color: #2f6cb8;
  color: #fff;
  cursor: pointer;
}
.newinfluencer_search__search_actions__search:hover {
  filter: brightness(110%);
}
.yt_newinfluencer_search_warning {
  position: relative;
  color: orange;
}
.yt_newinfluencer_search--podcast .yt_newinfluencer_search_warning {
  position: relative;
  color: #ed5555;
}
.yt_newinfluencer_search_error {
  position: relative;
  color: #E53935;
  font-weight: 500;
}

.newinfluencer__existing_list {

}
.newinfluencer__existing_list__container {
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  padding: 30px 20px;
  width: 95%;
  margin-top: 30px;
  box-sizing: border-box;
  text-align: center;
}
.newinfluencer__existing_list__container--not_listed {
  background-color: #D0D7E1;
}
.newinfluencer__existing_list__container__content {
  margin-top: 10px;
}
.newinfluencer__existing_list__container__content__link {
  position: absolute;
  right: 18px;
  top: 18px;
  color: #000;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  /* line-height: 24px; */
}
.newinfluencer__existing_list__container__content__link:hover {
  background-color: #f3f3f3;
  border-radius: 8px;
}
.newinfluencer__existing_list__container__content__title {
  margin: 0 0 18px 0;
  font-size: 30px;
}
.newinfluencer__existing_list__container__content__info {
  margin: 8px 0;
}
.newinfluencer__existing_list__container__content__info--link {
  color: #000;
  text-transform: capitalize;
}
.newinfluencer__existing_list__container__content__info--link:hover {
  text-decoration: underline;
}
.newinfluencer__existing_list__container__not_linked {
  margin-top: 50px;
}
.newinfluencer__existing_list__container__not_linked p {
  margin: 0;
}
.newinfluencer__existing_list__container__not_linked__action {
  text-decoration: underline;
  cursor: pointer;
}


.yt_newinfluencer_unknown {
  width: 100%;
  margin-bottom: 50px;
  margin-top: 30px;
}
.yt_newinfluencer_unknown_container {
  text-align: center;
  margin-top: 30px;
  font-family: "Roboto";
  width: 95%;
  box-sizing: border-box;
  /* margin: 30px auto 20px auto; */
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 15px;
}
.yt_newinfluencer_unknown_title {
  font-size: 23px;
  text-decoration: underline;
}
.yt_newinfluencer_unknown_subtitle {
  font-size: 16px;
}
.newinfluencer__existing_list__actions,
.yt_newinfluencer_unknown_actions,
.yt_newinfluencer_data_actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0px 0px 0px;
  align-items: center;
}
.newinfluencer__existing_list__actions__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 35px;
  border: 1px solid #000;
  border-radius: 8px;
  font-weight: 500;
  /* font-size: 15px; */
  text-transform: uppercase;
  letter-spacing: -0.12px;
  cursor: pointer;
  margin: 10px 0px;
}
.newinfluencer__existing_list__actions__button:hover {
  filter: brightness(110%);
}
.newinfluencer__existing_list__actions__button--blue {
  border: 1px solid #2f6cb8;
  background-color: #2f6cb8;
  color: #fff;
}
.newinfluencer__existing_list__actions__button--green {
  border: 1px solid #43A047;
  background-color: #43A047;
  color: #fff;
}
.newinfluencer__existing_list__actions__button--red {
  border: 1px solid #E53935;
  background-color: #E53935;
  color: #fff;
}
.yt_newinfluencer_unknown_add,
.yt_newinfluencer_data_actions_add {
  background-color: #43A047;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 35px;
  border: 1px solid #43A047;
  border-radius: 8px;
  font-size: 15px;
  letter-spacing: 0.8px;
  cursor: pointer;
  margin: 10px 0px;
}
.yt_newinfluencer_unknown_add:hover,
.yt_newinfluencer_data_actions_add:hover {
  background-color: #fff;
  color: #3cb33c;
}
.yt_newinfluencer_unknown_cancel,
.yt_newinfluencer_data_actions_cancel {
  background-color: #E53935;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 35px;
  border: 1px solid #E53935;
  border-radius: 8px;
  font-size: 15px;
  letter-spacing: 0.8px;
  cursor: pointer;
  margin: 10px 0px;
}
.yt_newinfluencer_unknown_cancel:hover,
.yt_newinfluencer_data_actions_cancel:hover {
  background-color: #fff;
  color: #ed5555;
}

.yt_newinfluencer_data {
  width: 100%;
  text-align: left;
}
.yt_newinfluencer_data_title {
  font-size: 23px;
  text-align: center;
}
.yt_newinfluencer_data_text {
  margin-bottom: 0px;
  font-size: 16px;
}
.yt_newinfluencer_data__warning {
  margin: 0;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  min-width: 64px;
  border: 2px solid orange;
  border-radius: 6px;
  /* display: inline-block; */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: orange;
  margin-bottom: 5px;
  padding: 6px;
}
.yt_newinfluencer_data__warning--icon {
  padding-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}
.yt_newinfluencer_data__warning--text {
  width: 80%;
  text-align: center;
}

.yt_newinfluencer_done {
  text-align: center;
  margin-top: 30px;
  font-family: "Roboto";
  width: 95%;
  box-sizing: border-box;
  /* margin: 30px 20px; */
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 15px;
}
.yt_newinfluencer_done_title {
  font-size: 30px;
  display: flex;
  justify-content: center;
  color: #3cb33c;
}
.yt_newinfluencer_done_title > span {
  margin-left: 10px;
}
.yt_newinfluencer_done_subtitle {
  font-size: 16px;
  color: #000;
  margin-bottom: 0px;
}
.yt_newinfluencer_done_subtitle--link {
  text-decoration: underline;
  font-weight: 400;
  margin-bottom: 40px;
  display: block;
}

.yt_newinfluencer_found {
  text-align: center;
  margin-top: 30px;
  font-family: "Roboto";
  width: 90%;
  box-sizing: border-box;
  /* margin: 30px 20px; */
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 15px;
}
.yt_newinfluencer_found_title {
  font-size: 22px;
}
.yt_newinfluencer_found_subtitle {
  font-size: 16px;
  margin-bottom: 0px;
  color: #000;
}
.yt_newinfluencer_found_subtitle--link {
  display: flex;
  justify-content: center;
  text-decoration: underline;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 0px;
}
