.email {
  margin: 10px 0px;
  border-radius: 10px;
  padding: 10px 10px 40px 10px;
  background-color: #d0d7e1;
  position: relative;
}

.email_preview {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid grey;
  padding-bottom: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.email_preview_header {
  width: calc(85% - 10px);
  padding-right: 10px;
}
.email_preview_header_subject {
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: bold;
  line-height: 19px;
}
.email_preview_header_sender {
  margin-bottom: 0px;
}


.email_preview_time {
  width: 15%;
  font-size: 12px;
  text-align: right;
}


.email_content_trimmed {
  background-color: #FFF;
  border-radius: 10px;
  padding: 10px;
}

.email_content {
  overflow: hidden;
}
.email_content--open {
  height: 100%;
}
.email_content--open > div {
  padding-top: 25px;
  padding-bottom: 40px;
}
.email_content--close {
  height: 28px;
}
.email_content--close > div {
  display: none;
}
.email_content_warning {
  color: orangered;
  font-weight: bold;
  margin-bottom: 0px;
}
.email-icon, .email_reply_ai {
  display: flex;
  align-items: center;
  border-radius: 100px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: #3A4E7A;
  color: #FFF;
  padding: 4px 12px;
  font-weight: bold;
  cursor: pointer;
}
.email_reply_ai {
  left: 5px;
  right: unset;
}
.email_reply_ai > span {
  margin-left: 8px;
}
.email-icon:hover, .email_reply_ai:hover {
  background-color: #fff;
  color: #3A4E7A;
}
.email-icon:hover > span, .email_reply_ai:hover > span {
  color: #3A4E7A;
}
.email-icon > span, .email_reply_ai > span {
  font-size: 20px;
  color: #fff;
}
.email-icon--open > span {
  transform: rotate(-90deg);
}
.email-icon--close > span {
  transform: rotate(90deg);
}