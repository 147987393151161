.instagram_container {
  padding: 1px 25px 56px 25px;
}
.instagram_container__header > h1 {
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  margin: 10px 0px 15px 0px;
  line-height: 1;
}
.instagram_container__content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px;
}
.instagram_container__content__card {
  height: 61px;
  border-radius: 16px;
  display: flex;
  margin: 18px 45px;
}

.instagram_container__content__card:hover > a {
  background-color: #C2D2E9 !important;
  color: #000 !important;
}
.instagram_container__content__card > .instagram_container__content__card--isDisable {
  background-color: #aeaeae !important;
  color: #575757 !important;
}
.instagram_container__content__card:hover > .instagram_container__content__card--isDisable {
  background-color: #aeaeae !important;
  color: #575757 !important;
}
.instagram_container__content__card > a {
  padding: 22px 70px;
  background-color: #3A4E7A;
  border-radius: 16px;
  font-size: 18px;
  line-height: 17px;
  font-weight: 400;
}
