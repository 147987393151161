.report-search-options {
}
.report-search-options__row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: auto 0;
  padding: 10px;
  min-height: 45px;
}
.report-search-options__row_select {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 16px;
  width: 160px;
  padding: 10px 15px;
}
.report-search-options__row_select--title {
  margin-bottom: 0px;
}
.report-search-options__row_select--icon {
}
.report-search-options__row_open {
  margin-left: 25px;
}
.report-search-options__row_open > .rdr-DateRange {
  background-color: unset;
}
.report-search-options__row_open > .rdr-DateRange > .rdr-Calendar {
  margin-right: 15px;
  border-radius: 16px !important;
}
.report-search-options__row_open > .rdr-DateRange > .rdr-Calendar > .rdr-MonthAndYear {

}


.report-search-options__row__title {
  margin: auto 0;
  font-weight: 600;
  margin-right: 30px;
  width: 90px;
}

.report-search-options__row__label {
  margin: auto 0;
  margin-right: 15px;
}
.report-search-options__row__input-date {
  margin: auto 0;
  height: 40px;
  padding: 0 20px 0 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  margin-right: 20px;
  border-radius: 16px;
  border: none;
  outline: none;
}
.report-search-options__row__input-date::placeholder {
  color: #979797;
}
.report-search-options__row__toggle {
  margin: 0;
  padding: 0;
  display: flex;
  background: #ffffff;
  border-radius: 12px;
  margin-right: 20px;
  height: 40px;
}
.report-search-options__row__toggle__option {
  cursor: pointer;
  margin: 0;
  text-align: center;
  width: 80px;
  padding: 8px;
  background: #ffffff;
  border-radius: 12px;
}
.report-search-options__row__toggle__option--selected {
  margin: 0;
  text-align: center;
  width: 80px;
  padding: 8px;
  background: #3A4E7A;
  border-radius: 12px;
  color: #ffffff;
}
.report-search-options__row__agent-input {
  margin: auto 0;
  margin: 0;
  padding: 0 20px 0 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  margin-right: 20px;
  border-radius: 16px;
  border: none;
  outline: none;
}

.report-search-options__separator {
  padding: 0;
  margin: 0;
  color: #ffffff;
  background: #ffffff;
  margin: 10px;
  /* border-bottom: 1px solid #ffffff;
  width: 100px;
  height: 3px;
  margin: 10px;
  background: chartreuse; */
}

.report-search-options__row__chips {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
}
.report-search-options__row__chips__chip {
  display: flex;
  background: #ffffff;
  border-radius: 16px;
  padding: 2px 10px 2px 15px;
  margin: 0px 5px 10px 5px;
  height: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.report-search-options__row__chips__chip__remove {
  cursor: pointer;
  margin-left: 5px;
  font-size: 15px;
  color: #979797;
}
.report-search-options__row__chips__chip__remove:hover {
  color: #000000;
}


.report-result {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: 50px;
}
.report-result__qualification {
  background: #ffffff;
  border-radius: 16px;
  padding: 25px 15px;
  margin: 25px 20px 10px 10px;
  width: 40%;
}
.report-result__qualification__title {
  color: #3373C3;
  font-size: 13px;
  margin-bottom: 0px;
}
.report-result__qualification__content {
  display: flex;
  justify-content: space-evenly;
  padding: 15px 0 0 0;
}
.report-result__qualification__content__separator {
  margin: 5px 0 10px 0;
}
.report-result__qualification__content__yes {
  text-align: center;
  width: 20%;
}
.report-result__qualification__content__yes__content {
  font-size: 20px;
}
.report-result__qualification__content__total {
  text-align: center;
  width: 20%;
}
.report-result__qualification__content__total__title {
  font-size: 20px;
}
.report-result__qualification__content__total__content {
  font-size: 30px;
}
.report-result__qualification__content__no {
  text-align: center;
  width: 20%;
}
.report-result__qualification__content__no__content {
  font-size: 20px;
}

.report-result__qualification-graph {
  background: #ffffff;
  border-radius: 16px;
  padding: 25px 15px;
  margin: 25px 30px 10px 10px;
  width: 100%;
}
.report-result__qualification-graph__title {
  color: #3373C3;
  font-size: 13px;
  margin-bottom: 25px;
}


.report-result__scraping {
  background: #ffffff;
  border-radius: 16px;
  padding: 25px 30px;
  margin: 25px 20px 10px 10px;
  width: calc(58% - 130px);
}
.report-result__scraping__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 22px;
  border-radius: 6px;
}
.report-result__scraping__content:nth-child(odd) {
  background-color: #F1F3F4;
}
.report-result__scraping__content--type, .report-result__scraping__content--number, .report-result__scraping__content--percentage {
  font-family: "Roboto";
  font-size: 16px;
}
.report-result__scraping__content--type {
  width: 70%;
  margin-bottom: 0px;
}
.report-result__scraping__content--number {
  width: 15%;
  margin-bottom: 0px;
}
.report-result__scraping__content--percentage {
  width: 15%;
  margin-bottom: 0px;
  text-align: right;
}

.report-result__scraping__content--type_total {
  margin-bottom: 0px;
}
.report-result__scraping__content--number_total {
  margin-bottom: 0px;
}
.report-result__scraping__content_separator {
  border-top: 1px solid #000;
  margin-bottom: 0px;
  margin-top: 10px;
}
.report-result__scraping__content_total {
  background-color: #FFF !important;
}
.report-result__scraping__content--type_total {
  font-weight: bold;
}
.report-result__scraping__content--number_total {
  font-weight: bold;
}

.report-result__qualification_users {
  background: #ffffff;
  border-radius: 16px;
  padding: 25px 30px;
  margin: 25px 20px 10px 10px;
  width: calc(50% - 130px);
}
.report-result__qualification_users_user {
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 22px;
  border-radius: 6px;
}
.report-result__qualification_users_user:nth-child(odd) {
  background-color: #F1F3F4;
}
.report-result__qualification_users_user_header {
  background-color: #fff !important;
  border-bottom: 1px solid #000;
  border-radius: 0px;
  height: 22px;
}
.report-result__qualification_users_user_header-el {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 16px;
}
.report-result__qualification_users_user_header > .report-result__qualification_users_user--cc {
  text-align: left;
}
.report-result__qualification_users_user_separator {
  border-top: 1px solid #000;
  margin-bottom: 0px;
  margin-top: 10px;
}

.report-result__qualification_users_user_info, .report-result__qualification_users_user--yes, .report-result__qualification_users_user--no, .report-result__qualification_users_user--cc, .report-result__qualification_users_user--percentage {
  font-family: "Roboto";
  font-size: 16px;
  margin-bottom: 0px;
}
.report-result__qualification_users_user_info {
  width: 54%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 44px;
}
.report-result__qualification_users_user_info_name {

}
.report-result__qualification_users_user_info_email {
  font-size: 13px;
  line-height: 20px;
}
.report-result__qualification_users_user--yes, .report-result__qualification_users_user--cc {
  width: 10%;
  text-align: left;
}
.report-result__qualification_users_user--no {
  width: 13%;
  text-align: left;
}
.report-result__qualification_users_user--percentage {
  width: 13%;
  text-align: right;
}

