.outreach_influencer_deal_dates {
  background-color: #fff;
  border-radius: 20px;
  padding: 30px 30px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
}
.outreach_influencer_deal_dates_edit {
  position: absolute;
  color: #2d2d2d;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.outreach_influencer_deal_dates_edit:hover {
  color: #3a4e7a;
}
.outreach_influencer_deal_dates_date {
  width: 30%;
  margin-right: 3%;
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 115px;
}
.outreach_influencer_deal_dates_date--payment-methods {
  width: 45%;
}
.outreach_influencer_deal_dates_date--payment-status .dropdown_container,
.outreach_influencer_deal_dates_date--payment-methods .dropdown_container {
  width: 100%;
}
.outreach_influencer_deal_dates_date--payment-status {
  width: 45%;
}
.outreach_influencer_deal_dates_date--transaction-list {
  width: 100%;
  position: relative;
}
.outreach_influencer_deal_dates_date_transaction {
  background-color: #f1f3f4;
  border-radius: 8px;
  display: flex;
  margin-bottom: 10px;
  padding: 10px 20px;
  overflow-x: auto;
}
.outreach_influencer_deal_dates_date_transaction--empty {
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 40px;
}
.outreach_influencer_deal_dates_date_transaction .al_loading {
  padding: 0;
  width: 20%;
  min-width: 22px;
}
.outreach_influencer_deal_dates_date_transaction .al_loading_icon {
  width: 20px;
}
.outreach_influencer_deal_dates_date_transaction_delete {
  position: absolute;
  left: -10px;
  transform: translateY(-10px);
  height: 20px;
  width: 20px;
  background: #e53935;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.outreach_influencer_deal_dates_date_transaction_delete:hover {
  background: #f14c4a;
}
.outreach_influencer_deal_dates_date_transaction_delete .material-icons {
  color: #fff;
  font-size: 13px;
}
.outreach_influencer_deal_dates_date_transaction_num {
  width: 5%;
  min-width: 20px;
  margin-right: min(30px, 3%);
}
.outreach_influencer_deal_dates_date_transaction_status {
  width: 5%;
  min-width: 20px;
  margin-right: min(30px, 3%);
  display: flex;
  align-items: center;
  cursor: default;
}
.outreach_influencer_deal_dates_date_transaction_status .material-icons {
  font-size: 16px;
}
.outreach_influencer_deal_dates_date_transaction_status--requested
  .material-icons {
  color: #d0d7e1;
}
.outreach_influencer_deal_dates_date_transaction_status--processing
  .material-icons {
  color: #ffd84d;
}
.outreach_influencer_deal_dates_date_transaction_status--processed
  .material-icons {
  color: #43a047;
}
.outreach_influencer_deal_dates_date_transaction_date {
  width: 15%;
  min-width: 85px;
  margin-right: min(70px, 8%);
  flex-grow: 1;
}
.outreach_influencer_deal_dates_date_transaction_method {
  min-width: 17px;
  margin-right: min(20px, 3%);
  display: flex;
  align-items: center;
}
.outreach_influencer_deal_dates_date_transaction_value {
  width: 20%;
  min-width: 50px;
  margin-right: min(50px, 8%);
}
.outreach_influencer_deal_dates_date_transaction_id {
  text-align: right;
  width: auto;
  min-width: 90px;
  flex-grow: 1;
}
.outreach_influencer_deal_dates_container {
  display: flex;
  align-items: center;
}
.outreach_influencer_deal_dates_date_title {
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #3373c3;
}
.outreach_influencer_deal_dates_date_value {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0px;
}
.outreach_influencer_deal_dates_update-btn {
  margin-left: 10px;
  min-height: 20px;
  padding: 5px 6px;
  font-size: 10px;
  color: #fff;
  background-color: #3373c2;
  border: none;
}
