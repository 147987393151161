.reporting {
  padding: 0 10px;
}
.reporting > .input-switch-container {
  border-radius: 16px;
}
.reporting > .input-switch-container > .input-switch {
  padding: 13px 16px;
  width: 100px;
  text-align: center;
}
.reporting > .input-switch-container > .input-switch--selected {
  border-radius: 16px;
}
.reporting__filters {
  display: flex;
  margin: 30px 0;
}
.reporting__filters > div {
  margin-right: 20px;
}
.reporting__summary-result {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 60px;
}
.reporting-table {
  min-width: 645px;
  width: 50%;
  max-width: 660px;
}
.reporting-table__higlighted {
  border-radius: 8px;
  background-color: #d0d7e1;
}
.reporting-table__line {
  display: flex;
  flex-wrap: wrap;
  line-height: 40px;
  padding: 0 24px;
}
.reporting-table__line--header {
  margin-bottom: 4px;
}
/* .reporting-table__line--highlight {
  background-color: #d0d7e1;
}
:not(.reporting-table__line--highlight ~ .reporting-table__line--highlight).reporting-table__line--highlight {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
} */
.reporting-table__line--odd {
  background-color: #f1f3f4;
  border-radius: 8px;
}
.reporting-table__column {
  min-width: 145px;
  text-align: right;
}
.reporting-table__column--header {
  font-size: 18px;
  font-weight: 700;
}
.reporting-table__column--highlight {
  color: #3a4e7a;
}
.reporting-table__column--col1 {
  width: 160px;
  text-align: left;
}
.reporting-table__column--red {
  color: #e53935;
}
.reporting-table__column--green {
  color: #43a047;
}
.reporting-tool-line {
  background-color: #d0d7e1;
  border-radius: 20px;
  padding: 22px 15px;
  /* margin-top: 69px; */
  display: flex;
  justify-content: space-between;
}
.reporting-tool-line__export {
  background-color: #2f6cb8;
  border-radius: 8px;
  color: #ffffff;
  width: 180px;
  height: 36px;
  border: none;
  font-size: 14px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  cursor: pointer;
  margin: 0 34px;
}
.reporting-tool-line__export:not(.reporting-tool-line__export--disabled):hover {
  background-color: hsl(213, 59%, 49%);
}
.reporting-tool-line__export--disabled {
  background-color: #dbdbdb;
  color: rgba(0, 0, 0, 0.87);
  cursor: not-allowed;
}
/* .reporting-tool-line__export--disabled:hover {

} */
.reporting-tool-line-right {
  display: flex;
}
.reporting-tool-line__sort__title {
  margin-right: 20px;
  line-height: 40px;
}

.reporting-result {
  padding-top: 2px;
  padding-bottom: 40px;
}
/* .reporting-result__influencer-title__space {
  width: 470px
} */
.reporting-result__influencer-title {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 10px 20px;
  margin: 24px 0;
}
.reporting-result__influencer-title__space__checkbox {
  width: 28px;
  margin: 0 6px;
}
.reporting-result__influencer-title__space__image {
  width: 46px;
  margin: 0 6px;
}
.reporting-result__influencer-title__space__name {
  width: 30%;
}
.reporting-result__influencer {
  /* display: flex; */
  background-color: #ffffff;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 24px 0;
}
.reporting-result__influencer-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reporting-result__influencer-head__expand {
  font-size: 30px;
  cursor: pointer;
}
.reporting-result__influencer-head__expand:hover {
  background-color: hsl(0, 0%, 98%);
  border-radius: 15px;
}
.reporting-result__influencer-head__left {
  display: flex;
  width: calc(100% - 40px);
}
.reporting-result__influencer__image {
  background-color: #d8d8d8;
  border-radius: 23px;
  height: 46px;
  width: 46px;
  margin: 0 18px 0 25px;
}
.reporting-result__influencer__image-content {
  border-radius: 23px;
  height: 46px;
  width: 46px;
}
.reporting-result__influencer__name {
  font-weight: 500;
  width: 30%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.reporting-result__influencer__checkbox {
  transform: scale(2);
}
.reporting-result__influencer__checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.reporting-result__influencer__section {
  display: flex;
  min-width: 85px;
  /* width: 62px;
  align-content: space-between; */
  justify-content: center;
  align-items: center;
  margin: 0 30px;
}
.reporting-result__influencer__value {
  margin-right: 8px;
}
.reporting-result__influencer-body {
  padding: 0 50px;
  margin-top: 30px;
  height: auto;
  transition: max-height 0.2s ease, margin-top 0.2s ease;
  max-height: 1000px;
}
.reporting-result__influencer-body--collapsed {
  max-height: 0px;
  transition: max-height 0.1s ease, margin-top 0.1s ease;
  padding: 0 50px;
  margin-top: 0px;
  overflow: hidden;
}

/*
** ALDateRange
*/
.al-date-range {
  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
}
.al-date-range__input {
  outline: none;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  background-color: white;
  border-radius: 16px;
  min-width: 170px;
  user-select: none;
  cursor: pointer;
  border-style: none;
  font-size: 14px;
}
.al-date-range__overlay {
  position: absolute;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  display: flex;
  z-index: 1;
}
.al-date-range__overlay__close {
  position: absolute;
  right: 11px;
  top: 11px;
  cursor: pointer;
  padding: 2px;
}
.al-date-range__overlay__close:hover {
  border-radius: 14px;
  background-color: hsl(215, 22%, 96%);
}
/* .al-date-range__overlay .rdr-DateRange {
} */
/* .al-date-range__overlay .rdr-Calendar {
  background-color: #ffffff;
  margin-right: 10px;
  padding: 28px;
  border-radius: 20px;
}
.al-date-range__overlay .rdr-MonthAndYear-innerWrapper {
  text-align: center;
  box-sizing: border-box;
  font-size: 12px;
  padding: 10px 0px;
  height: 38px;
  line-height: 18px;
}
.al-date-range__overlay .rdr-MonthAndYear-button.prev {
  display: block;
  box-sizing: border-box;
  height: 18px;
  width: 18px;
  padding: 0px;
  margin: 0px 10px;
  border: medium none;
  background: rgb(189, 195, 199) none repeat scroll 0% 0%;
  box-shadow: none;
  outline: currentcolor none medium;
  border-radius: 50%;
  float: left;
}
.al-date-range__overlay .rdr-MonthAndYear-button.prev > i {
  display: block;
  width: 0px;
  height: 0px;
  padding: 0px;
  margin: 0px 0px 0px 1px;
  border-color: transparent rgb(52, 73, 94) transparent transparent;
  border-style: solid;
  border-width: 4px 6px 4px 4px;
  border-image: none 100% / 1 / 0 stretch;
  text-align: center;
}
.al-date-range__overlay .rdr-MonthAndYear-button.next {
  display: block;
  box-sizing: border-box;
  height: 18px;
  width: 18px;
  padding: 0px;
  margin: 0px 10px;
  border: medium none;
  background: rgb(189, 195, 199) none repeat scroll 0% 0%;
  box-shadow: none;
  outline: currentcolor none medium;
  border-radius: 50%;
  float: right;
}
.al-date-range__overlay .rdr-MonthAndYear-button.next > i {
  display: block;
  width: 0px;
  height: 0px;
  padding: 0px;
  margin: 0px 0px 0px 7px;
  border-color: transparent transparent transparent rgb(52, 73, 94);
  border-style: solid;
  border-width: 4px 4px 4px 6px;
  border-image: none 100% / 1 / 0 stretch;
  text-align: center;
} */

/*
** Dropdown
*/
.reporting__filters__dropdown-value {
  -webkit-appearance: none;
  outline: none;
  height: 40px;
  min-width: 260px;
  border: none;
  border-radius: 16px;
  font-size: 14px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.87);
}
.reporting__filters__dropdown-value:hover {
  border: none;
}
.reporting__filters__dropdown-value:focus {
  border: none;
  border-radius: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.reporting__filters__dropdown-list {
  border-radius: 16px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
