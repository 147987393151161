.q_youtube__videos__video {
  width: 31%;
  margin-bottom: 35px;
  position: relative;
}
.q_youtube__videos__video_image {
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0;
}
.q_youtube__videos__video_image > img {
  display: flex;
  width: 100%;
  margin: 0;
  cursor: pointer;
}
.q_youtube__videos__video_image--duration {
  position: absolute;
  right: 4px;
  bottom: 4px;
  z-index: 2;
  background-color: #00000080;
  color: #fff;
  padding: 0px 3px;
  border-radius: 4px;
}
.q_youtube__videos__video_image--genre {
  display: none;
}
.q_youtube__videos__video_image:hover > .q_youtube__videos__video_image--genre {
  display: flex;
  position: absolute;
  font-size: 12px;
  background-color: #0089ffba;
  margin-bottom: 0px;
  padding: 3px 6px;
  line-height: 1;
  bottom: 3px;
  left: 3px;
  border-radius: 4px;
}


.q_youtube__videos__video_infos {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.q_youtube__videos__video_infos--title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
  font-size: 13px;
  line-height: 20px;
  margin-top: 10px;
}
.q_youtube__videos__video_infos--date {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0px;
  line-height: 20px;
}
.q_youtube__videos__video_infos--views {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0px;
}
.q_youtube__videos__video_infos_stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.q_youtube__videos__video_infos_stats--likes {
  color: green;
}
.q_youtube__videos__video_infos_stats--dislikes {
  color: red;
}

.q_youtube__videos__video_infos_stats--likes, .q_youtube__videos__video_infos_stats--dislikes {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.q_youtube__videos__video_infos_stats--likes > span, .q_youtube__videos__video_infos_stats--dislikes > span {
  font-size: 15px;
  margin-right: 4px;
}
.q_youtube__videos__video_infos--open_modal {
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.q_youtube__videos__video_infos--open_modal > span {
  font-size: 20px;
  color: #00000080;
}
