.qualification_history-modal {
  display: flex;
  flex-direction: column;
  width: 75vw;
  max-height: 65vh;
  padding: 31px;
}
.qualification_history-modal h2 {
  font-size: 20px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}
.modal-head__line {
  display: flex;
  justify-content: center;
}
.qualification_history-modal .modal-head__line__title {
  flex: 1;
  display: flex;
  justify-content: left;
  padding: 0;
  margin: 0;
  padding-left: 0;
  font-size: 24px;
  font-weight: 400;
  text-transform: none;
}
.modal-head__line__button {
  transform: translate(14px, -24px);
}
/* .modal-head__separator {
  margin: 0 0 14px 0;
} */
.modal-foot{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.qualification_history-modal__content {
  position: relative;
  height: 100%;
  overflow: scroll;
}
.qualification_history-modal__platform_link_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 5px;
}
.qualification_history-modal__platform_link {
  height: 24px;
}

.qualification_history-modal__rounded-button {
  outline: none;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: solid 1px #000;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}
.qualification_history-modal__rounded-button:hover {
  background: #3cb33c;
  color: #fff;
}
.qualification_history-modal__rounded-button--selected {
  outline: none;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: default;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: solid 1px #000;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}
.qualification_history-modal__rounded-button__icon {
  margin-right: 8px;
}
/* .qualification_history-modal__co_creation_button {
  background-color: #1815a0;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #1815a0;
  border-radius: 3px;
  font-size: 15px;
  letter-spacing: .8px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  cursor: pointer;
  height: 24px;
  padding: 5px 35px;
  width: 45%;
  margin-left: auto;
  margin-top: 25px;
} */
