.outreach {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: calc(100vh - 30px);
  margin-right: 15px;
  overflow: hidden;
}
.outreach_platforms {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  border-radius: 16px;
  width: 80%;
}
.outreach_platforms__platform {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 20px;
  background-color: #fff;
  border-radius: 16px;
  width: 100%;
  cursor: pointer;
}
.outreach_platforms__platform--isSelected {
  background-color: #3A4E7A;
  color: #fff;
  border-radius: 16px;
}
.outreach_platforms__platform__title {
  font-family: "Roboto";
  font-size: 16px;
  letter-spacing: 0.15px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
}
.outreach_platforms__platform__title > span {
  margin-left: 5px;
}


.outreach_container {
  padding: 20px;
  background-color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 20px;
  height: calc(100vh - 128px);
  overflow: scroll;
  width: 70%;
}
.outreach_container_users {

}
.outreach_container_users_header {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  background-color: #EEF1F5;
}
.outreach_container_users_header--title:first-child {
  width: calc(35% + 66px);
  margin-left: 168px;
}
.outreach_container_users_header--title {
  margin-bottom: 0px;
  font-family: "Roboto";
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
  font-weight: 600;
}
.outreach_container_user {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d4d4d4;
  padding: 13px 0px;
  font-family: "Roboto";
}
.outreach_container_user__check {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  margin-left: 20px;
  margin-right: 26px;
}
.outreach_container_user__check > span {
  font-size: 20px;
}
.outreach_container_user_number {
  width: 70px;
  border: none;
  border-bottom: 1px solid #d4d4d4;
  height: 30px;
  padding: 0px;
  margin: 0px;
  background-color: unset;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.15px;
}
.outreach_container_user_number:focus {
  outline: none;
}
.outreach_container_user__picture {
  width: 54px;
  height: 54px;
  margin-left: 60px;
  margin-right: 12px;
  border-radius: 100%;
}

.outreach_container_user__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;
}
.outreach_container_user__info--name {
  margin-bottom: 0px;
  font-size: 15px;
  font-family: "Roboto";
  letter-spacing: 0.15px;
  line-height: 22px;
}
.outreach_container_user__info--email {
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Roboto";
  line-height: 16px;
  letter-spacing: 0.15px;
}
.outreach_container_user > .dropdown_container {
  width: 35%;
}

.outreach_actions {
  position: relative;
  width: calc(30% - 95px);
  margin-left: 15px;
  padding: 20px;
  background-color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 20px;
  height: calc(100vh - 128px);
  overflow: scroll;
}

.outreach_actions_summary {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.outreach_actions_summary_container {
  background-color: #eef1f5;
  text-align: center;
  width: 100%;
  border-radius: 16px;
  padding: 10px;
}
.outreach_actions_summary_container:first-child {
  margin-right: 7.5px;
}
.outreach_actions_summary_container:last-child {
  margin-left: 7.5px;
}
.outreach_actions_summary_container_title {
  font-family: "Roboto";
  font-size: 19px;
  line-height: 19px;
  margin-bottom: 10px;
}
.outreach_actions_summary_container_value {
  font-family: "Roboto";
  font-size: 38px;
  margin-bottom: 0px;
  line-height: 1;
}
.outreach_actions_summary_container_value--error {
  color: #E53935;
}




.outreach_actions_selected {
  display: flex;
  flex-direction: column;
}
.outreach_actions_selected_user {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 35px;
  background-color: #F1F3F4;
  margin-bottom: 10px;
  border-radius: 16px;
}
.outreach_actions_selected_user_info {
  width: 80%;
}
.outreach_actions_selected_user_info--name {
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.15px;
  font-family: "Roboto";
}
.outreach_actions_selected_user_info_campaign {
  display: flex;
  flex-direction: row;
  margin: 0px;
  font-family: "Roboto";
}
.outreach_actions_selected_user_info_campaign > .material-icons {
  margin-right: 10px;
  transform: rotate(-45deg);
}
.outreach_actions_selected_user_info_campaign--error {
  color: #E53935;
}
.outreach_actions_selected_user_info_nbChannel {
  width: 20%;
  text-align: center;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 30px;
}
.outreach_actions_selected_user_info_nbChannel--error {
  color: #E53935;
}

.outreach_actions_validate {
  width: 50%;
  background-color: #2F6CB8;
  border: 1px solid #2F6CB8;
  text-align: center;
  padding: 6px;
  border-radius: 20px;
  cursor: pointer;
  float: right;
  margin-top: 50px;
  color: #FFFFFF;
}
.outreach_actions_validate:hover:not(.outreach_actions_validate--hasError) {
  background-color: #FFF;
  color: #2F6CB8;
}
.outreach_actions_validate:hover:not(.outreach_actions_validate--hasError) > p {
 color: #2F6CB8;
}
.outreach_actions_validate--hasError {
  background-color: #C9C9C9;
  border: 1px solid #C9C9C9;
  cursor: not-allowed;
}
.outreach_actions_validate > p {
  margin-bottom: 0px;
  color: #FFF;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 26px; 
}
.outreach_actions_processing {
  margin-top: 50px;
}