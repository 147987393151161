.outreach_influencer_header {
  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.outreach_influencer_header_details {
  position: absolute;
  right: 20px;
  bottom: 15px;
  display: flex;
}
.outreach_influencer_header_details > p {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  color: #bbbbbb;
  margin: 0px;
  margin-right: 10px;
}
.outreach_influencer_header_details_title {
  margin-right: 3px;
  font-weight: 600;
}
.outreach_influencer_header_forceupdate {
  cursor: pointer;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #3A4E7A;
}
.outreach_influencer_header_forceupdate_info {
  display: none;
}
.outreach_influencer_header_forceupdate:hover > .outreach_influencer_header_forceupdate_info {
  display: flex;
  position: absolute;
  width: 100px;
  margin: 0px;
  top: 32px;
  left: -10px;
  background-color: #e8ecf1;
  line-height: 18px;
  padding: 5px 12px;
  border: 1px solid #3a4e7a80;
  z-index: 2;
  border-radius: 8px;
}
.outreach_influencer_header_forceupdate_info:after, .outreach_influencer_header_forceupdate_info:before {
  content: "";
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
}
.outreach_influencer_header_forceupdate_info:after {
  top: -15px;
  border-color: transparent transparent #e8ecf1;
  left: 12px;
  border-width: 8px;
}
.outreach_influencer_header_forceupdate_info:before {
  border-width: 9px;
  left: 11px;
  top: -18px;
  border-color: transparent transparent #3a4e7a94; 
}



.outreach_influencer_header_edit {
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 10px;
  right: 12px;
  color: #9a9a9a;
}
.outreach_influencer_header_edit_info {
  display: none;
}
.outreach_influencer_header_edit:hover > .outreach_influencer_header_edit_info {
  display: flex;
  position: absolute;
  width: 100px;
  margin: 0px;
  top: 32px;
  right: -10px;
  background-color: #e8ecf1;
  line-height: 18px;
  padding: 5px 12px;
  border: 1px solid #3a4e7a80;
  z-index: 2;
  border-radius: 8px;
}
.outreach_influencer_header_edit_info:after, .outreach_influencer_header_edit_info:before {
  content: "";
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
}
.outreach_influencer_header_edit_info:after {
  top: -15px;
  border-color: transparent transparent #e8ecf1;
  right: 12px;
  border-width: 8px;
}
.outreach_influencer_header_edit_info:before {
  border-width: 9px;
  right: 11px;
  top: -18px;
  border-color: transparent transparent #3a4e7a94; 
}




.outreach_influencer_header_notes {
  position: fixed;
  top: calc(25vh - 45px);
  left: 25%;
  right: 0px;
  bottom: 0px;
  width: 50%;
  height: 50vh;
  max-height: 50vh;
  z-index: 6;
  background: #FFFFFF;
  box-shadow: 2px 2px 6px 4px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 45px 50px;
}
.outreach_influencer_header_notes--close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.outreach_influencer_header_notes_form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}
.outreach_influencer_header_notes__input {
  width: 75%;
  box-sizing: border-box;
  background: #F3F3F3;
  border-radius: 16px;
  padding: 12px 24px;
  border: none;
  font-size: 15px;
}
.outreach_influencer_header_notes__input:focus {
  outline: none;
}
.outreach_influencer_header_notes__add {
  background: #2F6CB8;
  border-radius: 16px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fff;
  cursor: pointer;
}
.outreach_influencer_header_notes___add--isDisable {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.oi_header_notes_list {
  max-height: calc(50vh - 81px);
  overflow: scroll;
}
.oi_header_notes_list_note {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  font-family: "Roboto";
  padding: 10px 0px;
}
.oi_header_notes_list_note_content {
  width: 75%;
  box-sizing: border-box;
  background-color: #F3F3F3;
  padding: 10px 40px 10px 15px;
  border-radius: 16px;
  min-height: 0px;
  transition: min-height 0.3s;
}
.oi_header_notes_list_note_meta {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.oi_header_notes_list_note_meta_date {
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #979797;
  line-height: 20px;
}
.oi_header_notes_list_note_meta_author {
  margin: 0px;
  letter-spacing: 0.2px;
  color: #979797;
  line-height: 20px;
}
.outreach_influencer_header_info {
  max-width: 35%;
  display: flex;
  flex-direction: row;
  position: relative;
}
.oi_header_info_picture {
  width: 28%;
  height: 28%;
  border-radius: 100%;
  background-color: #D8D8D8;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.oi_header_info_details {
  padding-left: 15px;
  font-family: "Roboto";
}
.oi_header_info_details_name {
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}
.oi_header_info_details_email {
  font-size: 15px;
  min-height: 18px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-wrap: break-word;
  word-break: break-all;
  padding-right: 43px;
}
.oi_header_info_details_email:hover {
  padding-right: 0;
}
.oi_header_info_details_email--icon {
  font-size: 18px;
  margin-right: 7px;
}
.oi_header_info_details--edit, .oi_header_info_details--copy {
  display: none;
  font-size: 18px;
}

.oi_header_info_details--edit-visible {
  display: flex;
  cursor: pointer;
}
.oi_header_info_details_email:hover > .oi_header_info_details--edit {
  display: flex;
}
.oi_header_info_details_email:hover > .oi_header_info_details--copy,
.oi_header_info_details_discount-code:hover > .oi_header_info_details--copy {
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 15px;
  cursor: pointer;
}
.oi_header_info_details_email_input {
  border: none;
  border-bottom: 1px solid #000;
  font-size: 16px;
  margin-bottom: 8px;
}
.oi_header_info_details_email_input:focus {
  outline: none;
}
.oi_header_info_details_paypal {

}
.oi_header_info_details_paypal:hover > .oi_header_info_details--edit {
  display: flex;
  cursor: pointer;
  margin-right: 0px;
  margin-left: 3px;
}
.oi_header_info_details_paypal_icon {
  width: 19px;
  margin-right: 5px;
}
.oi_header_info_details_paypal_email {
  min-height: 18px;
  width: 100%;
  margin-bottom: 0px;
}
.oi_header_info_details_paypal,
.oi_header_info_details_country,
.oi_header_info_details_gender,
.oi_header_info_details_discount-code,
.oi_header_info_details_keyword {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px;
}

.oi_header_info_details_discount-code:hover > .oi_header_info_details--copy.material-icons-outlined,
.oi_header_info_details_country > .material-icons,
.oi_header_info_details_gender > .material-icons,
.oi_header_info_details_discount-code > .material-icons-outlined,
.oi_header_info_details_keyword > .material-icons {
  font-size: 18px;
  margin-right: 7px;
}
.oi_header_info_details_country > .oi_header_info_details_country--value,
.oi_header_info_details_discount-code > .oi_header_info_details_discount-code--value,
.oi_header_info_details_gender > .oi_header_info_details_gender--value,
.oi_header_info_details_keyword > .oi_header_info_details_keyword--value {
  font-size: 13px;
}
.oi_header_info_details_discount-code:hover > .oi_header_info_details--edit,
.oi_header_info_details_country:hover > .oi_header_info_details--edit {
  display: flex;
  cursor: pointer;
  margin-right: 0px;
  position: absolute;
  right: 0px;
}
.oi_header_info_details_discount-code,
.oi_header_info_details_country {
  position: relative;
}
.oi_header_info_details_discount-code > .oi_header_details_editing,
.oi_header_info_details_country > .oi_header_details_editing {
  width: 200%;
}
.oi_header_info_details_discount-code--value,
.oi_header_info_details_country--value {
  min-height: 20px;
}
.oi_header_info_details_notes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 9px 0px;
  cursor: pointer;
  width: 35px;
}
.oi_header_info_details_notes:hover > p {
  text-decoration: underline;
}
.oi_header_info_details_notes > .material-icons {
  font-size: 30px;
}
.oi_header_info_details_notes > p {
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0px;
}
.outreach_influencer_header_tags {
  display: flex;
  flex-direction: column;
  width: 22%;
}
.outreach_influencer_header_tags > .al_tag_influencer:first-child {
  margin-bottom: 40px;
}
.al_tag_influencer {
  margin-bottom: 20px;
}
.outreach_influencer_header_platform {
  position: relative;
  width: 30%;
}
.outreach_influencer_header_platform .input-switch-container {
  background-color: #F3F3F3;
  width: 100%;
  overflow-x: auto;
}
.outreach_influencer_header_platform .input-switch {
  width: 25%;
  padding: 10px 22px;
}
.oi_header_platform {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.oi_header_platform--loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.oi_header_platform--loading > .al-loading {
  font-size: 40px;
}
.oi_header_platform_link {
  display: flex;
  position: absolute;
  right: 0;
  color: rgba(0,0,0,.87);
  padding: 2px;
  background-color: #F3F3F3;
  border-radius: 5px;
}
.oi_header_platform_link:hover {
  color: #3A4E7A;
}
.oi_header_platform_link_account_number {
  position: absolute;
  background-color: #061629;
  color: #fff;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 9px;
  width: 12px;
  height: 12px;
  line-height: 13px;
  text-align: center;
  border-radius: 100%;
  right: -4px;
  top: -4px;
}
.oi_header_info_details_email--number {
  position: absolute;
  background-color: #061629;
  color: #fff;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 9px;
  width: 12px;
  height: 12px;
  line-height: 13px;
  text-align: center;
  border-radius: 100%;
  left: 12px;
  top: -2px; 
}
.oi_header_platform_logo_container {
  position: relative;
}
.oi_header_platform_logo {
  width: auto;
  height: 26px;
}
.input-switch.input-switch--selected .oi_header_platform_logo.invert-on-select {
  filter: invert(1)
}
.oi_header_platform_details {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 30px;
}
.oi_header_platform_details_title {
  position: relative;
  font-weight: bold;
  margin-bottom: 0px;
  line-height: 20px;
  min-height: 18px;
  cursor: pointer;
  width: fit-content;
}
.oi_header_platform_details:hover  .oi_header_info_details--edit {
  display: flex;
  position: absolute;
  right: -40px;
  background-color: #fff;
}
.oi_header_platform_details_editing {
  border: none;
  border-bottom: 1px solid #000;
  font-size: 16px;
  margin-bottom: 8px;
}
.oi_header_platform_details_editing:focus {
  outline: none;
}

.oi_header_platform_details_views {
  margin-bottom: 0px;
  line-height: 20px;
  font-size: 12px;
}
.oi_header_platform_details_subs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
  line-height: 20px;
}
.oi_header_platform_details_subs > .material-icons {
  font-size: 18px;
  margin-right: 7px;
}
.oi_header_platform_details_subs--value {
  font-size: 14px;
  margin: 0px;
}
.oi_header_platform_details_broadcasters {
  margin: 15px 0 5px 0;
}
.oi_header_platform_details_broadcasters_link {
  color: #000;
  text-transform: capitalize;
}
.oi_header_platform_details_broadcasters_link:hover {
  text-decoration: underline;
}
.oi_header_platform_details_networks {
  margin: 5px 0 0 0;
}
.oi_header_platform_details_networks_name {
  display: inline;
}

.oi_header_platform_update {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 15px;
  justify-content: flex-end;
  margin-top: 20px;
}
.oi_header_platform_update_logo {
  width: 26px;
  height: auto;
  margin-right: 5px;
}
.oi_header_platform_update_input {
  width: 100%;
  display: flex;
  border: none;
  background-color: #F3F3F3;
  padding: 5px 19px;
  border-radius: 22px;
  font-family: "Roboto";
  letter-spacing: 0.15px;
  line-height: 26px;
  margin-bottom: 8px;
}
.oi_header_platform_update_input:focus {
  outline: none;
}
.oi_header_platform_update_button {
  height: 30px;
  border-radius: 22px;
  border: 1px solid #2F6CB8;
  background-color: #2F6CB8;
  padding: 0px 15px;
  color: #fff;
  line-height: 30px;
  font-size: 14px;
  font-family: "Roboto";
  letter-spacing: 0.15px;
  cursor: pointer;
  margin-top: 8px;
}
.oi_header_platform_update_button--disable {
  opacity: 0.3;
}
.oi_header_platform_update_button:hover {
  background-color: #FFF;
  color: #2F6CB8;
}

.oi_header_details_editing {
  position: absolute;
  background-color: #fff;
  width: 30vw;
  min-width: 380px;
  padding: 30px 40px;
  border: 1px solid #3a4e7a;
  border-radius: 15px;
  z-index: 1;
  top: 24px;
}
.oi_header_details_editing--right {
  right: 0px;
  top: 50px;
}
.oi_header_details_editing--youtube {
  width: 45vw;
}
.oi_header_details_editing--podcast {
  width: 60vw;
}

.pd_edit_container,
.yt_edit_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  min-width: 80%;
}
.pd_edit_container_link,
.yt_edit_container_link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 25px;
  border: none;
  outline: none;
  /* min-width: 20%; */
}
.pd_edit_container_link_img,
.yt_edit_container_link_img {
  /* width: 100%; */
  height: 100%;
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  display: flex;
  border-radius: 100%;
  border: none;
  background-color: #D8D8D8;
  outline: none;
}
.pd_edit_container_content,
.yt_edit_container_content {
  width: calc(80% - 15px);
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}
.pd_edit_container_content_title,
.yt_edit_container_content_title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: "Roboto";
}
.pd_edit_container_content_subtitle,
.yt_edit_container_content_subtitle {
  font-family: "Roboto";
  font-size: 15px;
  margin-bottom: 0px;
}
.oi_header_details_editing--title {
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #3373C3;
}
.oi_header_details_editing--close {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 5px;
  font-size: 20px;
  border-radius: 8px;
}
.oi_header_details_editing--close:hover {
  background-color: rgba(58, 78, 122, 0.05);
}
.oi_header_details_editing_add {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}
.oi_header_details_editing_add_input-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.oi_header_details_editing_add_input-message {
  font-family: "Roboto";
  font-size: 12px;
  margin: 0 0 5px 0;
}
.oi_header_details_editing_add_input-message--warning {
  color: #ed5555;
}
.oi_header_details_editing_add_input-message--error {
  color: #E53935;
  font-weight: 500;
}
.oi_header_details_editing_add_input {
  box-sizing: border-box;
  line-height: 36px;
  min-width: calc(100% / 2 - 40px);
  border: none;
  border: 1px solid #C5C5C5;
  border-radius: 8px;
  padding: 0 20px;
  margin-right: 40px;
  margin-bottom: 12px;
}
.oi_header_details_editing_add_input:focus {
  outline: none;
  border: 1px solid #757575;
}
.oi_header_details_editing_add_input--full-line {
  min-width: calc(100% - 40px);
}
.oi_header_details_editing_add_button {
  background: #2F6CB8;
  border: 1px solid #2F6CB8;
  border-radius: 16px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  color: #fff;
  cursor: pointer;
  margin-bottom: 12px;
}
.oi_header_details_editing_add_button--disabled {
  cursor: not-allowed;
  filter: grayscale(1);
}
.oi_header_details_editing_add_button:hover {
  background: #fff;
  color: #2F6CB8;
}
.oi_header_details_editing_add .dropdown_container {
  min-width: 50%;
}
.oi_header_details_editing_value {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  padding: 8px 0px;
  border-bottom: 1px solid #b7b7b7;
  align-items: center;
}
.oi_header_details_editing_value:last-child {
  border-bottom: none;
}
.oi_header_details_editing_value--val {
  margin-right: 50px;
  margin-bottom: 0px;
}
.oi_header_details_editing_value--del {
  color: #a0a0a0;
  cursor: pointer;
  font-size: 30px;
  display: flex;
  align-items: center;
}
.oi_header_details_editing_value--del:hover {
  color: #000;
}
.header_forceupdate--refresh {
  -webkit-animation: spinLoading 2.3s linear infinite;
  -moz-animation: spinLoading 2.3s linear infinite;
  animation: spinLoading 2.3s linear infinite;
  animation-direction: reverse;
}

.yt_data--loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px;
  background-color: #e8ecf1;
  width: 70%;
  margin-top: 10px;
  border-radius: 16px;
}
.yt_data--loading > .al-loading {
  font-size: 24px;
}
.yt_data--loading-details {
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 18px;
}
.yt_data--error {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 7px;
  border-radius: 7px;
  margin-top: 9px;
  cursor: pointer;
}
.yt_data--error > span {
  font-size: 20px;
  margin-right: 10px;
}
.yt_data_container {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  border: 1px solid #d2d2d2;
  padding: 12px 20px;
  border-radius: 5px;
  margin-top: 10px;
}
.yt_data_container_line:first-child {
  margin-right: 27px;
}
.yt_data_container_line {
  width: 50%;
}
.yt_data_container_data {
  position: relative;
}
.yt_data_container_data-text {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0px; 
  padding: 2px 0px;
}
.yt_data_container_data-text > span {

}
.yt_data_container_data-info {
  display: none;
}
.yt_data_container_data:hover > .yt_data_container_data-info {
  display: flex;
  position: absolute;
  width: calc(100% - 20px);
  margin: 0px;
  top: 25px;
  left: -4px;
  background-color: #e8ecf1;
  line-height: 18px;
  padding: 5px 12px;
  border: 1px solid #3a4e7a80;
  z-index: 2;
  border-radius: 8px;
}
.yt_data_container_data-info:after, .yt_data_container_data-info:before {
  content: "";
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
}
.yt_data_container_data-info:after {
  top: -15px;
  border-color: transparent transparent #e8ecf1;
  left: 12px;
  border-width: 8px;
}
.yt_data_container_data-info:before {
  border-width: 9px;
  left: 11px;
  top: -18px;
  border-color: transparent transparent #3a4e7a94; 
}