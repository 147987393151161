.split_no_influencer {
  font-family: "Roboto";
  font-size: 11px;
  letter-spacing: 0.15px;
  color: #fff;
  border-radius: 11px;
  position: absolute;
  right: 0px;
  line-height: 11px;
  /* border-radius: 16px; */
  /* padding: 3px 10px; */
}
.split_no_influencer_loading, .split_no_influencer_data {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  border-radius: 16px;
  height: 26px;
}
.split_no_influencer_loading {
  background-color: #7b7b7b;
}
.split_no_influencer_data {
  background-color: #3A4E7A;
}
.split_no_influencer_data > button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  color: #fff;
  line-height: 1;
  background-color: unset;
  font-size: unset;
  height: 100%;
  padding: 0px;
  cursor: pointer;
}

.split_no_influencer_loading > .al_loading {
  background-color: transparent;
  padding: unset;
  width: unset;
  margin-right: 5px;
}
.split_no_influencer_loading > .al_loading > .al_loading_icon {
  font-size: 20px;
  color: #fff;
}

.split_no_influencer_details {
  background-color: #e8ecf1;
  position: absolute;
  border: 1px solid;
  color: #000;
  z-index: 9;
  font-size: 13px;
  padding: 10px;
  width: 130%;
  right: 0px;
  top: 39px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
}
.split_no_influencer_details > span {
  line-height: 16px;
}
.split_no_influencer_details > span:first-child {
  font-weight: bold;
}