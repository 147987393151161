.search-export-modal {
  display: flex;
  flex-direction: column;
  width: 60vw;
  height: 75vh;
}
.search-export-modal__content {
  position: relative;
  height: 100%;
  /* min-height: 300px; */
  /* height: 300px; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0;
  align-items: center;
}
.search-export-modal__add_data {
  display: flex;
  justify-content: space-between;
  background-color: #d0d7e1;
  box-sizing: border-box;
  width: 100%;
  padding: 22px 29px;
  border-radius: 20px;
}
.search-export-modal__add_data__option-wrapper {
  display: flex;
  align-items: center;
}
.search-export-modal__add_data__option-wrapper > .input-switch-container {
  margin-left: 20px;
  margin-right: 5px;
}
.search-export-modal__data_list {
  margin-top: 40px;
  /* display: flex;
  justify-content: space-between; */
  width: 100%;
  /* box-sizing: border-box; */
}
.search-export-modal__data_to_export {
  border-top: solid 1px #D0D7E1;
  /* width: 100%; */
  /* box-sizing: border-box; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.search-export-modal__data_to_export__header {
  padding: 20px 0;
  /* display: none; */
  display: flex;
  justify-content: space-between;
  width: 80%;
  line-height: 30px;
  /* box-sizing: border-box; */
  /* width: 100%; */
  /* display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%; */
}
.search-export-modal__data_to_export__header-content {
  display: flex;
}
.search-export-modal__data_to_export__header__index {
  margin-left: 20px;
  color: #979797;
}
.search-export-modal__data_to_export__header-actions {
  display: flex;
}
.search-export-modal__data_to_export__header-actions > * {
  margin-left: 20px;
}
.search-export-modal__data_to_export__header-actions__expand {
  font-size: 30px;
  cursor: pointer;
}
.search-export-modal__data_to_export__header-actions__expand:hover {
  background-color: hsl(0, 0%, 98%);
  border-radius: 15px;
}
.search-export-modal__data_to_export__fields {
  padding-right: 100px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.search-export-modal__data_to_export__field {
  border-top: solid 1px #D0D7E1;
  width: 50%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.modal-head__line {
  display: flex;
  justify-content: center;
}
.modal-head__line__title {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  padding-left: 67px;
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
}
.modal-head__line__button {
  transform: translate(14px, -24px);
}
.modal-head__separator {
  margin: 0 0 14px 0;
}
.modal-foot {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
/*
** Checkbox
*/
.search-export__checkbox {
  cursor: pointer;
  transform: scale(2);
}
/*
** Dropdown
*/
.search-export-modal__dropdown-value {
  -webkit-appearance: none;
  outline: none;
  height: 40px;
  min-width: 260px;
  border: none;
  border-radius: 16px;
  font-size: 12px;
  line-height: 40px;
}
.search-export-modal__dropdown-value:hover {
  border: none;
}
.search-export-modal__dropdown-value:focus {
  border: none;
  border-radius: 16px;
}
.search-export-modal__dropdown-list {
  border-radius: 16px;
}