@import url('https://fonts.googleapis.com/css?family=Patrick+Hand&display=swap');

@font-face {
  font-family: 'Effra';
  src: local('Effra'), url("../assets/fonts/Effra_Std_Rg.ttf") format('truetype');
}

.gift_pdf {
  color: #000;
  position: absolute;
  right: 15px;
  text-align: center;
}
.gift_pdf--icons {
  font-size: 42px;
}
.gift_pdf--title {
  font-size: 13px;
  margin-bottom: 0px;
  line-height: 18px;
}
.gift_pdf:hover {
  text-decoration: underline;
}
.gift_note {
  padding: 50px;
}
.giftnotes_print {
  display: none;
}
.giftnotes__input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.giftnotes__input > .al-loading {
  font-size: 24px;
}
.giftnotes_output_container {
  display: flex;
}
.giftnotes_output {
  display: flex;
  flex-direction: column;
  width: 20vw;
  position: relative;
}
.giftnotes_output_textarea {
  width: calc(20vw - 6px);
  height: 13.14vw;
}
.giftnotes_result {
  display: flex;
  flex-direction: column;
  width: 20vw;
  position: relative;
  border: 1px solid grey;
  margin-left: 35px;
}
.giftnotes_result_textarea {
  font-family: "Patrick Hand";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(20vw - 100px);
  height: calc(13.14vw - 8px);
  padding: 6px 50px;
}
.giftnotes_result_textarea:first-child {
  border-bottom: 1px solid grey;
}
.giftnotes__input_input {
  width: 50vw;
  height: 60px;
  font-size: 42px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #061529;
  margin-bottom: 20px;
  padding: 0px 15px;
}
.giftnotes__input_input:focus {
  outline: none;
  border-bottom: 3px solid #061529;
}
.giftnotes_print_note {
  font-family: "Effra", "Helvetica";
}

@page {
  size: 76.2mm 50.8mm;
  margin: 0;
}
@media print {
  body, html {
    position: relative;
    width: 76.2mm;
    height: 50.8mm;
    margin: 0px;
    overflow: hidden;
  }
  .gift_pdf {
    display: none;
  }
  .notification-container {
    display: none;
    height: 0px !important;
    width: 0px !important;
  }
  .giftnotes {
    display: none;
  }
  .oklos-menu__nav {
    display: none;
  }
  .oklos-menu {
    height: 100%;
  }
  .oklos-menu__container {
    height: 100%;
  }
  .mdl-layout__content {
    margin: 0px !important;
    width: 100%;
    height: 100%;
  }
  .mdl-layout__drawer {
    display: none;
  }
  .giftnotes_print_note:nth-child(2n) {
    background-color: green;
  }

  .giftnotes_print {
    display: flex;
    flex-direction: column;
    width: 76.2mm;
    height: 50.8mm;
    overflow: hidden;
    justify-content: space-evenly;
  }
  .giftnotes_print_note {
    /* overflow: hidden; */
    /* width: 100mm;
    height: 72mm;
    display: flex;
    justify-content: center;
    align-items: center; */
    /* position: relative;
    width: calc(100% - 20mm);
    height: calc(49% - 20mm);
    padding: 10mm;
    display: flex;
    justify-content: center;
    align-items: center; */
    overflow: hidden;
    position: relative;
    display: flex;
    /* width: calc(100% - 27.4mm);
    height: calc(100% - 7.4mm); */
    /* padding-left: 7.4mm; */
    /* padding-right: 20mm; */
    width: calc(100% - 7.6mm);
    height: calc(100% - 7.6mm);
    font-family: "Effra", "Helvetica";
    /* text-transform: uppercase; */
    margin: 3.8mm;
    padding-bottom: 3.5mm;
  }
  .giftnotes_print_note--reverse {
    transform: rotate(180deg);
  }
  .giftnotes_print_note_image {
    position: absolute;
    /* width: calc(100% - 7.6mm); */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 0px;
  }
  .giftnotes_print_note_image--border {
    width: 100%;
    height: 1px;
    border: 1px solid #000;
    margin-right: 2.5mm;
  }
  .giftnotes_print_note_image--img {
    width: 3.26mm;
  }
}
/* 
@page {
  size: auto;
  margin: 0;
}
@media print {
  html, body {
    width: 148mm;
    height: 210mm;
  }
  a[href]:after {
    content: none !important;
  }
  body {
    width: 140mm;
    height: 184mm;
  }
  div {
    width: 148mm !important;
    height: 200mm !important;
  }
  .gift_pdf {
    display: none;
  }
  .notification-container {
    display: none;
    height: 0px !important;
    width: 0px !important;
  }
  .giftnotes {
    display: none;
  }
  .oklos-menu__nav {
    display: none;
  }
  .giftnotes_print {
    display: flex;
    display: flex;
    flex-direction: column;
  }
  .mdl-layout__content {
    margin: 0px !important;
    width: unset;
    height: unset;
  }
  .giftnotes_print_note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 30mm;
    margin: 0mm;
    width: 88mm !important;
    height: 98mm !important;
    font-size: 20px;
    line-height: 31px;
    font-family: "Patrick Hand";
    padding: 0mm 30mm;
    border: 1mm solid transparent;
  }
  .giftnotes_print_note:first-child {
    border-bottom: 1px solid #a9a9a9;
  }
} */