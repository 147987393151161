.payment {
  /* background-color: #fff; */
  margin-left: 25px;
  margin-right: 40px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.payment .input-switch-container {
  width: 320px;
}
.payment .input-switch {
  width: 50%;
  font-size: 16px;
}
.payment .al_container {
  margin: 30px 0;
  padding: 30px;
}

.payment__section {
  display: flex;
  justify-content: space-between;
}
.payment__section:not(:first-child) {
  margin-top: 70px;
}
.payment__section__left {
  /* flex-grow: 2; */
  width: calc((100% - 60px) / 3 * 2);
}
.payment__section__right {
  /* flex-grow: 1; */
  width: calc((100% - 60px) / 3);
  display: flex;
  align-items: center;
}
.payment__section__title {
  font-size: 20px;
  margin-bottom: 30px;
}
.payment__section__left__line {
  display: flex;
  padding: 0 21px;
}
.payment-import__input-form .al_loading,
.payment__actions .al_loading,
.payment__section__left__line .al_loading {
  width: 20%;
  padding: 2px 5px;
  background: none;
}
.payment-import__input-form .al_loading_icon,
.payment__actions .al_loading_icon,
.payment__section__left__line .al_loading_icon {
  width: 20px;
  filter: invert(100%);
}

.payment__section__left__export-processing-btn {
  width: 280px;
  /* color: white; */
  background-color: #FFD84D;
  border-radius: 8px;
  height: 40px;
  border: none;
  outline: none;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.12px;
  min-height: 36px;
  padding: 8px 25px;
  cursor: pointer;
  margin-left: 30px;
}
.payment__section__left__export-processing-btn:hover {
  filter: brightness(1.03);
}
.payment__section__input_container {
  margin-right: 60px;
}
.payment__section__input_container {
  width: 230px;
}
.payment__section__input_container--fmax_amount {
  width: 280px;
}
.payment__section__input_container__title {
  margin-bottom: 8px;
}
.payment .dropdown_container__selected_value {
  border-radius: 16px;
}
.payment__section__input_container--fmax_amount input {
  width: 100%;
  border: solid 1px #C5C5C5;
  border-radius: 8px;
  line-height: 38px;
  padding: 0 15px;
  outline: none;
}
.payment__section__input_container--fmax_amount input:focus {
  border: solid 1px #000;
}
/* .payment__section--retrieve .al_button { */
.payment .al_button {
  width: 220px;
  background-color: #2F6CB8;
  border-radius: 8px;
  height: 40px;
  border: none;
}
/* Table styling */
.payment__section__right .al_table__wrapper {
  width: 100%;
}
.payment__section__right .al_table {
  border-spacing: 0 10px;
  border-collapse: separate;
  table-layout: fixed;
}
.payment__section__right .al_table__header {
  background-color: transparent;
  height: auto;
  line-height: inherit;
  font-size: 15px;
}
.payment__section__right .al_table__body__row {
  border: none;
  padding: 20px;
}
.payment__section__right tr:last-child td {
  background-color: #3A4E7A;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
}
.payment__section__right  td {
  background: #F1F3F4;
  font-size: 12px;
}
.payment__section__right td:first-child {
  border-radius: 8px 0 0 8px;
  /* text-align: left; */
}
.payment__section__right td:last-child {
  border-radius: 0 8px 8px 0;
}
/* --- */

.payment__section__right__info {
  width: 100%;
  height: 80px;
  background-color: #F1F3F4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment__section__right__info__description {
  font-size: 16px;
  margin-right: 20px;
}
.payment__section__right__info__value {
  font-size: 24px;
}
.payment__actions {
  display: flex;
  justify-content: right;
  margin-top: 50px;
}

.payment__section__left__line .al_button:disabled,
.payment__actions .al_button:disabled {
  filter: grayscale(100%);
  cursor: not-allowed;
}

/* - - - - - - - - */
/* - - IMPORT  - - */
/* - - - - - - - - */
.payment-import .al_container {
  padding: 40px;
}
.payment-import__input-form {
  display: flex;
  justify-content: space-between;
}
.payment-import__input-form__area {
  position: relative;
  background-color: #F3F3F3;
  min-height: 40px;
  display: flex;
  align-content: center;
  /* justify-content: center; */
  /* line-height: 40px; */
  padding: 0 20px;
  border-radius: 16px;
  width: 450px;
  cursor: pointer;
  color: #757575;
}
.payment-import__input-form__area:hover {
  background-color: hsl(0, 0%, 93%);
}
.payment-import__input-form__area:active {
  background-color: hsl(0, 0%, 80%);
}
.payment-import__input-form__area span {
  position: absolute;
  right: 20px;
  top: 12px;
  font-size: 14px;
}
.payment-import__input-form__area input[type="file"] {
  display: none;
}
.payment-import__input-form__area__placeholder {
  width: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
}
.payment-import__input-form__area__placeholder p {
  margin: 0;
}
.payment-import__processing__line {
  display: flex;
}
.payment-import__processing__line:not(:last-child) {
  margin-bottom: 30px;
}
.payment-import__processing__line__description {
  margin-right: 60px;
  font-size: 16px;
}
.payment-import__processing__line__value {
  font-size: 24px;
  word-spacing: 0.25px;
}
.payment-import__processing {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.payment-import__processing .al_loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
}
.payment-import__processing__loading-description {
  position: absolute;
  top: 50%;
  left: calc(50% + 50px + 25px);
  transform: translateY(-50%);
  font-size: 16px;
  /* font-weight: 300; */
}