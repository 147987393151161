.shipping_search {
  padding: 1px 25px 56px 25px;
}
.shipping_search_container {
  
}
.shipping_search_container_header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 25px 0px;
}
.shipping_search_container_header > .al_search {
  margin-right: 20px;
}
.shipping_search_container_content {
  width: 80%;
  margin: 0 auto;
}
.content__order {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 20px;
  border: 1px solid;
  border-radius: 16px;
  margin-bottom: 20px;
}
.content__order_id {
  width: 50%;
  margin: 0px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.content__order_date {
  width: 50%;
  margin: 0px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.content__order__products {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.content__order__products__header {
  margin: 0px;
  border-bottom: 1px solid #a2a2a2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
}
.content__order__products__header_name {

}
.content__order__products__header_price {

}
.content__order__products_product {
  display: flex;
  width: 100%;
}
.content__order__products_product_name {
  width: 50%;
  margin: 0px;
  line-height: 30px;
}
.content__order__products_product_price {
  width: 50%;
  margin: 0px;
  text-align: right;
  line-height: 30px;
}
.content__order__products_total {
  text-align: right;
  margin: 0px;
  border-top: 1px solid;
  font-size: 16px;
  line-height: 35px;
}