.filter_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter_container__dropdown > select {
  height: 36px;
  min-width: 150px;
  font-size: 15px;
}
.filter_container__dropdown {
  font-size: 16px;
  margin-right: 20px;
}
.mdl-menu__item {
  font-size: 16px;
}
.filter_container__input {
  margin-right: 20px;
}


.data__key, .data__values {
  display: flex;
  flex-direction: row;
}
.data__key__el, .data__values__el {
  max-width: 9.09%;
  min-width: 9.09%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  padding: 3px;
  border: 1px solid #000;
}

.result_container {
  width: 85%;
  margin: 0 auto;
}
.result_container > h4 {
  text-align: center;
}
.result_container__links > button {
  margin-bottom: 20px;
}
.result_container__links > span {
  margin: 0px;
}
.result_container__inv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#tt {
  margin-bottom: 100px;
}
.data_channels > tbody {
  width: 100%;
  overflow: scroll;
  display: block;
}
.channel_toOutreach {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}



