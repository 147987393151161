.influencer-search {
  margin-right: 20px;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.influencer_search__search-button-container {
  display: flex;
  flex-direction: row-reverse;
}
.influencer_search__search-button {
  background-color: #43A047;
  border-radius: 8px;
  color: #FFFFFF;
  width: 180px;
  height: 36px;
  border: none;
  font-size: 14px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  cursor: pointer;
  margin-top: 20px;
}
.influencer_search__search-button:hover {
  background-color: hsl(123, 41%, 49%);
}

.influencer_search__page-info {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 42px;
  margin: 30px 10px 2px 0;
}
.influencer_search__page-info__nav > span {
  margin: 0 2px;
  min-width: 42px;
  min-height: 42px;
  text-align: center;
  line-height: 42px;
}
.influencer_search__page-info__nav > span:hover {
  background-color: hsl(221, 36%, 90%);
  border-radius: 14px;
  cursor: pointer;
}
.influencer_search__tool-line {
  background-color: #D0D7E1;
  border-radius: 20px;
  padding: 22px 15px;
  display: flex;
  justify-content: space-between;
}
.influencer_search__tool-line__export {
  background-color: #2F6CB8;
  border-radius: 8px;
  color: #FFFFFF;
  width: 180px;
  height: 36px;
  border: none;
  font-size: 14px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  cursor: pointer;
  margin: 0 34px;
}
.influencer_search__tool-line__export:hover {
  background-color: hsl(213, 59%, 49%);
}
.influencer_search__tool-line-right {
  display: flex;
}
.influencer_search__tool-line-left {
  margin-left: 5px;
}
.influencer_search__tool-line-sort__title {
  margin-right: 20px;
  line-height: 40px;
}

.influencer_search__footer-pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 30px;
  user-select: none;
}
.influencer_search__footer-pagination__page-numbers {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  line-height: 42px;
}
.influencer_search__footer-pagination__page-numbers__cell {
  cursor: pointer;
  min-width: 42px;
  min-height: 42px;
  text-align: center;
  margin: 0 2px;
}
.influencer_search__footer-pagination__page-numbers__cell:hover {
  background-color: hsl(221, 36%, 90%);
  border-radius: 14px;
}
.influencer_search__footer-pagination__page-numbers__cell--selected {
  cursor: pointer;
  min-width: 42px;
  min-height: 42px;
  background-color: #3A4E7A;
  color: white;
  border-radius: 14px;
  text-align: center;
  margin: 0 2px;
}
.influencer_search__footer-pagination__page-numbers__cell--unselectable {
  min-width: 42px;
  min-height: 42px;
  text-align: center;
  font-weight: 700;
  margin: 0 2px;
}
.influencer_search__footer-pagination__chevron {
  cursor: pointer;
  min-width: 42px;
  min-height: 42px;
  text-align: center;
  line-height: 42px;
  margin: 0 2px;
}
.influencer_search__footer-pagination__chevron:hover {
  background-color: hsl(221, 36%, 90%);
  border-radius: 14px;
}
.influencer_search__loading {
  margin: 0 auto;
  margin-top: 15px;
  width: 200px;
}
.influencer_search__loading__reason {
  justify-content: center;
  text-align: center;
  margin-top: 10px;
}