/* Error Boundary CSS */
.route-error-boundary {
  background-color: #fff;
  border-radius: 16px;
  padding: 20px 40px;
  margin: 20px;
}
.route-error-boundary__title {
  font-size: 21px;
  margin-bottom: 10px;
}
.route-error-boundary__dev-info {
  color: #EDF1F5;
  background-color: rgb(31, 31, 31);
  border-radius: 10px;
  padding: 20px 40px;
  margin-bottom: 20px;
  margin-top: 20px;
  max-height: 70vh;
  overflow: auto;
}
.route-error-boundary__dev-info pre {
  margin-bottom: 5px;
  margin-top: 0;
}