.modal_video_container {
  position: fixed;
  right: 20px;
  height: 60vh;
  background-color: #fff;
  z-index: 3;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  padding: 40px 20px;
  width: 40%;
  bottom: 0px;
  overflow-y: scroll;
}
.modal_video_container--close {
  position: absolute;
  right: 8px;
  top: 7px;
  display: flex;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.modal_video_container__title {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0px;
}
.modal_video_container__data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0px;
  padding: 14px 10px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}
.modal_video_container__data__views, .modal_video_container__data__thumbs__likes, .modal_video_container__data__thumbs__dislikes {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #353535;
}
.modal_video_container__data__views > i, .modal_video_container__data__thumbs__likes > i, .modal_video_container__data__thumbs__dislikes > i {
  font-size: 20px;
  margin-right: 5px;
}
.modal_video_container__data__thumbs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.modal_video_container__data__description .al_loading,
.modal_video_container__data__thumbs .al_loading {
  margin: 0;
  padding: 3px 2px;
  width: 35px;
}
.modal_video_container__data__description .al_loading img,
.modal_video_container__data__thumbs .al_loading img {
  width: 50%;
}
.modal_video_container__data__thumbs__likes {
  margin-right: 15px;
}
.modal_video_container__data__description {
  font-size: 15px;
}
.modal_video_container__data__description__title {
  font-size: 13px;
  line-height: 18px;
  border-bottom: 1px solid #616161;
  display: inline-block;
  margin-bottom: 5px;
  color: #616161;
}
.modal_video_container__loading {
  width: 200px;
  position: absolute;
  top: 2px;
  left: 1px;
  /* zoom: 0.5; */
}
.modal_video_container__loading > .al_loading {
  flex-direction: row;
  width: 100%;
  padding: 6px 4px;
  margin: 0;
}
.modal_video_container__loading > .al_loading > img {
  font-size: 12px;
  width: 10%;
}
.modal_video_container__loading > .al_loading > .al_loading_text {
  margin: 0 0 0 10px;
}
.modal_video_container__loading > .al_loading > .al_loading_text > .al_loading_text_line {
  font-size: 12px;
}
