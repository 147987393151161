/* width */
.outreach_influencers::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.outreach_influencers::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.outreach_influencers::-webkit-scrollbar-thumb {
  background: #061529;
}

/* Handle on hover */
.outreach_influencers::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.outreach_list {
  position: relative;
}
.outreach_search {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-left: 10px;
  padding: 3px;
  position: relative;
}

.outreach_search__icon {
  font-size: 16px;
  position: relative;
  cursor: pointer;
}
.outreach_search_info {
  position: absolute;
  right: -290px;
  width: 250px;
  z-index: 9;
  background-color: #fff;
  top: 0px;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 15px;
  overflow: scroll;
  max-height: calc(100vh - 62px);
}
.outreach_search_info_title {
  text-align: center;
  font-size: 16px;
  line-height: 28px;
  border-bottom: 1px solid grey;
}
.outreach_influencers {
  height: calc(100vh - 30px);
  overflow-y: scroll;
  margin-right: 20px;
}
.outreach_influencers_tab {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Roboto";
  padding: 15px;
  background-color: #fff;
  border-radius: 16px;
  cursor: pointer;
  margin-right: 40px;
  margin-bottom: 6px;
}
.outreach_influencers_tab:not(.outreach_influencers_tab--isSelected):hover {
  background-color: hsl(221, 36%, 97%);
}
.outreach_influencers_tab:last-child {
  margin-bottom: 0px;
}
.outreach_influencers_count {
  font-size: 12px;
  margin-bottom: 10px;
  margin-right: 40px;
}
.outreach_influencers_count_warning {
  color: orange;
  display: inline;
}
.outreach_search_info_example {
  margin-top: 20px;
}
.outreach_search_info_example_title {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 5px;
}
.outreach_influencers_tab--name {
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 20px;
  min-height: 20px;
}
.outreach_influencers_tab--email {
  margin: 0px;
  line-height: 20px;
  overflow-x: hidden;
  max-width: 90%;
  min-height: 20px;
}
.outreach_influencers_tab--status {
  margin: 5px 0px 0px 0px;
  padding-top: 3px;
  font-size: 13px;
  line-height: 20px;
  border-top: 1px solid #f1f1f1;
}
.outreach_influencers_tab--status-current {
  font-size: 11px;
  margin-left: 5px;
  color: grey;
  text-transform: lowercase;
}
.outreach_influencers_tab--arrow {
  position: absolute;
  height: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 5px;
  font-size: 32px;
}

.outreach_influencers_tab--isSelected {
  margin-right: 20px;
  background-color: #3A4E7A;
  color: #fff;
}
.outreach_influencers_tab--isSelected > .outreach_influencers_tab--status > .outreach_influencers_tab--status-current {
  color: #D8D8D8;
}
.outreach_influencers_more {
  width: calc(100% - 20px);
  height: 10%;
  padding-top: 20px;
}
.outreach_influencers_more > .al-loading {
  font-size: 36px;
  margin-right: 20px;
}
.outreach_influencers_more_text {
  margin-right: 20px;
  text-align: center;
  margin-top: 5px;
  font-size: 15px;
  color: #5f5f5f;
}
.outreach_influencer {
  position: relative;
  overflow: scroll;
  height: calc(100vh - 30px);
}
.loading_influencer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60%;
  margin: 0px;
}
.loading_influencer_container {
  padding: 60px 40px;
  max-width: 24%;
  background-color: #fff;
  border-radius: 20px;
}
.loading_influencer_icon {
  font-size: 60px;
}
.loading_influencer > .al-loading {
  font-size: 40px;
}
.loading_influencer_text {
  font-size: 15px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0px;
  line-height: 22px;
  color: #3a3a3a;
}
.outreach_influencer_container_tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.outreach_influencer_tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  border-radius: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.outreach_influencer_tabs_tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 16px;
  width: 150px;
  cursor: pointer;
}
.outreach_influencer_tabs_tab--isSelected {
  background-color: #3A4E7A;
  color: #fff;
  border-radius: 16px;
}
.outreach_influencer_tabs_tab--isDisabled {
  cursor: not-allowed;
  opacity: 0.2;
}
.outreach_influencer_newdeal {
  background: #3A4E7A;
  border: 1px solid #3A4E7A;
  border-radius: 16px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  padding: 0px 14px;
}
.outreach_influencer_newdeal:hover {
  background: #fff;
  color: #3A4E7A;
}
.outreach_influencer_newdeal > span {
  margin-left: 10px;
  font-size: 20px;
}
.outreach_influencer_newdeal--isDisabled {
  cursor: not-allowed;
  background-color: #b5b5b5;
  border: 1px solid #b5b5b5;
  color: #e1e1e1;
}
.outreach_influencer_newdeal--isDisabled:hover {
  background-color: #b5b5b5;
  border: 1px solid #b5b5b5;
  color: #e1e1e1;
}
.search_history {
  position: absolute;
  width: calc(100% - 30px);
  background-color: #fff;
  left: 0px;
  top: 40px;
  border-radius: 20px;
  z-index: 9;
  border: 1px solid #e0e0e0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}
.search_history_content {
  overflow: scroll;
  max-height: 150px;
  padding: 10px 0px;
}
.search_history_value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 15px;
}
.search_history_value:hover {
  background-color: rgba(6, 21, 41, 0.02);
}
.search_history_value-text {
  cursor: pointer;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 18px;
}
.search_history_value > span {
  cursor: pointer;
  font-size: 14px;
  color: #888888;
}
.search_history_value > span:hover {
  color: #000;
}
.search_history_close {
  cursor: pointer;
  text-align: center;
  margin-top: 12px;
  font-size: 13px;
  line-height: 18px;
  text-decoration: underline;
  padding-bottom: 15px;
}
.outreach_list--close {
  width: 28px;
  margin-right: 15px;
}
.outreach_list--open {
  width: 21.35%;
}
.outreach_list_open {
  position: absolute;
  background-color: #3A4E7A;
  display: flex;
  padding: 18px 2px;
  top: 68px;
  border-radius: 16px;
  color: #fff;
  cursor: pointer;
  right: 8px;
}
.outreach_influencers--close {
  display: none;
}
.outreach_influencers--open {
  display: block;
}
.outreach_list--close > .outreach_list_open {
  right: 0px;
}

.outreach_influencer_menu_close {
  width: calc(100% - 43px);
}
.outreach_influencer_menu_open {
  width: 78.65%;
}
.outreach_search_dropdown {
  margin-right: 16px;
  max-width: calc((100% / 4) - (16px * 5));
  width: 100%;
}
.outreach_search_dropdown .dropdown_container__selected_value {
  border-radius: 16px;
}
.outreach_search_dropdown .dropdown_container__list__value {
  font-size: 14px;
}
.outreach_search_line {
  display: flex;
  margin-bottom: 16px;
  flex-wrap: wrap;
  font-size: 12px;
}
.outreach_search_actions {
  position: absolute;
  right: 0;
  display: flex;
  align-items: flex-end;
}
.outreach_search_line .al_button.text {
  margin-right: 7px;
  color: #000;
  text-decoration: underline;
  padding: 8px 8px 0 8px;
  line-height: 16px;
  min-height: unset;
  font-weight: normal;
  font-size: 16px;
}
.outreach_search_line .al_button.text:hover {
  background: none;
  color: grey;
}
.outreach_search_line .al_button.contained {
  background-color: #2F6CB8;
  border: none;
  width: 220px;
  font-size: 14px;
}
.outreach_search_input {
  width: calc(100% - 235px);
  max-width: 400px;
  height: 40px;
  display: flex;
  position: relative;
}
.outreach_search__input {
  width: 100%;
  border: none;
  font-family: "Roboto";
  font-size: 14px;
  border-radius: 16px;
  padding: 0 70px 0 20px;
}
.outreach_search__input:focus {
  outline: none;
}
.outreach_search__input--history {
  border-bottom-left-radius: 0;
  border: 1px solid #e0e0e0;
}
.outreach_search_cta--reset {
  cursor: pointer;
  display: flex;
  position: absolute;
  margin-right: 0px;
  right: 44px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.outreach_search_cta--reset > span {
  font-size: 16px;
  color: #545454;
}

.outreach_search_cta--search {
  cursor: pointer;
  display: flex;
  margin-right: 10px;
  position: absolute;
  right: 6px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.outreach_search_cta--search > span {
  font-size: 16px;
}
