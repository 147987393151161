.al_button {
    outline:none;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.12px;
    background-color: rgba(0, 0, 0, 0);
    min-height: 36px;
    padding: 8px 25px;
    border-radius: 8px;
}
.al_button:hover{
    cursor: pointer;
}
.al_button--disable {
    background-color: rgb(134, 134, 134) !important;
    border-color: rgb(134, 134, 134) !important;
    cursor: not-allowed !important;
}
.al_button.outlined {
    color: #3A4E7A;
    border: solid 1px #3A4E7A;
}
.al_button.outlined:hover {
    background-color: rgba(58, 78, 122, 0.05);
}
.al_button.outlined:active {
    background-color: rgba(58, 78, 122, 0.2);
}
.al_button.text {
    color: #3A4E7A;
    border: none;
    padding: 8px 18px;
}
.al_button.text:hover {
    background-color: rgba(58, 78, 122, 0.05);
}
.al_button.text:active {
    background-color: rgba(58, 78, 122, 0.2);
}
.al_button.contained {
    color: white;
    background-color: #3A4E7A;
    border: solid 1px #3A4E7A;
}
.al_button.contained:hover {
    /* background-color: rgba(58, 78, 122, 0.05); */
    filter: brightness(1.1);
}
.al_button.contained:active {
    /* background-color: rgba(58, 78, 122, 0.05); */
    filter: brightness(1.4);
}
.al_button__info_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.al_button__info_container__icon {
    margin-right: 10px;
    margin-left: -6px;
}
.al_button__info_container__icon--no-child {
    margin-left: -3px;
}