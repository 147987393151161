.outreach_influencer_deal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  background-color: #d0d7e1;
  border-radius: 20px;
  padding: 25px;
  margin: 20px 0px;
}

.outreach_influencer_deal_l {
  position: relative;
  width: 38.34%;
  margin-top: 20px;
  padding-bottom: 60px;
}
.outreach_influencer_deal_campaign {
  background-color: #fff;
  border-radius: 20px;
  padding: 15px 35px;
  margin-top: 20px;
}
.outreach_influencer_deal_status {
  background-color: #fff;
  border-radius: 20px;
  padding: 15px 35px;
}

.outreach_influencer_deal_status_dropdowns {
  display: flex;
}

.outreach_influencer_deal_status_dropdowns .dropdown_container {
  width: 250px;
}

.outreach_influencer_deal_status_dropdowns .dropdown_container:first-child {
  margin-right: 10px;
}

.outreach_influencer_deal .dropdown_container {
  font-size: 14px;
}
.outreach_influencer_deal .dropdown_container__selected_value__content,
.outreach_influencer_deal .dropdown_container__selected_value__input,
.outreach_influencer_deal .dropdown_container__list__value {
  font-size: 14px;
}
.outreach_influencer_deal_am {
  background-color: #fff;
  border-radius: 20px;
  padding: 15px 35px;
  margin-top: 20px;
}

.outreach_influencer_deal_delete {
  background-color: #e53935;
  border-radius: 8px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}
.outreach_influencer_deal_delete:hover {
  background-color: #f14c4a;
}

.outreach_influencer_deal_dates,
.outreach_influencer_deal_pricing {
  background-color: #fff;
  border-radius: 20px;
  padding: 30px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
}
.outreach_influencer_deal_pricing--edit {
  padding-bottom: 55px;
}

.outreach_influencer_deal_pricing .outreach_influencer_deal_dates_date:last-child {
  margin: 0px;
}

.outreach_influencer_deal_r {
  width: 58.52%;
  margin-top: 20px;
}
.outreach_influencer_payment_dropdown_options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.outreach_influencer_payment_dropdown_options .material-icons {
  display: none;
}
.outreach_influencer_payment_dropdown_options--processed .material-icons {
  display: block;
  color: #43a047;
  margin-left: 5px;
}
.outreach_influencer_payment_dropdown_options--requested .material-icons {
  display: block;
  color: #d0d7e1;
  margin-left: 5px;
}
.outreach_influencer_payment_dropdown_options--processing .material-icons {
  display: block;
  color: #ffd84d;
  margin-left: 5px;
}
.oi_influencer_edit_input--date {
  border: none;
  border-bottom: 1px solid #d3d3d3;
  font-family: "Roboto";
  font-size: 17px;
  line-height: 20px;
  width: 90%;
  background-color: transparent;
}
.oi_influencer_edit_input--text {
  border: none;
  border-bottom: 1px solid #d3d3d3;
  font-family: "Roboto";
  font-size: 17px;
  line-height: 20px;
  width: 90%;
  background-color: transparent;
}
.oi_influencer_edit_input--number {
  border: none;
  border-bottom: 1px solid #d3d3d3;
  font-family: "Roboto";
  font-size: 17px;
  line-height: 20px;
  width: 90%;
  background-color: transparent;
}
.outreach_influencer_deal_update_button {
  background: #2f6cb8;
  border-radius: 16px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  font-family: "Roboto";
  padding: 0px 15px;
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.outreach_influencer_deal_update_button > span {
  font-size: 19px;
  margin-left: 4px;
}
.oi_deal_shipping {
  background-color: #fff;
  border-radius: 20px;
  padding: 25px 35px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
  max-height: 450px;
  overflow-y: auto;
}
.oi_deal_shipping--overflow-visible {
  overflow-y: visible;
}
.oi_deal_shipping__typeform__list ul {
  list-style: none;
}
.oi_deal_shipping__typeform__list li::before {
  content: "❌ ";
  margin-right: 10px;
}
li.oi_deal_shipping__typeform__list__element--done::before {
  content: "✅ ";
}
.oi_deal_shipping__typeform {
  width: 100%;
  margin-bottom: 15px;
}
.oi_deal_shipping__typeform__selected_typeform {
  margin-bottom: 25px;
}
.oi_deal_shipping__results__header,
.oi_deal_shipping__typeform__header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.oi_deal_shipping__results__header__title,
.oi_deal_shipping__typeform__header__title {
  margin-bottom: 0px;
  font-family: "Roboto";
  color: #3373c3;
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 20px;
}
.oi_deal_shipping__typeform__selected_typeform__result {
  display: flex;
  justify-content: space-between;
}
.oi_deal_shipping__typeform__selected_typeform__result__actions {
  width: 40%;
  display: flex;
  max-width: 200px;
  height: 40px;
  justify-content: end;
  margin-left: 10px;
}
.oi_deal_shipping__typeform__selection {
  display: flex;
  margin-bottom: 15px;
}
.oi_deal_shipping__typeform__selection .dropdown_container {
  width: 250px;
}
.oi_deal_shipping__typeform__response-button {
  border: none;
  background-color: #3373c2;
  color: #ffffff;
  border-radius: 13px;
  padding: 3px 12px;
  cursor: pointer;
  min-height: 30px;
  margin-bottom: 5px;
  width: 100%;
  max-width: 160px;
}
.oi_deal_shipping__typeform__response-button--unset {
  background-color: #F14C4A;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: auto;
  min-width: 35px;
  margin-left: 5px;
}
.oi_deal_shipping__typeform__response-button--unset .material-icons {
  font-size: 18px;
}
.oi_deal_shipping__typeform__selection__confirm {
  border: none;
  background-color: #3373c2;
  color: #ffffff;
  border-radius: 16px;
  padding: 0 18px;
  cursor: pointer;
  height: 36px;
  margin-top: 15px;
}
.oi_deal_shipping__typeform__response-button:hover,
.oi_deal_shipping__typeform__selection__confirm:hover {
  filter: brightness(105%);
}
.oi_deal_shipping__typeform__selection__confirm--disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.oi_deal_shipping__typeform__selection .autocomplete-input {
  margin-left: 10px;
}
.oi_deal_shipping__typeform__selection .autocomplete-input__header {
  border-bottom: 1px solid #c4c4c4;
}
.oi_deal_shipping__typeform__selection .autocomplete-input__header__input {
  padding: 0;
}
.oi_deal_shipping__typeform__selection .autocomplete-input__header__input--focus {
  padding: 0;
}
.oi_deal_shipping__typeform .autocomplete-input,
.oi_deal_shipping__typeform .autocomplete-input__dropdown,
.oi_deal_shipping__typeform .autocomplete-input__header,
.oi_deal_shipping__typeform .autocomplete-input__header__input {
  width: 100%;
}
.oi_deal_shipping__typeform__selected {
  display: flex;
}
.oi_deal_shipping__results {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.oi_deal_shipping__results__new_shipping {
  height: 36px;
  line-height: 36px;
  padding: 0 18px;
  color: #ffffff;
  border-radius: 16px;
  border: none;
  background-color: #3373c2;
  cursor: pointer;
  margin-top: 15px;
  width: 150px;
}
.oi_deal_shipping__results__new_shipping:hover {
  background-color: hsl(213, 58%, 55%);
}
.oi_deal_shipping__results__field {
  width: 33%;
  margin-bottom: 36px;
}
.oi_deal_shipping__results__container {
  display: flex;
  flex-wrap: wrap;
  background-color: #f1f3f4;
  border-radius: 8px;
  padding: 20px 25px 20px 25px;
  margin-bottom: 14px;
}
.oi_deal_shipping__results__field--oneline {
  width: 100%;
}
.oi_deal_shipping__results .oi_deal_shipping__results__header {
  margin-bottom: 10px;
}
.deal_identifier {
  border-radius: 20px;
  padding: 25px 35px;
  margin: 20px 0px;
  background-color: #fff;
}
.deal_identifier_title {
  color: #3373c3;
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 20px;
}
.deal_identifier_content {
  font-size: 18px;
  line-height: 20px;
  margin-left: 25px;
  color: grey;
}