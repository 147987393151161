.deal_list {
  --nb-columns: 8;
  --nb-spaces: calc(var(--nb-columns) - 1);
  --max-gap-size: 50px;
  --gap-size: min(2.3%, var(--max-gap-size));
  --total-space: calc(var(--nb-spaces) * var(--gap-size));
  --column-width: calc(12.5% - (var(--total-space) / var(--nb-columns)));
}

.deals_list__header {
  border-radius: 20px;
  background: #D0D7E1;
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  margin-top: 30px;
  align-items: center;
}


.deals_list__row {
  border-radius: 20px;
  background: #FFF;
  margin: 15px 0;
  padding: 16px 20px;
  display: flex;
  align-items: center;
}

.deals_list__row:first-child {
  background: #D0D7E1;
}


.deals_list__header_item {
  display: flex;
  align-items: center;
  width: var(--column-width);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  cursor: pointer;
  user-select: none;
}

.deals_list__header_item__sort {
  margin-left: 9px;
  background-color: #fff;
  border-radius: 100%;
  font-size: 19px;
}

.deals_list__header_item:not(:first-child) {
  margin-left: var(--gap-size);
}

.deals_list__header_item--influencer {
  width: calc(var(--column-width) / 2 * 5);
}

.deals_list__header_item--expected_date,
.deals_list__header_item--last_outreach,
.deals_list__header_item--date_created {
  width: calc(var(--column-width) / 4 * 3);
  min-width: 75px;
}
.deals_list__header_item--follow-up {
  min-width: 105px;
}
.deals_list__header_item--payment {
  width: calc(var(--column-width) / 3 * 2);
  min-width: 60px;
}