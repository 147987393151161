.q_youtube {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 67%;
  height: calc(100vh - 155px);
  position: relative;
  padding: 27px 20px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
}
.q_youtube__header {
  width: 28%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
.q_youtube__header_meta {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  margin-bottom: 28px;
}
.q_youtube__header_meta_link {
  width: 40%;
  margin-right: 20px;
}
.q_youtube__header_meta_picture {
  width: 100%;
  height: auto;
  border-radius: 100%;
}
.q_youtube__header_meta_details--name {
  display: block;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  margin-bottom: 12px;
  line-height: 18px;
  color: rgba(0,0,0,.87);
}
.q_youtube__header_meta_details--views {
  font-family: "Roboto";
  letter-spacing: 0.15px;
  margin-bottom: 8px;
  line-height: 16px;
}
.q_youtube__header_meta_details--subs {
  font-family: "Roboto";
  letter-spacing: 0.15px;
  margin-bottom: 0px;
  line-height: 16px;
}
.q_youtube__header_description {
  position: relative;
  text-align: center;
  height: 28px;
  border: 1px solid;
  border-radius: 10px;
}
.q_youtube__header_description_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 28px;
  cursor: pointer;
}
.q_youtube__header_description_button > span {
  margin-left: 5px;
}
.q_youtube__header_description_details {
  display: none;
}
.q_youtube__header_description_details--isVisible {
  position: relative;
  display: flex;
  background-color: #fff;
  height: 300px;
  border: 1px solid;
  border-radius: 10px;
  z-index: 9999999;
  margin: -1px;
  padding: 10px 15px;
  overflow: scroll;
}
.q_youtube__header_description_details--isVisible > span {
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 2px;
}
.q_youtube__header_name {
  font-family: "Roboto";
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.q_youtube__header_emails {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px
}
.q_youtube__header_emails > span {
  font-family: "Roboto";
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 18px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.q_youtube__header_general_det {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px;
}
.q_youtube__header_general_det > span {
  font-size: 18px;
  margin-right: 6px;
}
.q_youtube__header_general_det_value {
  font-size: 14px !important; 
}


.q_youtube__videos {
  width: 67%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: scroll;
}

.q_youtube__videos__load {
  width: 100%;
  margin: 0 32.5%;
  text-align: center;
  background-color: #06152a;
  font-family: "Roboto";
  font-size: 16px;
  color: #fff;
  padding: 10px 0px;
  border-radius: 18px;
  cursor: pointer;
  margin-bottom: 15px;
  border: 1px solid #06152a;
  max-height: 20px;
}
.q_youtube__videos__load:hover {
  background-color: #fff;
  color: #06152a;
}
.q_youtube__update {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.q_youtube__update > input {
  height: 44px;
  width: 40%;
  border: none;
  background-color: #F3F3F3;
  padding: 0px 20px;
  border-radius: 22px;
  font-family: "Roboto";
  letter-spacing: 0.15px;
}
.q_youtube__update > input:focus {
  outline: none;
}
.q_youtube__update--button {
  height: 42px;
  border-radius: 22px;
  border: 1px solid #2F6CB8;
  background-color: #2F6CB8;
  padding: 0px 40px;
  color: #fff;
  line-height: 44px;
  font-size: 17px;
  font-family: "Roboto";
  letter-spacing: 0.15px;
  margin-left: 16px;
  cursor: pointer;
}
.q_youtube__update--button:hover {
  background-color: #FFF;
  color: #2F6CB8;
}


.loading_video {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}
.loading_video > .al-loading {
  font-size: 40px;
}
.loading_video_text {
  font-size: 15px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0px;
  line-height: 22px;
  color: #828282;
}
.q_youtube__videos__load--loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.q_youtube__videos__load--loading > i {
  width: 40px;
}
.q_youtube__videos__load--loading > p {
  margin-bottom: 0px;
  font-family: "Roboto";
  font-size: 13px;
}