.al_tag_influencer--title {
  font-family: "Roboto";
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 1;
  margin-bottom: 16px;
}
.al_tag_influencer_tags, .al_tag_influencer_popular {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.al_tag_influencer_popular {
  margin-bottom: 25px;
}
.al_tag_influencer_tags--tag, .al_tag_influencer_popular--tag {
  background-color: #C2D2E9;
  min-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 7px;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.al_tag_influencer_popular--tag {
  background-color: #D4D4D4;
  padding: 6px 10px;
  margin-right: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  width: fit-content;
}
.al_tag_influencer_tags--tag > p, .al_tag_influencer_popular--tag > p {
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Roboto";
  letter-spacing: 0px;
  line-height: 1;
}
.al_tag_influencer_tags--tag > span {
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
}

.al_tag_influencer_search {
  display: flex;
  flex-direction: column;
  position: relative;
}
.al_tag_influencer_search_input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #C4C4C4;
}
.al_tag_influencer_search_input--field {
  width: 100%;
  border: none;
  font-family: "Roboto";
  font-size: 14px; 
}
.al_tag_influencer_search_input--field:focus {
  outline: none;
}

.al_tag_influencer_search--icon {
  color: #00000080;
}

.al_tag_influencer_loading > i {
  font-size: 30px;
}

.al_tag_influencer_search__result {
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px 15px 5px 15px;
  border: 1px solid #D4D4D4;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  top: 24px;
  background-color: #fff;
  width: calc(100% - 30px);
  max-height: 150px;
  overflow: scroll;
  z-index: 999;
}
.al_tag_influencer_search__result--empty {
  text-align: center;
  width: 100%;
  font-family: "Roboto";
  font-size: 13px;
  color: #484848;
  margin-bottom: 0px;
}
.al_tag_influencer_search__result--close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #a2a2a2;
  cursor: pointer;
}