.reporting_qualification {
  margin-bottom: 50px;
}
.reporting_qualification > .al-date-range > .al-date-range__input {
  font-size: 18px;
  height: 46px;
  padding: 0px 25px;
}
.al-date-range .rdrDayStartPreview,
.al-date-range .rdrStartEdge {
  background-color: #D0D7E1;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  border: none;
  z-index: 0;
}
.al-date-range .rdrDayInPreview,
.al-date-range .rdrInRange {
  background-color: #D0D7E1;
  border: none;
  z-index: 0;
}
.al-date-range .rdrDayEndPreview,
.al-date-range .rdrEndEdge {
  background-color: #D0D7E1;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  border: none;
  z-index: 0;
}
.al-date-range .rdrWeekDays > span {
  color: #3373C3;
}
.al-date-range .rdrDayNumber span::after {
  display: none;
  font-weight: 400;
}
.al-date-range .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #000;
}
.reporting_qualification_range {
  margin-top: 25px;
  font-size: 22px;
  border-bottom: 1px solid;
  margin-bottom: 24px;
  text-align: center;
  padding-bottom: 5px;
}
.qualification_report {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 30px;
}
.qualification_report_data {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  width: 15%;
  margin-right: 35px;
}
.qualification_report_data--title {
  margin-bottom: 10px;
  text-align: center;
  font-size: 18px;
  border-bottom: 1px solid #9e9e9e;
  padding-bottom: 10px;
}
.qualification_report_data--value {
  margin: 0px;
  font-size: 38px;
  text-align: center;
  line-height: 1;
}

.reporting_table_qualification {
  background-color: #fff;
  padding: 15px 25px;
  border-radius: 20px;
  margin-right: 15px;
}
.reporting_table_qualification_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #000;
}
.reporting_table_qualification_header > p {
  width: 14.28%;
  margin: 5px 0px;
  text-align: center;
  font-weight: 700;
}
.reporting_table_qualification_data {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #cecece;
}

.reporting_table_qualification_data > p {
  width: 14.28%;
  margin: 12px 0px;
  text-align: center;
}
.reporting_table_qualification_data:last-child {
  border-bottom: none;
}