.instascrap__content__outreach__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.outreach__container__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 16px;
  margin: 35px 20px;
  padding: 35px 0px;
}
.outreach__container__content > p {
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 35px;
}
.outreach__container__content > .button--tooutreach {
  background-color: #3A4E7A !important;
  padding: 0px 40px;
  background: unset;
  box-shadow: unset;
}

.outreach__container__content > .button--tooutreach:hover {
  background-color: #C2D2E9 !important;
  color: #000 !important;
}