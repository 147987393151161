.followup-picker {
  display: flex;
  flex-direction: column;
  position: relative;
  --max-container-width: 230px;
  --padding-width: 25px;
  max-width: calc(var(--max-container-width) - (var(--padding-width) * 2));
  background-color: #FFF;
  padding: 20px var(--padding-width);
  border: 1px solid #757575;
  border-radius: 16px;
  position: absolute;
  top: 40px;
  width: 230px;
  z-index: 1;
}

.followup-picker__title {
  font-family: "Roboto";
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.followup-picker__button {
  font-family: "Roboto";
  background-color: #F1F3F4;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  border: none;
  padding: 5px 0;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

.followup-picker__button:hover {
  filter: brightness(95%);
}

.followup-picker__button:active {
  filter: brightness(90%);
}

.followup-picker__button:not(:last-child) {
  margin-bottom: 8px;
}