.tags_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.tags_wrapper--tag {
  background-color: #c2d2e9;
  min-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 7px;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.tags_wrapper--tag > p {
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Roboto";
  letter-spacing: 0px;
  line-height: 1;
}

.tags_wrapper--tag > span {
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
}