.modal-warn {
  /* background: #E53935; */
  padding: 40px 55px;
  width: calc(520px - 55px * 2);
  min-width: auto;
  filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.15));
}

.modal-warn .modal-head__line__button {
  top: -25px;
  right: -10px;
}

.modal-warn .modal-head__line__title {
  color: #E53935;
  margin: 0;
  letter-spacing: 0.2px;
}
.modal-warn .confirmation-modal__content {
  width: 100%;
  text-align: center;
}
.modal-warn .confirmation-modal__content p {
  letter-spacing: 0.15px;
  font-size: 18px;
}
.modal-warn .modal-head__line__button .material-icons {
  font-size: 28px;
}

.modal-warn .modal-foot .al_button {
  width: 180px;
}
.modal-warn .modal-foot__separator {
  width: 30px;
}
.modal-warn .modal-foot__continue .al_button {
  background: #43A047;
  border: solid 1px #43A047
}