.typeform-information__labels {
  display: flex;
  flex-flow: wrap;
}
.ti-label {
  width: 50%;
  margin-bottom: 36px;
}
.ti-label--large {
  width: 100%;
}
.ti-label__title {
  font-size: 13px;
  margin-bottom: 10px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #3373c3;
}
.ti-label__value p {
  font-size: 16px;
}
.ti-label p {
  margin: 0;
}

.typeform-information .al_table {
  border-spacing: 0 10px;
  border-collapse: separate;
}
.typeform-information .al_table__title {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #3373c3;
  margin-bottom: 0;
}
.typeform-information .al_table__header {
  background-color: #ffffff;
  border-bottom: 1px solid #000000;
  height: 20px;
  vertical-align: bottom;
  /* margin-bottom: 5px; */
}
.typeform-information .al_table__header__row__cell {
  font-weight: 400;
  font-size: 16px;
}
.typeform-information  td {
  background: #F1F3F4;
  padding: 20px 0;
  font-size: 16px;
}
.typeform-information  td:first-child {
  border-radius: 8px 0 0 8px;
  text-align: left;
}
.typeform-information  td:last-child {
  border-radius: 0 8px 8px 0;
}