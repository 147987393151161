/*
** Switch
*/
.input-switch-container {
  background-color: #FFFFFF;
  border-radius: 12px;
  display: inline-flex;
  flex-basis: auto
}
.input-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  padding: 10px 16px;
  font-size: 14px;
  /* line-height: 16px; */
  cursor: pointer;
  /* vertical-align: middle; */
}
.input-switch--selected {
  border-radius: 12px;
  color: #FFFFFF;
  background-color: #3A4E7A;
}
.input-switch--disabled {
  color: #888888;
  cursor: not-allowed;
}