.label_status {
  height: 30px;
  margin-right: 15px;
}
.label_status > p {
  line-height: 30px;
  margin: 0px;
  padding: 0px 26px;
}
.label_status--red {
  background-color: #EF9A9A;
}
.label_status--purple {
  background-color: #CBC5E9;
}
.label_status--green {
  background-color: #C8E6C9;
}
.label_status--grey {
  background-color: #B0BEC5;
}
.label_status--yellow {
  background-color: #FFF5B3;
}
.label_status--unknown {
  background-color: #575757;
  color: #fff;
}
