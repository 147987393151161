.video_information_container {
  
}
.video_information_container__input {
  width: 100%;
  background-color: transparent;
  color: steelblue;
  border-color: steelblue;
  outline: none;
  caret-color: transparent;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  border-radius: 20px;
  margin: 20px 0;
}
.video_information_container__input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: steelblue;
}
.video_information_container__input::-moz-placeholder { /* Firefox 19+ */
  color: steelblue;
}
.video_information_container__input--selected {
  width: 100%;
  background-color: transparent;
  color: seagreen;
  border-color: seagreen;
  outline: none;
  caret-color: transparent;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  border-radius: 20px;
  margin: 20px 0;
}
.video_information_container__input--selected::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: seagreen;
}
.video_information_container__input--selected::-moz-placeholder { /* Firefox 19+ */
  color: seagreen;
}
.video_information_container__bottom_actions {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
}