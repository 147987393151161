.outreach_influencer_deal_header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 15px;
}
.oi_deal_header_name {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 28px;
  position: relative;
  padding-right: 26px;
}
.oi_deal_header_name--value {
  margin-bottom: 0px;
  font-size: 20px;
  font-family: "Roboto";
  letter-spacing: 0.5px;
}
.oi_deal_header_name--editing {
  display: none;
  margin-left: 8px;
  color: #232323;
  position: absolute;
  right: 0px;
}
.oi_deal_header_name--editing > span {
  font-size: 18px;
  display: flex;
  align-items: center;
}
.oi_deal_header_name:hover > .oi_deal_header_name--editing {
  display: flex;
}
.oi_deal_header_name_edit {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.oi_deal_header_name_edit--name {
  border: none;
  border-bottom: 1px solid #3a4e7a;
  font-family: "Roboto";
  font-size: 20px;
  background-color: transparent;
  font-weight: 300;
}
.oi_deal_header_name_edit--name:active,
.oi_deal_header_name_edit--name:focus {
  outline: none;
  border-bottom: 2px solid #3a4e7a;
}
.oi_deal_header_name_edit--button {
  cursor: pointer;
  margin-left: 10px;
  color: #868686;
}
.oi_deal_header_name_edit--button:hover {
  color: #000;
}
.oi_deal_header_name_edit--button > span {
  font-size: 22px;
  display: flex;
  align-items: center;
}
.oi_deal_header_name_date {
  display: flex;
  width: 80%;
  align-items: center;
  margin-left: 15px;
  height: 28px;
}
.oi_deal_header_name_date--value {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 20px;
  color: #4c4c4c;
}
.oi_deal_header_name_date--value > span {
  margin-left: 5px;
}
.oi_deal_header_name_status {
  background-color: #fff;
  padding: 5px 20px;
  position: absolute;
  right: 90px;
  border-radius: 10px;
}
.oi_deal_header_name_status > p {
  margin-bottom: 0px;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 300;
}
.oi_deal_header_name_toggle-deal {
  cursor: pointer;
  position: absolute;
  right: 25px;
  display: flex;
}
.oi_deal_header_name_toggle-deal > span {
  font-size: 41px;
}
